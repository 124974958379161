import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useApp } from '../contexts/AppContext';
import config from '../config';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #ffffff 100%);
  gap: 20px;
`;

const LoadingSpinner = styled(motion.div)`
  width: 40px;
  height: 40px;
  border: 3px solid #e1e1e1;
  border-top-color: #0066FF;
  border-radius: 50%;
`;

const LoadingText = styled.p`
  color: #666;
  font-size: 16px;
  margin: 0;
`;

export function AuthCallback() {
  const navigate = useNavigate();
  const { login } = useApp();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const processedRef = useRef(false);

  useEffect(() => {
    async function processCallback(code, redirectUri) {
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        const healthCheck = await fetch(`${config.apiUrl}/health`, {
          signal: controller.signal
        }).catch(() => ({ ok: false }));
        
        clearTimeout(timeoutId);

        if (!healthCheck.ok) {
          throw new Error('Backend server is not available. Please try again later.');
        }

        const response = await fetch(`${config.apiUrl}/auth/google/callback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Origin': window.location.origin
          },
          credentials: 'include',
          body: JSON.stringify({ 
            code, 
            redirect_uri: redirectUri,
            client_id: config.googleClientId
          })
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(
            errorData.details || 
            errorData.error || 
            `Authentication failed (${response.status})`
          );
        }

        const data = await response.json();
        
        if (!data?.token) {
          throw new Error('No authentication token received');
        }

        await login({
          token: data.token,
          user: data.user
        });

        // Navigate based on whether it's their first login
        navigate('/dashboard', { 
          replace: true,
          state: { 
            showOutreachDialog: !data.user?.onboarding_completed 
          } 
        });

      } catch (error) {
        console.error('Auth callback error:', error);
        setError(error.message);
        
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');
        
        navigate('/login', { 
          replace: true,
          state: { error: error.message } 
        });
      }
    }

    if (!isProcessing && !processedRef.current) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      
      if (!code) {
        setError('No authorization code received');
        navigate('/login', { 
          replace: true,
          state: { error: 'No authorization code received' } 
        });
        return;
      }

      setIsProcessing(true);
      processedRef.current = true;

      const redirectUri = `${window.location.origin}/callback`;
      processCallback(code, redirectUri).finally(() => {
        setIsProcessing(false);
      });
    }

    return () => {
      processedRef.current = true;
    };
  }, [navigate, login, isProcessing]);

  return (
    <Container>
      <LoadingSpinner
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
      />
      <LoadingText>
        {error ? error : isProcessing ? 'Completing your sign in...' : 'Preparing...'}
      </LoadingText>
    </Container>
  );
}