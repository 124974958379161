// ImageCacheService.js
class ImageCacheService {
    constructor() {
        if (ImageCacheService.instance) {
            return ImageCacheService.instance;
        }
        this.imageCache = new Map();
        this.imageObjectCache = new Map();
        ImageCacheService.instance = this;
    }

    preloadImage(url) {
        if (this.imageCache.has(url)) {
            return Promise.resolve(url);
        }

        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                this.imageCache.set(url, img);
                this.imageObjectCache.set(url, img);
                resolve(url);
            };
            img.onerror = reject;
            img.src = url;
        });
    }

    getCachedImage(url) {
        return this.imageCache.get(url);
    }

    isImageCached(url) {
        return this.imageCache.has(url);
    }

    clearCache() {
        this.imageCache.clear();
        this.imageObjectCache.clear();
    }

    // New method to get cached image object
    getCachedImageObject(url) {
        return this.imageObjectCache.get(url);
    }

    // New method to check if image is fully loaded
    isImageLoaded(url) {
        const img = this.imageObjectCache.get(url);
        return img && img.complete && img.naturalHeight !== 0;
    }
}

const instance = new ImageCacheService();
export default instance;
