import React from 'react';
import styled from '@emotion/styled';
import { luxuryTheme as theme } from '../styles/theme';

const Container = styled.div`
  min-height: 100vh;
  background: white;
  padding: ${theme.spacing[40]} ${theme.spacing[24]};
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  color: ${theme.colors.text};

  h1 {
    font-size: 2.5rem;
    margin-bottom: ${theme.spacing[32]};
    color: ${theme.colors.text};
  }

  h2 {
    font-size: 1.5rem;
    margin: ${theme.spacing[32]} 0 ${theme.spacing[16]};
    color: ${theme.colors.text};
  }

  p {
    margin-bottom: ${theme.spacing[16]};
    line-height: 1.6;
    color: ${theme.colors.gray700};
  }

  ul {
    margin-bottom: ${theme.spacing[16]};
    padding-left: ${theme.spacing[24]};

    li {
      margin-bottom: ${theme.spacing[8]};
      color: ${theme.colors.gray700};
    }
  }
`;

export function PrivacyPolicy() {
  return (
    <Container>
      <Content>
        <h1>Privacy Policy</h1>
        
        <p>Last updated: {new Date().toLocaleDateString()}</p>
        
        <p>GrupaAI (&quot;we&quot;, &quot;our&quot;, or &quot;us&quot;) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our AI agent orchestration platform.</p>

        <h2>Information We Collect</h2>
        <p>We collect information that you provide directly to us, including:</p>
        <ul>
          <li>Account information (name, email, company details)</li>
          <li>Profile information</li>
          <li>Communication preferences</li>
          <li>Calendar access and data (with your permission)</li>
          <li>Email account access and content (with your permission)</li>
          <li>Usage data and interaction with our AI agents</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We use the collected information for:</p>
        <ul>
          <li>Providing and improving our services</li>
          <li>Personalizing your experience</li>
          <li>Communication and support</li>
          <li>Analytics and service optimization</li>
          <li>Security and fraud prevention</li>
          <li>Enabling AI agents to schedule meetings on your behalf (with calendar access permission)</li>
          <li>Allowing AI agents to send and respond to emails on your behalf (with email access permission)</li>
        </ul>

        <h2>Calendar and Email Access</h2>
        <p>Our AI agents require access to your calendar and email to provide enhanced services:</p>
        <ul>
          <li>Calendar access is used exclusively for scheduling and managing meetings on your behalf</li>
          <li>Email access enables our AI agents to send emails and occasionally respond to emails with your explicit permission</li>
          <li>We maintain strict security protocols to protect your calendar and email data</li>
          <li>You can revoke calendar and email access permissions at any time</li>
          <li>We do not store email content or calendar data beyond what is necessary for service operation</li>
        </ul>

        <h2>Data Security</h2>
        <p>We implement appropriate technical and organizational security measures to protect your information. However, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure.</p>

        <h2>Data Sharing and Disclosure</h2>
        <p>We may share your information with:</p>
        <ul>
          <li>Service providers and business partners</li>
          <li>Legal authorities when required by law</li>
          <li>Third parties with your consent</li>
        </ul>

        <h2>Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access your personal information</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Opt-out of marketing communications</li>
          <li>Data portability</li>
        </ul>

        <h2>Cookies and Tracking</h2>
        <p>We use cookies and similar tracking technologies to improve your experience and collect usage data. You can control cookies through your browser settings.</p>

        <h2>Children&apos;s Privacy</h2>
        <p>Our services are not intended for children under 13. We do not knowingly collect information from children under 13.</p>

        <h2>Changes to This Policy</h2>
        <p>We may update this Privacy Policy periodically. We will notify you of any material changes by posting the new Privacy Policy on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have questions about this Privacy Policy, please contact us at:</p>
        <p>Email: compliance@grupa.ai</p>
      </Content>
    </Container>
  );
}

export default PrivacyPolicy;