import { useState, useEffect, useCallback, useRef } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';

export function useAgents() {
  const [agents, setAgents] = useState([]);
  const [activeAgentsCount, setActiveAgentsCount] = useState(0);
  const { on, off, emit } = useWebSocket();

  const processedEvents = useRef(new Set());
  const [lastCampaignId, setLastCampaignId] = useState(null);

  const handleCampaignStarted = useCallback((data) => {
    // Add campaign ID tracking
    const campaignId = data?.campaign_id;
    if (!campaignId) {
      console.log('Skipping campaign event without ID');
      return;
    }

    // Check if this is a stale campaign event
    if (lastCampaignId && lastCampaignId !== campaignId) {
      console.log('Skipping event from previous campaign:', {
        currentCampaign: lastCampaignId,
        receivedCampaign: campaignId
      });
      return;
    }

    // Add deduplication check with campaign ID
    const eventKey = `campaign_started_${campaignId}`;
    if (processedEvents.has(eventKey)) {
      console.log('Skipping duplicate campaign event:', eventKey);
      return;
    }
    processedEvents.add(eventKey);

    if (data?.agents) {
      // Update campaign ID first
      setLastCampaignId(campaignId);

      const formattedAgents = data.agents.map(agent => ({
        id: agent.id,
        name: agent.name,
        type: agent.type,
        status: agent.status,
        description: agent.description,
        campaign_id: campaignId // Track campaign ID at agent level
      }));
      setAgents(formattedAgents);
      setActiveAgentsCount(data.agents.filter(agent => agent.status === 'active').length);
    }
  }, [processedEvents, lastCampaignId]);

  const handleAgentsList = useCallback((data) => {
    // Check for stale events by comparing timestamps
    const eventTimestamp = data?.timestamp || Date.now();
    const sessionStartTime = window.sessionStartTime || Date.now();

    if (eventTimestamp < sessionStartTime) {
      console.log('Skipping stale agents list event:', {
        eventTimestamp,
        sessionStartTime
      });
      return;
    }

    // Add deduplication check with timestamp
    const eventKey = `agents_list_${eventTimestamp}`;
    if (processedEvents.has(eventKey)) {
      console.log('Skipping duplicate agents list event:', eventKey);
      return;
    }
    processedEvents.add(eventKey);

    if (Array.isArray(data)) {
      // Filter agents by current campaign if we have one
      const filteredAgents = lastCampaignId
        ? data.filter(agent => !agent.campaign_id || agent.campaign_id === lastCampaignId)
        : data;

      setAgents(filteredAgents);
      setActiveAgentsCount(filteredAgents.filter(agent => agent.status === 'active').length);
    }
  }, [processedEvents, lastCampaignId]);

  const handleAgentUpdate = useCallback((data) => {
    // Add deduplication check with campaign context
    const eventKey = `agent_update_${data?.agentId}_${Date.now()}`;
    if (processedEvents.has(eventKey)) {
      console.log('Skipping duplicate agent update:', eventKey);
      return;
    }
    processedEvents.add(eventKey);

    // Skip updates for agents from different campaigns
    if (lastCampaignId && data?.campaign_id && data.campaign_id !== lastCampaignId) {
      console.log('Skipping update from different campaign:', {
        currentCampaign: lastCampaignId,
        updateCampaign: data.campaign_id
      });
      return;
    }

    if (data?.agentId) {
      setAgents(prevAgents => {
        const newAgents = prevAgents.map(agent => {
          if (agent.id === data.agentId) {
            return { ...agent, ...data };
          }
          return agent;
        });
        setActiveAgentsCount(newAgents.filter(agent => agent.status === 'active').length);
        return newAgents;
      });
    }
  }, [processedEvents, lastCampaignId]);

  const refresh = useCallback(async () => {
    // Clear processed events on refresh
    processedEvents.current.clear();
    return await emit('get_agents');
  }, [emit]);

  useEffect(() => {
    // Set up event handlers
    on('campaign_started', handleCampaignStarted);
    on('agents_list', handleAgentsList);
    on('agent_update', handleAgentUpdate);
    on('agent_status_change', handleAgentUpdate);

    // Initial agents list request
    refresh();

    // Cleanup
    return () => {
      off('campaign_started', handleCampaignStarted);
      off('agents_list', handleAgentsList);
      off('agent_update', handleAgentUpdate);
      off('agent_status_change', handleAgentUpdate);
      
      // Clear campaign tracking on unmount
      setLastCampaignId(null);
      processedEvents.current.clear();
    };
  }, [on, off, refresh, handleCampaignStarted, handleAgentsList, handleAgentUpdate]);

  return {
    agents,
    activeAgentsCount,
    setAgents,
    refresh,
    currentCampaignId: lastCampaignId
  };
}