import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { authService } from '../services/auth';

const AppContext = createContext({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  token: null,
  notifications: [],
  login: async () => {},
  logout: async () => {},
  checkAuth: async () => {},
  addNotification: () => {},
  setUser: () => {},
  setIsAuthenticated: () => {},
  setToken: () => {}
});

let isCheckingAuth = false;

export function AppProvider({ children }) {
  const [user, setUser] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('user'));
    } catch {
      return null;
    }
  });
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('auth_token'));
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(() => localStorage.getItem('auth_token'));

  // Define checkAuth first before using it in useEffect or login
  const checkAuth = useCallback(async () => {
    if (isCheckingAuth) return;
    isCheckingAuth = true;

    try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        setIsAuthenticated(false);
        setUser(null);
        throw new Error('No auth token found');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/status`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        if (response.status === 401) {
          console.warn('Token expired or invalid, redirecting to login');
          setIsAuthenticated(false);
          setUser(null);
          localStorage.removeItem('auth_token');
          window.location.href = '/login';
          return;
        }
        throw new Error('Auth check failed');
      }

      const data = await response.json();
      if (data.isAuthenticated) {
        setIsAuthenticated(true);
        setUser(data.user);
        return true;
      }

      throw new Error('Not authenticated');

    } catch (error) {
      console.error('Auth check failed:', error);
      setIsAuthenticated(false);
      setUser(null);
      localStorage.removeItem('auth_token');
      throw error;
    } finally {
      setTimeout(() => {
        isCheckingAuth = false;
      }, 1000);
    }
  }, []);

    // Then define other functions that depend on checkAuth
  const login = useCallback(async (authData) => {
    try {
      if (!authData.token) {
        throw new Error('No token provided');
      }

      // Store token first
      localStorage.setItem('auth_token', authData.token);
      
      // Then update state
      setIsAuthenticated(true);
      if (authData.user) {
        const userStr = typeof authData.user === 'string' 
          ? authData.user 
          : JSON.stringify(authData.user);
        localStorage.setItem('user', userStr);
        setUser(authData.user);
      }
      
      // Set token in state
      setToken(authData.token);
      
    } catch (error) {
      console.error('Login failed:', error);
      // Clear everything on failure
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');
      setUser(null);
      setToken(null);
      setIsAuthenticated(false);
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');
      await authService.logout();
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  }, []);

  const addNotification = useCallback((notification) => {
    const id = Date.now();
    setNotifications(prev => [...prev, { ...notification, id }]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.id !== id));
    }, 5000);
  }, []);

  useEffect(() => {
    const checkInitialAuth = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        if (!token) {
          setIsAuthenticated(false);
          setUser(null);
          setIsLoading(false);
          return;
        }

        await checkAuth();
      } catch (err) {
        console.error('Initial auth check failed:', err);
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');
      } finally {
        setIsLoading(false);
      }
    };

    checkInitialAuth();
  }, [checkAuth]);

  useEffect(() => {
    setIsAuthenticated(!!user);
  }, [user]);

  if (isLoading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  return (
    <AppContext.Provider value={{
      user,
      isAuthenticated,
      isLoading,
      token,
      notifications,
      login,
      logout,
      checkAuth,
      addNotification,
      setUser,
      setIsAuthenticated,
      setToken
    }}>
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useApp must be used within an AppProvider');
    }
    
    // Add auth from context
    const { isAuthenticated, token } = context;
    
    return {
        ...context,
        isAuthenticated,
        token
    };
}; 