import { useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from '../components/AuthProvider';

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const checkTokenExpiration = (token) => {
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    return decoded.exp > Date.now() / 1000;
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
}; 