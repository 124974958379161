import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const ApprovalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StatusMessage = styled.div`
  font-size: 14px;
  color: #4B5563;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FCD34D;
    display: inline-block;
  }
`;

const EmailPreview = styled.div`
  background: #F9FAFB;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  padding: 16px;
  margin-left: 12px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -6px;
    top: 16px;
    width: 10px;
    height: 10px;
    background: #F9FAFB;
    border-left: 1px solid #E5E7EB;
    border-bottom: 1px solid #E5E7EB;
    transform: rotate(45deg);
  }
`;

const EmailHeader = styled.div`
  margin-bottom: 12px;
`;

const Label = styled.div`
  font-size: 12px;
  color: #6B7280;
  margin-bottom: 4px;
`;

const Subject = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #111827;
`;

const EmailBody = styled.div`
  font-size: 14px;
  line-height: 1.6;
  color: #374151;
  white-space: pre-wrap;
  margin-bottom: ${props => props.hasSignature ? '12px' : '0'};
  padding-bottom: ${props => props.hasSignature ? '12px' : '0'};
  border-bottom: ${props => props.hasSignature ? '1px solid #E5E7EB' : 'none'};
`;

const Signature = styled.div`
  font-size: 13px;
  color: #6B7280;
  padding-top: 12px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
  margin-left: 12px;
`;

const Button = styled.button`
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 6px;
  
  ${props => props.primary ? `
    background: #2563EB;
    color: white;
    border: 1px solid transparent;
    &:hover {
      background: #1D4ED8;
    }
  ` : props.destructive ? `
    color: #DC2626;
    background: #FEF2F2;
    border: 1px solid transparent;
    &:hover {
      background: #FEE2E2;
    }
  ` : `
    background: white;
    color: #374151;
    border: 1px solid #D1D5DB;
    &:hover {
      background: #F3F4F6;
    }
  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled(StatusMessage)`
  &:before {
    background: #34D399;
  }
  color: #065F46;
`;

// Keep track of processed email IDs to prevent duplicate rendering
const processedEmailIds = new Set();

const EmailApprovalUI = ({ email, onApprove, onReject }) => {
  const [status, setStatus] = useState('pending'); // pending, sending, approved, rejected
  const isProcessed = useRef(false);
  
  useEffect(() => {
    // Cleanup when component unmounts
    return () => {
      if (email?.id && status === 'approved') {
        processedEmailIds.add(email.id);
      }
    };
  }, [email?.id, status]);

useEffect(() => {
  isProcessed.current = processedEmailIds.has(email?.id) || ['approved', 'rejected'].includes(status);
}, [email?.id, status]);

useEffect(() => {
  setStatus('pending');
}, [email?.id]);
  
  console.log('EmailApprovalUI received email:', email, 'Status:', status, 'Is processed:', isProcessed.current);

  const handleApprove = async () => {
    if (isProcessed.current) {
      console.log('EmailApprovalUI: Ignoring approve action for already processed email:', email?.id);
      return;
    }
    
    setStatus('sending');
    await onApprove();
    setStatus('approved');
    
    // Mark as processed
    if (email?.id) {
      processedEmailIds.add(email.id);
      isProcessed.current = true;
    }
  };

  const handleReject = () => {
    if (isProcessed.current) {
      console.log('EmailApprovalUI: Ignoring reject action for already processed email:', email?.id);
      return;
    }
    
    onReject('FEEDBACK_MODE'); // Special flag to tell AgentChat to enter feedback mode
    
    // Mark as processed
    if (email?.id) {
      processedEmailIds.add(email.id);
      isProcessed.current = true;
    }
  };

  if (status === 'approved') {
    return (
      <SuccessMessage>
        Email approved and sent successfully
      </SuccessMessage>
    );
  }

  return (
    <ApprovalWrapper>
      <StatusMessage>
        Waiting for your approval to send this email
      </StatusMessage>
      
      <EmailPreview>
        <EmailHeader>
          <Label>To</Label>
          <Subject>{email.recipient_email}</Subject>
          <Label style={{ marginTop: '8px' }}>Subject</Label>
          <Subject>{email.subject}</Subject>
        </EmailHeader>
        
        <EmailBody hasSignature={!!email.signature}>
          {email.body}
        </EmailBody>
        
        {email.signature && (
          <Signature>{email.signature}</Signature>
        )}
      </EmailPreview>

      <Actions>
        <Button 
          destructive
          onClick={(e) => { console.log('Reject button clicked', e); handleReject(); }}
          disabled={status === 'sending' || isProcessed.current}
        >
          Reject & Provide Feedback
        </Button>
        <Button 
          primary
          onClick={(e) => { console.log('Approve button clicked', e); handleApprove(); }}
          disabled={status === 'sending' || isProcessed.current}
        >
          {status === 'sending' ? 'Sending...' : 'Approve & Send'}
        </Button>
      </Actions>
    </ApprovalWrapper>
  );

};

export default EmailApprovalUI;
