import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth';
import { firebaseConfig } from '../config/firebase';
import AppMessagingService from '../services/AppMessagingService';

const WebSocketContext = createContext(null);

export const CONNECTION_STATES = {
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  ERROR: 'error'
};

const SOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
 
export function WebSocketProvider({ children }) {
  const { token } = useAuth();
  const [connectionState, setConnectionState] = useState(CONNECTION_STATES.DISCONNECTED);
  const [error, setError] = useState(null);
  const eventHandlers = useRef(new Map());
  const joinedRooms = useRef(new Set());

  const handleMessage = useCallback((message) => {
    console.log('Received message:', message);
    
    // Generate a unique message ID for deduplication
    const messageId = message.id || `${message.type || message.event_name}_${message.timestamp || Date.now()}`;
    
    // Create a content signature for additional deduplication
    const contentSignature = message.campaign_id && message.content ? 
      `${message.campaign_id}_${typeof message.content === 'string' ? message.content : JSON.stringify(message.content)}` : null;
    
    // Create multiple keys for deduplication
    const processedKey = `processed_${messageId}`;
    const contentKey = contentSignature ? `content_${contentSignature}` : null;
    
    // Check if message was already processed by ID or content signature
    if (eventHandlers.current.get(processedKey) || 
        (contentKey && eventHandlers.current.get(contentKey))) {
      console.log('Skipping duplicate message:', messageId, contentKey);
      return;
    }
    
    // Mark as processed using both keys
    eventHandlers.current.set(processedKey, true);
    if (contentKey) {
      eventHandlers.current.set(contentKey, true);
    }
    
    // Process agent-related events and email review requests
    if (message.event_name && [
      'agents_list',
      'campaign_started',
      'agent_update',
      'agent_status_change',
      'get_agents',
      'email_review_request'
    ].includes(message.event_name)) {
      const handler = eventHandlers.current.get(message.event_name);
      if (handler) {
        let normalizedData;
        
        if (message.event_name === 'email_review_request') {
          normalizedData = {
            ...message,
            source: 'event',
            emailData: {
              body: message.body || message.content?.body || message.content,
              subject: message.subject || message.content?.subject,
              recipient_email: message.recipient_email || message.content?.recipient_email,
              id: messageId,
              is_html: message.is_html || message.content?.is_html || false,
              signature: message.signature || message.content?.signature || '',
              campaign_id: message.campaign_id
            },
            campaign_context: {
              blockAgents: true,
              ...(message.campaign_context || {})
            },
            sortTime: message.sortTime || Date.now(),
            sender: message.sender,
            room: message.room,
            agent_id: message.agent_id,
            recipient_id: message.recipient_id,
            delivered_to_socketio: message.delivered_to_socketio
          };
        } else {
          if (message.event_name === 'agents_list' || message.event_name === 'agent_status_change') {
            normalizedData = Array.isArray(message.data) ? 
              message.data.map(item => ({
                id: item.id || item.agent_id,
                name: item.name,
                status: item.status || 'active',
                type: item.type || 'agent',
                source: 'event',
                sortTime: item.sortTime || Date.now(),
                timestamp: item.timestamp || Date.now()
              })) :
              [{
                id: message.data?.id || message.data?.agent_id || message.agent_id,
                name: message.data?.name || message.name,
                status: message.data?.status || message.status || 'active',
                type: message.data?.type || message.type || 'agent',
                source: 'event',
                sortTime: message.data?.sortTime || message.sortTime || Date.now(),
                timestamp: message.data?.timestamp || message.timestamp || Date.now()
              }];
          } else {
            normalizedData = Array.isArray(message.data) ? 
              message.data.map(item => ({
                ...item,
                source: 'event',
                sortTime: item.sortTime || Date.now(),
                sender: item.sender,
                room: item.room,
                agent_id: item.agent_id,
                recipient_id: item.recipient_id,
                delivered_to_socketio: item.delivered_to_socketio
              })).sort((a, b) => (a.timestamp || Date.now()) - (b.timestamp || Date.now())) :
              { ...(message.data || message), source: 'event' };
          }
        }

        handler(normalizedData);
        return;
      }
    }
    
    // Process typed messages
    if (message.type) {
      const handler = eventHandlers.current.get(message.type);
      if (handler) {
        const normalizedMessage = {
          ...message,
          ...(message.data || {}),
          timestamp: message.timestamp || Date.now(),
          id: messageId,
          source: message.source || (message.event_name ? 'event' : (message.type ? 'room' : 'system')),
          sortTime: message.sortTime || Date.now(),
          sender: message.sender,
          room: message.room,
          agent_id: message.agent_id,
          recipient_id: message.recipient_id,
          delivered_to_socketio: message.delivered_to_socketio,
          campaign_context: message.campaign_context || {}
        };
        handler(normalizedMessage);
      }
    }
  }, []);

  const handleConnectionState = useCallback((state) => {
    console.log('Connection state changed:', state);
    if (typeof state === 'string') {
      switch (state) {
        case 'connected':
          setConnectionState(CONNECTION_STATES.CONNECTED);
          setError(null);
          joinedRooms.current.forEach(room => {
            AppMessagingService.joinRoom(room);
          });
          break;
        case 'disconnected':
          setConnectionState(CONNECTION_STATES.DISCONNECTED);
          break;
        case 'error':
          setConnectionState(CONNECTION_STATES.ERROR);
          break;
        default:
          console.warn('Unknown connection state:', state);
      }
    } else if (typeof state === 'object') {
      // Consider connected if either Firebase or Socket.IO is connected
      const status = state.firebaseConnected || state.socketConnected ? 
        CONNECTION_STATES.CONNECTED : 
        CONNECTION_STATES.DISCONNECTED;
      
      setConnectionState(status);
      
      if (status === CONNECTION_STATES.CONNECTED) {
        setError(null);
        joinedRooms.current.forEach(room => {
          AppMessagingService.joinRoom(room);
        });
      }
    }
  }, []);

  const handleError = useCallback((err) => {
    console.error('Connection error:', err);
    
    // Only set error state if it's a Firebase error
    if (err.source === 'firebase') {
      setConnectionState(CONNECTION_STATES.ERROR);
      setError(err);
    } else {
      // For Socket.IO errors, just log and continue
      console.warn('Socket.IO error (continuing with Firebase):', err);
    }
  }, []);

  useEffect(() => {
    if (!token) {
      setConnectionState(CONNECTION_STATES.DISCONNECTED);
      return;
    }

    console.log('Initializing messaging service...');
    setConnectionState(CONNECTION_STATES.CONNECTING);
    
    try {
      // Initialize app-level messaging service
      AppMessagingService.initialize({
        firebase: firebaseConfig,
        socketUrl: SOCKET_URL,
        token
      });

      // Add listeners
      AppMessagingService.addListener({
        message: handleMessage,
        stateChange: handleConnectionState,
        error: handleError
      });

    } catch (error) {
      console.error('Error initializing messaging service:', error);
      handleError({ ...error, source: 'firebase' });  // Mark as Firebase error
      setConnectionState(CONNECTION_STATES.ERROR);
    }

    // Cleanup listeners on unmount, but don't disconnect
    return () => {
      AppMessagingService.removeListener({
        message: handleMessage,
        stateChange: handleConnectionState,
        error: handleError
      });
    };
  }, [token, handleMessage, handleConnectionState, handleError]);

  const joinRoom = useCallback(async (room) => {
    if (!room) return false;
    
    try {
      const success = await AppMessagingService.joinRoom(room);
      if (success) {
        joinedRooms.current.add(room);
      }
      return success;
    } catch (err) {
      console.error('Failed to join room:', err);
      setError(err);
      return false;
    }
  }, []);

  const leaveRoom = useCallback(async (room) => {
    if (!room) return;
    
    try {
      await AppMessagingService.leaveRoom(room);
      joinedRooms.current.delete(room);
    } catch (err) {
      console.error('Failed to leave room:', err);
      setError(err);
    }
  }, []);

  const sendMessage = useCallback(async (room, message) => {
    if (!room) return false;
    
    try {
      return await AppMessagingService.sendMessage(message, room);
    } catch (err) {
      console.error('Failed to send message:', err);
      setError(err);
      return false;
    }
  }, []);

  const emit = useCallback(async (eventName, data) => {
    if (!eventName) return false;

    try {
      return await AppMessagingService.emit(eventName, data);
    } catch (err) {
      console.error('Failed to emit event:', err);
      setError(err);
      return false;
    }
  }, []);

  const on = useCallback((eventName, handler) => {
    if (!eventName || !handler) return;

    // Check if handler already exists to prevent duplicates
    const existingHandler = eventHandlers.current.get(eventName);
    if (existingHandler === handler) {
      console.log('Handler already registered for:', eventName);
      return;
    }

    // Clean up any existing handler before setting new one
    if (existingHandler) {
      console.log('Cleaning up existing handler for:', eventName);
      AppMessagingService.removeEventHandler(eventName, existingHandler);
    }

    console.log('Adding event handler for:', eventName);
    eventHandlers.current.set(eventName, handler);
    return AppMessagingService.handleEvent(eventName, handler, { once: false });
  }, []);

  const once = useCallback((eventName, handler) => {
    if (!eventName || !handler) return;

    console.log('Adding one-time event handler for:', eventName);
    return AppMessagingService.handleEvent(eventName, handler, { once: true });
  }, []);

  const off = useCallback((eventName, handler) => {
    if (!eventName) return;

    console.log('Removing event handler for:', eventName);
    
    // Only remove if the specific handler matches
    if (handler && eventHandlers.current.get(eventName) === handler) {
      eventHandlers.current.delete(eventName);
      AppMessagingService.removeEventHandler(eventName, handler);
    } else if (!handler) {
      // If no specific handler provided, remove all handlers for this event
      eventHandlers.current.delete(eventName);
      AppMessagingService.removeEventHandler(eventName);
    }
  }, []);

  const joinGroup = useCallback(async (groupType) => {
    return AppMessagingService.joinGroup(groupType);
  }, []);

  const leaveGroup = useCallback(async (groupType) => {
    return AppMessagingService.leaveGroup(groupType);
  }, []);

  return (
    <WebSocketContext.Provider
      value={{
        connectionState,
        isConnected: connectionState === CONNECTION_STATES.CONNECTED,
        error,
        emit,
        on,
        off,
        once,
        joinRoom,
        leaveRoom,
        sendMessage,
        joinGroup,
        leaveGroup
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket() {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
}

export default WebSocketContext;
