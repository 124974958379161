import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';
import { GoogleAuth } from '../components/GoogleAuth';
import { luxuryTheme as theme } from '../styles/theme';

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  background: white;
`;

const LeftPanel = styled.div`
  flex: 1;
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 50%, #1a1a1a 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing[64]};
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 100px rgba(0,0,0,0.2);

  @media (max-width: 1024px) {
    display: none;
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 70%);
`;

const BrandingContent = styled.div`
  color: white;
  text-align: center;
  position: relative;
  z-index: 1;
  max-width: 500px;
`;

const BrandLogo = styled.div`
  font-size: ${theme.typography.h3.fontSize};
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: ${theme.spacing[8]};
  
  img {
    width: 40px;
    height: 40px;
  }
  
  span {
    color: white;
  }
`;

const BrandMessage = styled.p`
  font-size: 1.25rem;
  line-height: 1.6;
  opacity: 0.9;
`;

const RightPanel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing[40]};
  background: white;
`;

const LoginContainer = styled(motion.div)`
  width: 100%;
  max-width: 440px;
  padding: ${theme.spacing[48]};
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12),
              0 2px 8px rgba(0, 0, 0, 0.06),
              0 0 1px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 48px rgba(0, 0, 0, 0.12),
                0 4px 16px rgba(0, 0, 0, 0.08),
                0 0 2px rgba(0, 0, 0, 0.08);
  }
`;

const WelcomeText = styled.div`
  text-align: center;
  margin-bottom: ${theme.spacing[40]};

  h2 {
    font-size: 2rem;
    font-weight: 700;
    color: ${theme.colors.text};
    margin-bottom: ${theme.spacing[16]};
  }

  p {
    color: ${theme.colors.gray600};
    font-size: 1.125rem;
    line-height: 1.5;
  }
`;


export const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useApp();

  const handleLoginSuccess = (userData) => {
    login(userData);
    navigate('/dashboard', { 
      state: { 
        openOutreachDialog: true,
        newLogin: true 
      },
      replace: true 
    });
  };

  const handleLoginError = (error) => {
    console.error('Login failed:', error);
  };

  return (
    <PageContainer>
      <LeftPanel>
        <GradientOverlay />
        <BrandingContent>
          <BrandLogo>GrupaAI - The WorkOS for AI Agents</BrandLogo>
          <BrandMessage>
            Scale your organization with autonomous AI teams working 24/7.
            From sales to engineering to marketing, our AI agents drive growth across every business function.
          </BrandMessage>
        </BrandingContent>
      </LeftPanel>

      <RightPanel>
        <LoginContainer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <WelcomeText>
            <h2>Welcome to GrupaAI</h2>
            <p>Sign in with Google to get started</p>
          </WelcomeText>

          <GoogleAuth 
            onSuccess={handleLoginSuccess}
            onError={handleLoginError}
          />
        </LoginContainer>
      </RightPanel>
    </PageContainer>
  );
};

export default LoginPage;