import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import AgentAvatar from './AgentAvatar';
import './MentionDropdown.css';

const MentionDropdown = ({ agents, query, position, onSelect, visible }) => {
  const filteredAgents = agents.filter(agent => 
    agent.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="mention-dropdown"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
          style={{
            top: position.top,
            left: position.left
          }}
        >
          {filteredAgents.length > 0 ? (
            filteredAgents.map(agent => (
              <motion.div
                key={agent.id}
                className="mention-item"
                onClick={() => onSelect(agent)}
                whileHover={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}
              >
                <div className="mention-item-avatar">
                  <AgentAvatar 
                    agent={agent} 
                    getAgentInitials={(name) => name.charAt(0).toUpperCase()}
                    style={{ width: '24px', height: '24px' }}
                  />
                </div>
                <div className="mention-item-name">
                  {agent.name}
                </div>
              </motion.div>
            ))
          ) : (
            <div className="mention-no-results">No agents found</div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

MentionDropdown.propTypes = {
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  query: PropTypes.string.isRequired,
  position: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default MentionDropdown;