import UnifiedMessaging from './UnifiedMessaging';

class AppMessagingService {
    static instance = null;
    
    constructor() {
        if (AppMessagingService.instance) {
            return AppMessagingService.instance;
        }
        
        this.messaging = null;
        this.activeOperations = new Set();
        this.listeners = new Set();
        AppMessagingService.instance = this;
    }

    async initialize(config) {
        if (this.messaging) {
            // If config changed, reinitialize
            if (this.messaging.token !== config.token) {
                await this.cleanup(); // Use the class cleanup method
            } else {
                return; // Already initialized with same config
            }
        }

        this.messaging = new UnifiedMessaging({
            ...config,
            onStateChange: (state) => {
                this.notifyListeners('stateChange', state);
            },
            onMessage: (message) => {
                this.notifyListeners('message', message);
            },
            onError: (error) => {
                this.notifyListeners('error', error);
            }
        });
    }

    // Register operation (like search) to prevent premature cleanup
    registerOperation(operationId) {
        this.activeOperations.add(operationId);
    }

    // Unregister operation when it's done
    unregisterOperation(operationId) {
        this.activeOperations.delete(operationId);
    }

    // Enhanced cleanup method
    async cleanup() {
        // Wait for any active operations to complete
        if (this.activeOperations.size > 0) {
            console.log('Waiting for active operations to complete before cleanup...');
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        if (this.activeOperations.size === 0 && this.messaging) {
            this.messaging.cleanup();
            this.messaging = null;
        }

        // Clear all listeners
        this.listeners.clear();
    }

    // Add listener for events
    addListener(listener) {
        this.listeners.add(listener);
    }

    // Remove listener
    removeListener(listener) {
        this.listeners.delete(listener);
    }

    // Notify all listeners of events
    notifyListeners(eventType, data) {
        this.listeners.forEach(listener => {
            if (listener[eventType]) {
                listener[eventType](data);
            }
        });
    }

    // Only cleanup if no active operations
    // cleanup() { // Removed duplicate cleanup method
    //     if (this.activeOperations.size === 0 && this.messaging) {
    //         this.messaging.cleanup();
    //         this.messaging = null;
    //     }
    // }

    // Proxy methods to UnifiedMessaging
    async joinRoom(room) {
        return this.messaging?.joinRoom(room);
    }

    async leaveRoom(room) {
        return this.messaging?.leaveRoom(room);
    }

    async sendMessage(message, room) {
        return this.messaging?.sendMessage(message, room);
    }

    async emit(eventName, data, room) {
        return this.messaging?.emit(eventName, data, room);
    }

    // Add event handler
    handleEvent(eventName, handler, options = { once: false }) {
        if (options.once) {
            return this.messaging?.once(eventName, handler);
        }
        return this.messaging?.on(eventName, handler);
    }

    // Remove event handler
    removeEventHandler(eventName, handler) {
        return this.messaging?.off(eventName, handler);
    }

    // Join group
    async joinGroup(groupType) {
        return this.messaging?.joinGroup(groupType);
    }

    // Leave group
    async leaveGroup(groupType) {
        return this.messaging?.leaveGroup(groupType);
    }

    // Get connection state
    get connectionState() {
        if (!this.messaging) return 'disconnected';
        return this.messaging.firebaseConnected || this.messaging.socketConnected ? 'connected' : 'disconnected';
    }

    async cleanupCampaignState() {
        return this.messaging?.cleanupCampaignState();
    }

    async removeEvent(eventName) {
        return this.messaging?.removeEvent(eventName);
    }
}

// Export singleton instance
export default new AppMessagingService();
