import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './ProfileMenu.css';

export const ProfileMenu = ({ user, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="profile-menu" ref={menuRef}>
      <button 
        className="avatar-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {user?.picture ? (
          <img 
            src={user.picture} 
            alt={user.name || 'Profile'} 
            className="avatar-image"
          />
        ) : (
          <div className="avatar-placeholder">
            {user?.name?.charAt(0) || 'U'}
          </div>
        )}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="dropdown-menu"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.1 }}
          >
            <a
              href="https://discord.gg/dDTem2P"
              target="_blank"
              rel="noopener noreferrer"
              className="logout-button"
              style={{ textDecoration: 'none' }}
            >
              Join Discord
            </a>
            <button
              onClick={() => {
                setIsOpen(false);
                onLogout();
              }}
              className="logout-button"
            >
              Logout
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};