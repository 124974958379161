// useImageCache.js
import { useState, useEffect } from 'react';
import imageCacheService from '../services/ImageCacheService';

export function useImageCache(url) {
    const [cachedUrl, setCachedUrl] = useState(() => {
        // Initialize with cached URL if available
        return url && imageCacheService.isImageCached(url) ? url : null;
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!url) return;

        // If already cached, no need to preload
        if (imageCacheService.isImageCached(url)) {
            setCachedUrl(url);
            return;
        }

        setIsLoading(true);
        setError(null);

        imageCacheService.preloadImage(url)
            .then(cachedUrl => {
                setCachedUrl(cachedUrl);
                setIsLoading(false);
            })
            .catch(err => {
                setError(err);
                setIsLoading(false);
            });
    }, [url]);

    return { 
        cachedUrl: imageCacheService.isImageCached(url) ? url : cachedUrl, 
        isLoading, 
        error 
    };
}
