import React, { useState, useCallback, useMemo, memo } from 'react';
import { flushSync } from 'react-dom';
import { useAuth } from './AuthProvider';
import './OutreachDialog.css';
import './OutreachDialog-step3.css';
import validator from 'validator';
import { PostEventSource } from '../services/PostEventSource';

const isValidUrl = (string) => {
  return validator.isURL(string, {
    require_protocol: true,
    require_valid_protocol: true,
    protocols: ['http', 'https'],
    require_host: true,
    require_tld: true
  });
};

const isPartialUrl = (string) => {
  if (string.startsWith('http://') || string.startsWith('https://')) {
    const withoutProtocol = string.replace(/^https?:\/\//, '');
    return withoutProtocol.length > 0 && !isValidUrl(string);
  }
  return false;
};

const OutreachDialog = ({ onStart, onClose, loading: externalLoading }) => {
  const { token } = useAuth();

  const baseurl = process.env.REACT_APP_API_URL || 'http://localhost:8080';

  const [currentStep, setCurrentStep] = useState(1);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [urlValidity, setUrlValidity] = useState('');
  const [missionStatement, setMissionStatement] = useState('');
  const [internalLoading, setInternalLoading] = useState(false);
  const [internalError, setInternalError] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [searchingProspects, setSearchingProspects] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [prospects, setProspects] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [schedulerLink, setSchedulerLink] = useState('');
  const [mode, setMode] = useState('autonomous');
  const [searchProgress, setSearchProgress] = useState({ current: 0, total: 0 });
  const [searchStatus, setSearchStatus] = useState('idle');
  const [seenUrls] = useState(new Set());
  const [prospectMap] = useState(new Map());
  const [totalToFind, setTotalToFind] = useState(0);

  const loading = externalLoading || internalLoading || searchingProspects;

  const getProspectScore = (prospect) => {
    // Define weights for different score components
    const weights = {
      fit_score: 0.3,        // How well they fit our target criteria
      query_match_score: 0.2, // How well they match the search query
      relevance_score: 0.2,   // Relevance to our needs
      decision_maker_score: 0.2, // Their decision making ability
      data_confidence: 0.1    // How confident we are in the data
    };

    // Get all available scores
    const scores = {
      fit_score: prospect.fit_score || prospect.analysis?.fit_score || 0,
      query_match_score: prospect.query_match_score || 0,
      relevance_score: prospect.relevance_score || prospect.analysis?.relevance_score || 0,
      decision_maker_score: prospect.decision_maker_score || 0,
      data_confidence: prospect.data_confidence || 0
    };

    // If scores are 0 or undefined, use prospect.score for initial yield
    if (prospect.score !== undefined) {
      const hasNoScores = scores.fit_score === 0 || scores.fit_score === undefined;
      if (hasNoScores) {
        // Only update the local scores object, don't mutate the prospect
        scores.fit_score = prospect.score;
        scores.query_match_score = prospect.score;
        scores.decision_maker_score = prospect.score;
        scores.relevance_score = prospect.score;
        scores.data_confidence = prospect.score;
      }
    }
    // Calculate weighted average
    let totalWeight = 0;
    let weightedSum = 0;

    Object.entries(weights).forEach(([key, weight]) => {
      if (scores[key] !== undefined && scores[key] !== null) {
        weightedSum += scores[key] * weight;
        totalWeight += weight;
      }
    });

    // Normalize by actual total weight used
    const finalScore = totalWeight > 0 ? weightedSum / totalWeight : 0;

    // Log score components for debugging
    console.log('Score components for', prospect.name, ':', {
      scores,
      finalScore
    });
    
    return finalScore;
  };

  const analyzeWebsite = useCallback(async (url) => {
    setInternalError(null);

    try {
      if (!token) {
        throw new Error('Not authenticated');
      }

      // Get Google credentials from session storage
      const googleCredentials = sessionStorage.getItem('google_credentials');
      console.log('Google credentials from session storage:', googleCredentials);
      
      if (!googleCredentials) {
        throw new Error('Google credentials not found. Please sign in with Google again.');
      }

      const response = await fetch(`${baseurl}/analyze_startup`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Google-Credentials': googleCredentials // Already a JSON string from sessionStorage
        },
        body: JSON.stringify({ url })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error response:', errorData);
        throw new Error(errorData.error || 'Failed to analyze website');
      }

      const data = await response.json();
      
      console.log('Analysis response:', data);
      
      if (data.success && data.analysis) {
        // Parse competitors if it's a string and ensure it's a valid array
        let competitors = [];
        if (typeof data.analysis.competitors === 'string') {
          if (data.analysis.competitors.toLowerCase() === 'not available' || 
              data.analysis.competitors.toLowerCase() === 'n/a') {
            competitors = [];
          } else {
            try {
              const parsed = JSON.parse(data.analysis.competitors);
              competitors = Array.isArray(parsed) ? parsed : [];
            } catch (e) {
              // If not JSON, try splitting by common separators
              competitors = data.analysis.competitors
                .split(/[,|;]/)
                .map(c => c.trim())
                .filter(c => c && c.toLowerCase() !== 'not available' && c.toLowerCase() !== 'n/a');
              console.warn('Competitors not in JSON format, split string:', competitors);
            }
          }
        } else if (Array.isArray(data.analysis.competitors)) {
          competitors = data.analysis.competitors;
        }

        // Filter out any "Not available" or invalid values
        competitors = competitors.filter(comp => 
          typeof comp === 'string' && 
          comp.toLowerCase() !== 'not available' &&
          comp.toLowerCase() !== 'n/a' &&
          comp.trim() !== ''
        );
          
        // Parse contact info if it's a string or ensure it's a valid object
        let contactInfo = {};
        if (typeof data.analysis.contact_information === 'string') {
          if (data.analysis.contact_information.toLowerCase() === 'not available' || 
              data.analysis.contact_information.toLowerCase() === 'n/a') {
            contactInfo = {};
          } else {
            contactInfo = {
              raw: data.analysis.contact_information
            };
          }
        } else if (typeof data.analysis.contact_information === 'object' && data.analysis.contact_information !== null) {
          contactInfo = data.analysis.contact_information;
        }

        setAnalysis({
          analysis: {
            solution_description: data.analysis.solution_description || 'No information available',
            how_it_works: data.analysis.how_it_works || 'No information available',
            target_customers: data.analysis.target_customers || 'No information available',
            contact_information: contactInfo
          },
          competitors: competitors
        });
        setInternalError(null);
      } else {
        console.error('Invalid response format:', data);
        throw new Error('Invalid response format');
      }
    } catch (err) {
      if (err.message === 'Not authenticated') {
        setInternalError('Please log in to analyze websites');
      } else {
        setInternalError('Failed to analyze website. Please try again.');
        console.error('Analysis error:', err);
      }
    } finally {
      setInternalLoading(false);
    }
  }, [token]);

  const startProspectSearch = useCallback(async () => {
    if (!missionStatement) return;

    setSearchingProspects(true);
    setProspects([]);
    setSearchProgress({ current: 0, total: 0 });
    setSearchStatus('searching');
    
    console.log('Initializing EventSource connection...');
    const eventSource = new PostEventSource(`${baseurl}/search_prospects`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept': 'text/event-stream',
        'X-Google-Credentials': sessionStorage.getItem('google_credentials')
      },
      body: JSON.stringify({
        campaign_mission: missionStatement,
        company_info: analysis,
        stream: true,
        search_id: Date.now().toString()
      })
    });
    
    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      setSearchError('Connection error occurred. Please try again.');
    };
    
    eventSource.onopen = () => {
      console.log('EventSource connection opened');
    };

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('Parsed SSE data:', data);
        
        if (data.status === 'started') {
          // Only handle 'started' if we're not already searching
          if (searchStatus !== 'searching') { //TODO: pay attention to this, we should remove it if we notice something weird
            console.log('Search started');
            setSearchStatus('searching');
            setSearchProgress({ current: 0, total: data.total_found || 0 });
            setTotalToFind(data.total_found || 0);
          }
        }
        else if (data.status === 'processing' || data.status === 'updated') {
          console.log(`${data.status === 'processing' ? 'Processing' : 'Updated'} data:`, data);

          // Update progress if provided
          if (data.current !== undefined && data.total_found !== undefined) {
            console.log('Progress update:', { current: data.current, total: data.total_found });
            setSearchProgress({ current: data.current, total: Math.max(data.total_found, searchProgress.total) });
            // Update total if it's larger
            if (data.total_found > totalToFind) {
              setTotalToFind(data.total_found);
            }
          }

          // Add or update prospects if provided
          if (data.prospects?.length > 0) {
            console.log('Received new prospects:', data.prospects);

            // Only filter prospects by score when processing is complete
            //TODO: come back to fix the issue with the UI not showing or updating/refreshing to show this updated data.
            if (data.status === 'completed') {
              data.prospects = data.prospects.filter(p => getProspectScore(p) * 100 > 40);
            }
            // Update prospects first
            const updatedProspects = (() => {
              // Update our persistent map with new prospects
              data.prospects.forEach(newProspect => {
                console.log('Processing prospect:', newProspect.name);
                const prospectKey = newProspect.linkedin_url;
                const existingProspect = prospectMap.get(prospectKey);

                // Create flat structure combining existing and new data
                const updatedProspect = {
                  // Start with existing data if any
                  ...existingProspect,
                  // Core fields
                  name: newProspect.name,
                  title: newProspect.title,
                  company: newProspect.company,
                  linkedin: newProspect.linkedin_url,
                  email: newProspect.email,
                  summary: newProspect.summary,
                  image_url: newProspect.image_url,
                  
                  // Raw data fields - preserve all
                  linkedin_data: newProspect.linkedin_data,
                  company_data: newProspect.company_data,
                  personal_data: newProspect.personal_data,
                  social_presence: newProspect.social_presence,
                  
                  // Scoring and analysis
                  score: newProspect.score,
                  fit_score: newProspect.fit_score,
                  query_match_score: newProspect.query_match_score,
                  relevance_score: newProspect.relevance_score,
                  decision_maker_score: newProspect.decision_maker_score,
                  data_confidence: newProspect.data_confidence,
                  similarity_metrics: newProspect.similarity_metrics || {},
                  
                  // Analysis fields
                  analysis: newProspect.analysis,
                  analysis_context: newProspect.analysis_context,
                  key_indicators: [...new Set([
                    ...(existingProspect?.key_indicators || []),
                    ...(newProspect.key_indicators || [])
                  ])],
                  highlights: [...new Set([
                    ...(existingProspect?.highlights || []),
                    ...(newProspect.highlights || [])
                  ])],
                  reasons: [...new Set([
                    ...(existingProspect?.reasons || []),
                    ...(newProspect.reasons || [])
                  ])],
                  why_good_prospect: newProspect.why_good_prospect,
                  
                  // Status and metadata
                  enrichment_status: newProspect.enrichment_status,
                  last_enriched_at: newProspect.last_enriched_at,
                  found_at: newProspect.found_at,
                  found_by_user: newProspect.found_by_user,
                  source: newProspect.source,
                  source_id: newProspect.source_id,
                  
                  // Data quality and verification
                  data_quality: newProspect.data_quality,
                  verification_status: newProspect.verification_status,
                  
                  // Additional fields
                  first_name: newProspect.first_name,
                  last_name: newProspect.last_name,
                  industry: newProspect.industry,
                  is_target: newProspect.is_target,
                  is_service_provider: newProspect.is_service_provider,
                  engagement_metrics: newProspect.engagement_metrics,
                  favicon: newProspect.favicon
                };

                // Calculate frontend score
                updatedProspect.score = getProspectScore(updatedProspect);

                if (!seenUrls.has(prospectKey)) {
                  seenUrls.add(prospectKey);
                }
                prospectMap.set(prospectKey, updatedProspect);
                console.log('Updated prospect map:', prospectKey, updatedProspect);
              });

              const values = Array.from(prospectMap.values());
              console.log('Returning updated prospects array:', values.length);
              return values;
            })();

            // If this is our first prospect(s), do the UI setup synchronously
            if (prospects.length === 0 && updatedProspects.length > 0) {
              console.log('First prospects received, updating synchronously');
              flushSync(() => {
                setProspects(updatedProspects);
                setSelectedProspect(updatedProspects[0]);
                if (currentStep === 1) {
                  setCurrentStep(2);
                }
              });
              console.log('Sync update complete, new prospects:', updatedProspects.length);
            } else {
              // For subsequent updates, no need for flushSync
              console.log('Updating prospects array:', updatedProspects.length);
              setProspects(updatedProspects);
            }
          }
        }
        else if (data.status === 'completed') {
          console.log('Received completed status', {
            currentProspects: prospects.length,
            dataTotal: data.total_found,
            message: data.message
          });
          
          const finalTotal = Math.max(totalToFind, data.total_found || 0);
          setSearchProgress({ current: finalTotal, total: finalTotal });
          setSearchStatus('completed');
          setSearchingProspects(false);
        }
        else if (data.status === 'error') {
          console.error('Search error:', data.message);
          setSearchStatus('idle');
          setSearchingProspects(false);
          //eventSource.close();
        }
      } catch (error) {
        console.error('Error parsing SSE message:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      setSearchError('Connection error. Please try again.');
      setSearchStatus('idle');
      setSearchingProspects(false);
      //eventSource.close();
    };
 
  }, [token, missionStatement, analysis, baseurl, currentStep, selectedProspect]);

  const handleStartCampaign = useCallback(() => {
    if (loading) {
      console.log('Campaign start already in progress');
      return;
    }

    try {
      setInternalLoading(true);
      setInternalError(null);
      const campaignData = {
        websiteUrl,
        schedulerLink,
        mode,
        prospects,
        analysis
      };
      
      console.log('OutreachDialog: Starting campaign with data:', campaignData);

      if (!onStart) {
        console.error('OutreachDialog: onStart prop is not defined');
        return;
      }

      onStart(campaignData);

    } catch (error) {
      console.error('OutreachDialog: Error starting campaign:', error);
      setInternalLoading(false);
      setInternalError('An unexpected error occurred. Please try again.');
    }
  }, [websiteUrl, schedulerLink, mode, prospects, analysis, onStart]);

  const handleNext = async () => {
    if (loading) return;
    
    if (currentStep === 1) {
      if (!websiteUrl.trim()) {
        setInternalError('Please enter a valid website URL for your business eg https://www.grupa.io');
        return;
      }
      try {
        await startProspectSearch();
      } catch (error) {
        console.error('Error in handleNext:', error);
        setSearchError(error.message);
      }
    } else if (currentStep === 2) {
      if (!missionStatement.trim()) {
        setSearchError('Please describe your mission');
        return;
      }
      setCurrentStep(3);
    } else if (currentStep === 3) {
      if (!schedulerLink.trim()) {
        setInternalError('Please provide your scheduler link');
        return;
      }
      if (!isValidUrl(schedulerLink)) {
        setInternalError('Please enter a valid URL starting with http:// or https://');
        return;
      }
      handleStartCampaign();
    }
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleUrlChange = (e) => {
    const inputUrl = e.target.value;
    setWebsiteUrl(inputUrl);
    if (!hasInteracted) setHasInteracted(true);
    
    if (!inputUrl) {
      setUrlValidity('');
    } else if (isValidUrl(inputUrl)) {
      setUrlValidity('valid');
    } else if (isPartialUrl(inputUrl)) {
      setUrlValidity('partial');
    } else {
      setUrlValidity('invalid');
    }
  };

  const handleUrlKeyPress = useCallback(async (e) => {
    if (e.key === 'Enter' && urlValidity === 'valid' && !loading) {
      e.preventDefault();
      setInternalLoading(true);
      await analyzeWebsite(websiteUrl);
    }
  }, [websiteUrl, urlValidity, analyzeWebsite, loading]);

  const handleSchedulerLinkChange = (e) => {
    const value = e.target.value;
    setSchedulerLink(value);
    setInternalError('');
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setInternalError('');
  };

  const handleMissionChange = (e) => {
    setMissionStatement(e.target.value);
  };

  const handleMissionKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && missionStatement.trim()) {
      e.preventDefault();
      handleNext();
    }
  }, [missionStatement, handleNext]);

  const getUrlInputClasses = () => {
    const classes = ['url-input'];
    if (loading) classes.push('loading');
    if (hasInteracted && urlValidity) classes.push(urlValidity);
    return classes.join(' ');
  };

  const ProspectImage = memo(({ prospect }) => {
    const [imageError, setImageError] = useState(false);

    const renderInitials = () => (
      <div className="initials-avatar">
        {prospect.name?.split(' ')
          .filter(part => part)
          .slice(0, 2)
          .map(part => part[0])
          .join('')}
      </div>
    );

    if (!prospect.image_url) {
      return renderInitials();
    }

    return imageError ? (
      renderInitials()
    ) : (
      <img 
        src={prospect.image_url} 
        alt={prospect.name || 'Profile'} 
        className="prospect-image"
        referrerPolicy="no-referrer"
        onError={() => setImageError(true)}
      />
    );
  });

  ProspectImage.displayName = 'ProspectImage';

  const StatusIndicator = () => {
    if (searchStatus !== 'searching') return null;
    
    return (
      <div className="search-status" style={{ 
        padding: '8px', 
        textAlign: 'center', 
        color: '#666',
        fontSize: '13px',
        opacity: searchStatus === 'searching' ? 1 : 0,
        transition: 'opacity 0.3s ease-out'
      }}>
        {searchProgress.total ? 
          `Found ${searchProgress.current} prospects out of ${searchProgress.total}...` : 
          'Searching for prospects...'}
      </div>
    );
  };

  const progressBarContainer = useMemo(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    width: '100%',
    height: '3px',
    backgroundColor: '#f3f4f6',
    overflow: 'hidden',
    margin: '0',
    padding: '0',
    opacity: searchStatus === 'searching' ? 1 : 0,
    transition: 'opacity 0.3s ease-out'
  }), [searchStatus]);

  const progressWidth = useMemo(() => {
    if (!searchProgress.total) return '0%';
    const percentage = (searchProgress.current / searchProgress.total) * 100;
    return `${Math.min(percentage, 100)}%`;
  }, [searchProgress.current, searchProgress.total]);

  const renderProgressBar = () => (
    <div style={progressBarContainer}>
      <div 
        className="progress-bar"
        style={{ width: progressWidth }}
        role="progressbar"
        aria-valuenow={searchProgress.current}
        aria-valuemin={0}
        aria-valuemax={searchProgress.total}
      />
    </div>
  );

  const ScoreBreakdown = ({ prospect }) => {
    const getMetricClass = (value) => {
      if (value >= 0.7) return 'excellent';
      if (value >= 0.5) return 'good';
      return 'average';
    };

    const getMetricLabel = (value) => {
      if (value >= 0.7) return 'Excellent';
      if (value >= 0.5) return 'Good';
      return 'Potential';
    };

    // Calculate and memoize metrics
    const metrics = useMemo(() => {
      // Calculate scores
      const scores = {
        fit_score: prospect.fit_score || prospect.analysis?.fit_score || prospect.score,
        query_match_score: prospect.query_match_score || prospect.score,
        relevance_score: prospect.relevance_score || prospect.analysis?.relevance_score || prospect.score,
        decision_maker_score: prospect.decision_maker_score || prospect.score,
        data_confidence: prospect.data_confidence || prospect.score
      };

      return [
          { 
            id: 'overall',
            label: 'Match Score',
            value: getProspectScore(prospect),
            icon: 'star'
          },
          { 
            id: 'fit',
            label: 'Ideal Fit',
            value: scores.fit_score || 0,
            icon: 'bullseye'
          },
          { 
            id: 'relevance',
            label: 'Relevance',
            value: scores.relevance_score || 0,
            icon: 'check-circle'
          },
          { 
            id: 'decision',
            label: 'Decider',
            value: scores.decision_maker_score || 0,
            icon: 'user-tie'
          }
      ];
    }, [prospect]);

    return (
      <div className="score-breakdown">
        <h3>
          <i className="fas fa-chart-line"></i>
          Prospect Insights
        </h3>
        <div className="score-metrics">
          {metrics.map(({ id, label, value, icon }) => {
            const metricClass = getMetricClass(value);
            const strengthLabel = getMetricLabel(value);
            return (
              <div key={id} className="score-metric-card">
                <div className="score-metric-label">
                  <div className="score-metric-header">
                    <i className={`fas fa-${icon} score-metric-icon`}></i>
                    <span>{label}</span>
                  </div>
                  <span className={`strength-indicator strength-${metricClass}`}>
                    {strengthLabel}
                  </span>
                </div>
                <div className="score-metric-value">
                  <div className="score-metric-bar">
                    <div 
                      className={`score-metric-bar-fill ${metricClass}`}
                      style={{ width: `${Math.round(value * 100)}%` }}
                    />
                  </div>
                  <span className="score-metric-percentage">
                    {Math.round(value * 100)}%
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderProspectList = () => {
    if (!prospects || prospects.length === 0) {
      return null;
    }

    return (
        <div className="prospects-layout">
          {/* Left Pane - Prospect List */}
          <div className="prospect-list">
            {/* Progress bar at top */}
          {renderProgressBar()}
          <StatusIndicator />
          {/* Prospects list */}
          {[...prospects].sort((a, b) => getProspectScore(b) - getProspectScore(a)).map((prospect, index) => (
            <div
              key={prospect.linkedin || index}
              className={`prospect-item ${selectedProspect?.linkedin === prospect.linkedin ? 'selected' : ''}`}
              onClick={() => {
                console.log('Selected prospect:', prospect);
                setSelectedProspect(prospect);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setSelectedProspect(prospect);
                }
              }}
              role="button"
              tabIndex={0}
              style={{
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <div className="prospect-info">
                <h3>{prospect.name || 'Unknown'}</h3>
                <div className="title">{prospect.title || prospect.position}</div>
                {(prospect.company) && (
                  <div className="company">
                    <i className="fas fa-building" />
                    <span>{prospect.company}</span>
                  </div>
                )}
                {prospect.location && (
                  <div className="location">
                    <i className="fas fa-map-marker-alt" />
                    <span>{prospect.location}</span>
                  </div>
                )}
              </div>
              <div className={`percentage-indicator ${
                getProspectScore(prospect) >= 0.7 ? 'percentage-high' :
                getProspectScore(prospect) >= 0.5 ? 'percentage-medium' :
                'percentage-low'
              }`}>
                <i className={`fas fa-${
                  getProspectScore(prospect) >= 0.7 ? 'check' :
                  getProspectScore(prospect) >= 0.5 ? 'circle' :
                  'circle'
                }`} />
                {Math.round(getProspectScore(prospect) * 100)}%
              </div>
            </div>
          ))}
        </div>

        {/* Right Content - Details and Analysis */}
        {selectedProspect && (
          <div className="prospect-content">
            <div className="prospect-details">
              <div className="prospect-image-container">
                <ProspectImage prospect={selectedProspect} />
              </div>
              <h2 className="prospect-name">{selectedProspect.name}</h2>
              <p className="prospect-title">{selectedProspect.title || selectedProspect.position}</p>
              {selectedProspect.linkedin && (
                <a 
                  href={selectedProspect.linkedin} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="prospect-linkedin"
                >
                  <i className="fab fa-linkedin"></i>
                  View LinkedIn Profile
                </a>
              )}
              <div className="prospect-summary">
                {selectedProspect.summary || selectedProspect.highlights?.join('\n')}
              </div>
            </div>

            {/* Analysis Section */}
            <div className="prospect-analysis-section">
              {/* Score Breakdown using component */}
              <ScoreBreakdown prospect={selectedProspect} />
                
              {/* Existing Reasons Section */}
              {(selectedProspect.why_good_prospect || selectedProspect.reasons || selectedProspect.highlights) && (
                <div className="prospect-reasons">
                  <h3>
                    <i className="fas fa-lightbulb"></i>
                    Why This Prospect?
                  </h3>
                  <ul>
                    {selectedProspect.why_good_prospect ? (
                      <li key="main-reason">
                        <i className="fas fa-check"></i>
                        {selectedProspect.why_good_prospect}
                      </li>
                    ) : (selectedProspect.reasons || selectedProspect.highlights || []).map((reason, index) => (
                      <li key={`reason-${index}`}>
                        <i className="fas fa-check"></i>
                        {reason}
                      </li>
                    ))}
                  </ul>
                </div>
              )} 
              {selectedProspect.key_indicators && selectedProspect.key_indicators.length > 0 && (
                <div className="analysis-section">
                  <div className="key-indicators">
                    <h3>Key Indicators</h3>
                    <ul>
                      {selectedProspect.key_indicators.map((indicator, index) => (
                        <li key={index}>{indicator}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderStep2 = () => {
    if (searchingProspects || prospects.length > 0) {
      return renderProspectList();
    }

    if (searchError) {
      return (
        <div className="empty-state">
          <div className="empty-state-content">
            <i className="fas fa-search" />
            <h3>Error occurred</h3>
            <p>{searchError}</p>
            <button 
              className="secondary-button"
              onClick={() => setCurrentStep(1)}
            >
              Go Back
            </button>
          </div>
        </div>
      );
    }

    if (!prospects.length) {
      return (
        <div className="empty-state">
          <div className="empty-state-content">
            <i className="fas fa-search" />
            <h3>No prospects found</h3>
            <p>Try adjusting the campaign mission: be more specific with the target.</p>
            <p> eg. YC founders in SF looking to hire founding engineers</p>
            <button 
              className="secondary-button"
              onClick={() => setCurrentStep(1)}
            >
              Go Back
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="prospects-layout">
        {/* Left Pane - Prospect List */}
        <div className="prospect-list">
          {/* Progress bar at top */}
          {renderProgressBar()}
          <StatusIndicator />
          {/* Prospects list */}
          {[...prospects].sort((a, b) => getProspectScore(b) - getProspectScore(a)).map((prospect, index) => (
            <div
              key={prospect.linkedin || index}
              className={`prospect-item ${selectedProspect === prospect ? 'selected' : ''}`}
              onClick={() => setSelectedProspect(prospect)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setSelectedProspect(prospect);
                }
              }}
              role="button"
              tabIndex={0}
              style={{
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <div className="prospect-info">
                <h3>{prospect.name || 'Unknown'}</h3>
                <div className="title">{prospect.title || prospect.position}</div>
                {(prospect.company) && (
                  <div className="company">
                    <i className="fas fa-building" />
                    <span>{prospect.company}</span>
                  </div>
                )}
                {prospect.location && (
                  <div className="location">
                    <i className="fas fa-map-marker-alt" />
                    <span>{prospect.location}</span>
                  </div>
                )}
              </div>
              <div className={`percentage-indicator ${
                getProspectScore(prospect) >= 0.7 ? 'percentage-high' :
                getProspectScore(prospect) >= 0.5 ? 'percentage-medium' :
                'percentage-low'
              }`}>
                <i className={`fas fa-${
                  getProspectScore(prospect) >= 0.7 ? 'check' :
                  getProspectScore(prospect) >= 0.5 ? 'circle' :
                  'circle'
                }`} />
                {Math.round(getProspectScore(prospect) * 100)}%
              </div>
            </div>
          ))}
        </div>

        {/* Right Content - Details and Analysis */}
        {selectedProspect && (
          <div className="prospect-content">
            <div className="prospect-details">
              <div className="prospect-image-container">
                <ProspectImage prospect={selectedProspect} />
              </div>
              <h2 className="prospect-name">{selectedProspect.name}</h2>
              <p className="prospect-title">{selectedProspect.title || selectedProspect.position}</p>
              {selectedProspect.linkedin && (
                <a 
                  href={selectedProspect.linkedin} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="prospect-linkedin"
                >
                  <i className="fab fa-linkedin"></i>
                  View LinkedIn Profile
                </a>
              )}
              <div className="prospect-summary">
                {selectedProspect.summary || selectedProspect.highlights?.join('\n')}
              </div>
            </div>

            {/* Analysis Section */}
            <div className="prospect-analysis-section">
              {/* Score Breakdown using component */}
              <ScoreBreakdown prospect={selectedProspect} />
                
              {/* Existing Reasons Section */}
              {(selectedProspect.why_good_prospect || selectedProspect.reasons || selectedProspect.highlights) && (
                <div className="prospect-reasons">
                  <h3>
                    <i className="fas fa-lightbulb"></i>
                    Why This Prospect?
                  </h3>
                  <ul>
                    {selectedProspect.why_good_prospect ? (
                      <li key="main-reason">
                        <i className="fas fa-check"></i>
                        {selectedProspect.why_good_prospect}
                      </li>
                    ) : (selectedProspect.reasons || selectedProspect.highlights || []).map((reason, index) => (
                      <li key={`reason-${index}`}>
                        <i className="fas fa-check"></i>
                        {reason}
                      </li>
                    ))}
                  </ul>
                </div>
              )} 
              {selectedProspect.key_indicators && selectedProspect.key_indicators.length > 0 && (
                <div className="analysis-section">
                  <div className="key-indicators">
                    <h3>Key Indicators</h3>
                    <ul>
                      {selectedProspect.key_indicators.map((indicator, index) => (
                        <li key={index}>{indicator}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    if (prospects.length > 0) {
      setSelectedProspect(prospects[0]);
    } else {
      setSelectedProspect(null);
    }
  }, [prospects]);

  return (
    <div className="outreach-dialog-overlay">
      <div className="outreach-dialog">
        <div className="dialog-header">
          <h2>Setup Mission for Agents</h2>
          <button className="close-button" onClick={handleClose}>×</button>
        </div>

        <div className="dialog-content">
          <div className={`step-content step-${currentStep}`} style={{ position: 'relative' }}>
            {currentStep === 1 && (
              <div>
                <div className="url-section">
                  <input
                    type="text"
                    value={websiteUrl}
                    onChange={handleUrlChange}
                    onKeyPress={handleUrlKeyPress}
                    disabled={loading}
                    placeholder={loading ? "Analyzing website..." : "Enter your startup's website URL"}
                    className={getUrlInputClasses()}
                  />
                  {internalError && <div className="error-message">{internalError}</div>}
                </div>

                <div className="quadrant-grid">
                  <div className="quadrant">
                    <h3>What We Do</h3>
                    <div className="quadrant-content">
                      {loading ? (
                        <div className="loading-placeholder" />
                      ) : (
                        <p>{analysis?.analysis?.solution_description || 'Enter a URL to analyze'}</p>
                      )}
                    </div>
                  </div>
                  <div className="quadrant">
                    <h3>How We Do It</h3>
                    <div className="quadrant-content">
                      {loading ? (
                        <div className="loading-placeholder" />
                      ) : (
                        <p>{analysis?.analysis?.how_it_works || 'Enter a URL to analyze'}</p>
                      )}
                    </div>
                  </div>
                  <div className="quadrant">
                    <h3>Target Audience</h3>
                    <div className="quadrant-content">
                      {loading ? (
                        <div className="loading-placeholder" />
                      ) : (
                        <p>{analysis?.analysis?.target_customers || 'Enter a URL to analyze'}</p>
                      )}
                    </div>
                  </div>
                  <div className="quadrant">
                    <h3>Competitors</h3>
                    <div className="quadrant-content">
                      {loading ? (
                        <div className="loading-placeholder" />
                      ) : analysis?.competitors?.length > 0 ? (
                        <ul>
                          {analysis.competitors.map((comp, index) => (
                            <li key={index}>{comp}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>Enter a URL to analyze</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mission-section">
                  <input
                    type="text"
                    className="mission-input"
                    value={missionStatement}
                    onChange={handleMissionChange}
                    onKeyPress={handleMissionKeyPress}
                    placeholder={!analysis ? "First analyze your startup above..." : "Enter your outreach campaign mission : eg. YC founders in SF looking to hire founding engineers"}
                    disabled={!analysis || searchingProspects}
                  />
                  {searchError && <div className="error-message">{searchError}</div>}
                </div>
              </div>
            )}
            {currentStep === 2 && renderStep2()}
            {currentStep === 3 && (
              <div className="scheduler-section">
                <div className="mode-options">
                  <div
                    role="radio"
                    aria-checked={mode === 'autonomous'}
                    tabIndex={0}
                    className={`mode-option ${mode === 'autonomous' ? 'selected' : ''}`}
                    onClick={() => handleModeChange('autonomous')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        handleModeChange('autonomous');
                      }
                    }}
                  >
                    <div className="mode-content">
                      <input
                        type="radio"
                        id="mode-autonomous"
                        name="mode"
                        value="autonomous"
                        checked={mode === 'autonomous'}
                        onChange={() => handleModeChange('autonomous')}
                        disabled={loading}
                      />
                      <label htmlFor="mode-autonomous">
                        <div className="mode-title">Autonomous Mode</div>
                        <div className="mode-description">
                          AI will handle the entire outreach process automatically
                        </div>
                      </label>
                    </div>
                  </div>

                  <div 
                    role="radio"
                    aria-checked={mode === 'supervised'}
                    tabIndex={0}
                    className={`mode-option ${mode === 'supervised' ? 'selected' : ''}`}
                    onClick={() => handleModeChange('supervised')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        handleModeChange('supervised');
                      }
                    }}
                  >
                    <div className="mode-content">
                      <input
                        type="radio"
                        id="mode-supervised"
                        name="mode"
                        value="supervised"
                        checked={mode === 'supervised'}
                        onChange={() => handleModeChange('supervised')}
                        disabled={loading}
                      />
                      <label htmlFor="mode-supervised">
                        <div className="mode-title">Supervised Mode</div>
                        <div className="mode-description">
                          AI works under your supervision: you review and approve
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="input-group">
                  <label htmlFor="scheduler-link">Scheduler Link</label>
                  <input
                    id="scheduler-link"
                    type="url"
                    className="scheduler-input"
                    value={schedulerLink}
                    onChange={handleSchedulerLinkChange}
                    placeholder="https://calendly.com/yourusername"
                  />
                </div>

                <div className="info-box">
                  <h4>Why we need this</h4>
                  <p>Some prospects prefer direct scheduling. Your booking link gives them a seamless way to get on your calendar.</p>
                </div>

                <div className="mission-summary">
                  <div className="mission-header">
                    <span className="mission-icon">🎯</span>
                    <h4>Mission Brief</h4>
                  </div>
                  <p className="mission-text">
                    {missionStatement}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dialog-footer">
          <div className="steps-indicator">
            {[1, 2, 3].map((step) => (
              <div
                key={step}
                className={`step-dot ${
                  step === currentStep ? 'active' : step < currentStep ? 'completed' : ''
                }`}
              />
            ))}
          </div>
          {currentStep === 1 && (
            <button
              className={`next-button ${searchingProspects ? 'searching' : ''}`}
              onClick={startProspectSearch}
              disabled={searchingProspects || !missionStatement.trim()}
            >
              {searchingProspects ? 'Finding Prospects' : 'Next'}
            </button>
          )}
          {currentStep === 2 && (
            <button
              className="next-button"
              onClick={() => {
                setCurrentStep(3);
              }}
              disabled={!selectedProspect}
            >
              Next
            </button>
          )}
          {currentStep === 3 && (
            <button
              className="next-button"
              onClick={handleNext}
              disabled={loading || !schedulerLink.trim() || !isValidUrl(schedulerLink)}
            >
              {loading ? (
                <>
                  <span className="spinner-small"></span>
                  {searchProgress.total ? `Loading ${searchProgress.current}/${searchProgress.total} prospects` : 'Loading...'}
                </>
              ) : (
                <>
                  Start Outreach
                  <span className="icon">🚀</span>
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export { OutreachDialog };