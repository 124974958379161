import React, { useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';
import { useWebSocket } from '../contexts/WebSocketContext'; 

const DashboardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MetricsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  min-height: 0;
`;

const MetricCard = styled(motion.div)`
  padding: 24px;
  background: #FFFFFF;
  border-radius: 16px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
  }

  h3 {
    margin: 0 0 12px 0;
    font-size: 14px;
    font-weight: 500;
    color: #6B7280;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: var(--font-family);
  }

  .metric-value {
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    color: #111827;
    font-family: var(--font-family);
    line-height: 1.2;
  }

  &[data-metric="agentCount"] .metric-value {
    color: ${props => props.value > 0 ? '#3B82F6' : '#111827'};
  }

  &[data-metric="emailsSent"] .metric-value {
    color: ${props => props.value > 0 ? '#1D9BF0' : '#111827'};
  }

  &[data-metric="emailsOpened"] .metric-value {
    color: ${props => props.value > 0 ? '#9333EA' : '#111827'};
  }

  &[data-metric="responses"] .metric-value {
    color: ${props => props.value > 0 ? '#FFC107' : '#111827'};
  }

  &[data-metric="meetings"] .metric-value {
    color: ${props => props.value > 0 ? '#22C55E' : '#111827'};
  }

  &[data-metric="responseRate"] .metric-value {
    color: ${props => props.value > 0 ? '#F97316' : '#111827'};
  }

  &[data-metric="conversionRate"] .metric-value {
    color: ${props => props.value > 0 ? '#EC4899' : '#111827'};
  }
`;

const ErrorMessage = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FEE2E2;
  color: #991B1B;
  padding: 16px 24px;
  border-radius: 8px;
  text-align: center;
  max-width: 80%;
  z-index: 100;

  button {
    margin-top: 12px;
    padding: 8px 16px;
    background: #DC2626;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    
    &:hover {
      background: #B91C1C;
    }
  }
`;

const LoadingOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 50;

  .loading-text {
    margin-top: 12px;
    color: #4B5563;
    font-size: 14px;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #E5E7EB;
    border-top-color: #3B82F6;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export function PerformanceDashboard({ metrics = {}, agentCount = 0 }) {
  const { isConnected, on, off, emit, error: socketError } = useWebSocket();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [localMetrics, setLocalMetrics] = useState({
    agentCount: agentCount || 0,
    emailsSent: metrics.emailsSent || 0,
    emailsOpened: metrics.emailsOpened || 0,
    responses: metrics.responses || 0,
    meetings: metrics.meetings || 0,
    responseRate: metrics.responseRate || 0,
    conversionRate: metrics.conversionRate || 0
  });

  const retryConnection = useCallback(() => {
    setError(null);
    setLoading(true);
    emit('get_metrics', {});
  }, [emit]);

  useEffect(() => {
    if (socketError) {
      setError(socketError.message || 'Failed to connect to server');
      setLoading(false);
    }
  }, [socketError]);

  useEffect(() => {
    let timeoutId;
    
    if (isConnected) {
      setError(null);
      
      // Use emit for socket events
      emit('get_metrics', {});
      
      // Set a timeout to show error if metrics don't load
      // Increased timeout from 10000 to 30000 (30 seconds) to allow more time for metrics to load
      timeoutId = setTimeout(() => {
        if (loading) {
          // Instead of showing an error, just silently stop the loading state
          // This prevents showing error messages when the backend is working but just slow
          setLoading(false);
        }
      }, 30000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isConnected, emit, loading]);

  useEffect(() => {
    // Update localMetrics immediately when agentCount changes
    setLocalMetrics(prev => ({
      ...prev,
      agentCount: agentCount
    }));
    console.log('PerformanceDashboard: Updating agent count to:', agentCount);
  }, [agentCount]);

  useEffect(() => {
    if (!isConnected) return;

    const handleMetricsUpdate = (message) => {
      if (!message || !message.content || !message.content.metrics) return;
      
      setLocalMetrics(prevMetrics => ({
        ...prevMetrics,
        ...message.content.metrics,
        // Keep agentCount from previous state
        agentCount: prevMetrics.agentCount
      }));
      setLoading(false);
    };

    const handleEmailSent = () => {
      setLocalMetrics(prevMetrics => ({
        ...prevMetrics,
        emailsSent: prevMetrics.emailsSent + 1
      }));
    };

    // Register event handlers for metrics updates and email events
    on('metrics_update', handleMetricsUpdate);
    on('email_sent', handleEmailSent);

    return () => {
      off('metrics_update', handleMetricsUpdate);
      off('email_sent', handleEmailSent);
    };
  }, [isConnected, on, off]);

  const cardVariants = {
    initial: { scale: 0.96, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0.96, opacity: 0 }
  };

  if (error) {
    return (
      <DashboardContainer>
        <ErrorMessage
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
        >
          <p>{error}</p>
          <button onClick={retryConnection}>Try Again</button>
        </ErrorMessage>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
      <MetricsGrid>
        <AnimatePresence>
          {loading && (
            <LoadingOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="loading-spinner" />
              <p className="loading-text">Loading metrics...</p>
            </LoadingOverlay>
          )}
        </AnimatePresence>

        <MetricCard
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-metric="agentCount"
          value={localMetrics.agentCount}
        >
          <h3>Active Agents</h3>
          <p className="metric-value">{localMetrics.agentCount}</p>
        </MetricCard>

        <MetricCard
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-metric="emailsSent"
          value={localMetrics.emailsSent}
        >
          <h3>Emails Sent</h3>
          <p className="metric-value">{localMetrics.emailsSent}</p>
        </MetricCard>

        <MetricCard
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-metric="emailsOpened"
          value={localMetrics.emailsOpened}
        >
          <h3>Emails Opened</h3>
          <p className="metric-value">{localMetrics.emailsOpened}</p>
        </MetricCard>

        <MetricCard
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-metric="responses"
          value={localMetrics.responses}
        >
          <h3>Responses</h3>
          <p className="metric-value">{localMetrics.responses}</p>
        </MetricCard>

        <MetricCard
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-metric="meetings"
          value={localMetrics.meetings}
        >
          <h3>Meetings</h3>
          <p className="metric-value">{localMetrics.meetings}</p>
        </MetricCard>

        <MetricCard
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-metric="responseRate"
          value={localMetrics.responseRate}
        >
          <h3>Response Rate</h3>
          <p className="metric-value">{localMetrics.responseRate}</p>
        </MetricCard>

        <MetricCard
          variants={cardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-metric="conversionRate"
          value={localMetrics.conversionRate}
        >
          <h3>Conversion Rate</h3>
          <p className="metric-value">{localMetrics.conversionRate}</p>
        </MetricCard>
      </MetricsGrid>
    </DashboardContainer>
  );
}

export default React.memo(PerformanceDashboard);