import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { LandingPage } from './pages/LandingPage';
import { LoginPage } from './pages/LoginPage';
import { AuthCallback } from './components/AuthCallback';
import DashboardPage from './pages/DashboardPage';
import { useAuth } from './components/AuthProvider';
import { ErrorBoundary } from './components/ErrorBoundary';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';
 
export function AppRoutes() {
    const { isAuthenticated, isLoading } = useAuth();
    const location = useLocation();
    
    // Show loading state while auth is being verified
    if (isLoading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Verifying authentication...</p>
            </div>
        );
    }

    return (
        <ErrorBoundary>
            <Suspense fallback={
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading application...</p>
                </div>
            }>
                <Routes>
                    <Route path="/" element={
                        isAuthenticated ? (
                            <Navigate to="/dashboard" replace />
                        ) : (
                            <LandingPage />
                        )
                    } />
                    
                    <Route path="/login" element={
                        isAuthenticated ? (
                            <Navigate to="/dashboard" replace />
                        ) : (
                            <LoginPage />
                        )
                    } />
                    
                    <Route path="/callback" element={<AuthCallback />} />

                    <Route path="/dashboard" element={
                        isAuthenticated ? (
                            <DashboardPage />
                        ) : (
                            <Navigate to="/login" replace state={{ from: location }} />
                        )
                    } />

                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfService />} />

                    {/* Catch all route */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Suspense>
        </ErrorBoundary>
    );
}