import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from './AuthProvider';
import config from '../config';
import './GoogleAuth.css';

export function GoogleAuth() {
    const { login } = useAuth();
    const [error, setError] = useState(null);

    const handleCredentialResponse = useCallback(async (response) => {
        try {
            if (!response.credential) {
                throw new Error('No credential received');
            }

            // Let the backend handle token validation and user extraction
            await login({
                token: response.credential
            });

        } catch (error) {
            console.error('Google auth error:', error);
            setError('Authentication failed. Please try again.');
        }
    }, [login]);

    useEffect(() => {
        // Load the Google Identity Services script
        const loadGoogleScript = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            script.onload = initializeGoogleSignIn;
            script.onerror = () => {
                console.error('Failed to load Google Sign-In script');
                setError('Failed to load authentication. Please try again later.');
            };
            document.body.appendChild(script);
            return script;
        };

        const initializeGoogleSignIn = () => {
            if (!window.google || !window.google.accounts) {
                console.error('Google Identity Services not loaded properly');
                setError('Authentication service not available. Please try again later.');
                return;
            }

            try {
                window.google.accounts.id.initialize({
                    client_id: config.googleClientId,
                    callback: handleCredentialResponse,
                    auto_select: false,
                    cancel_on_tap_outside: true
                });

                window.google.accounts.id.renderButton(
                    document.getElementById('googleSignInButton'),
                    { 
                        theme: 'filled_blue', 
                        size: 'large',
                        type: 'standard',
                        shape: 'pill',
                        text: 'continue_with',
                        width: 250
                    }
                );
            } catch (error) {
                console.error('Error initializing Google Sign-In:', error);
                setError('Failed to initialize authentication. Please try again later.');
            }
        };

        const script = loadGoogleScript();
        return () => {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        };
    }, [handleCredentialResponse]);

    return (
        <div className="google-auth-container">
            {error && <div className="error-message">{error}</div>}
            <div id="googleSignInButton"></div>
        </div>
    );
} 