// WebSocket URL configuration
const config = {
    // Google OAuth Configuration
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '905378160983-331elj7nemh1lc5rkttt9spvoo1fanrs.apps.googleusercontent.com',
    
    // API Configuration
    apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8080',
    wsUrl: process.env.REACT_APP_WEBSOCKET_URL || 'http://localhost:8080',
    //apiUrl: 'https://25f0-2601-646-8280-1c60-b40a-bd1d-39e0-976.ngrok-free.app',
    //wsUrl: 'https://25f0-2601-646-8280-1c60-b40a-bd1d-39e0-976.ngrok-free.app',
    // Environment
    isDevelopment: process.env.NODE_ENV === 'development',
    isProduction: process.env.NODE_ENV === 'production',
    
    // Socket Configuration
    socketOptions: {
        path: '/socket.io',
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionAttempts: Infinity,  
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 60000,  
        autoConnect: true,  
        withCredentials: true,
        forceNew: true,
        rejectUnauthorized: false,
        extraHeaders: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Google-Credentials'
        }
    },
    
    // Token Configuration
    tokenKey: 'auth_token',
    
    // API Endpoints
    endpoints: {
        auth: '/auth',
        verify: '/auth/verify',
        logout: '/auth/logout'
    },

    // Allowed Origins
    allowedOrigins: [
        process.env.REACT_APP_ALLOWED_ORIGINS.split(',').map(origin => origin.trim())
    ]
};

// Log configuration on load
console.log('Loaded configuration:', config);

export default config;
