import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useAuth } from '../components/AuthProvider';
import { Dashboard } from '../components/Dashboard';
import { CONNECTION_STATES } from '../contexts/WebSocketContext';

const DashboardPage = () => {
  const { connectionState, error: wsError } = useWebSocket();
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeDashboard = useCallback(async () => {
    try {
      if (!isAuthenticated || !user) {
        console.log('Not authenticated, redirecting to login');
        navigate('/login');
        return;
      }

      // Add debug logging
      console.log('Auth state:', { isAuthenticated, user });
      console.log('Connection state:', connectionState);

      // Connection is now handled automatically by UnifiedMessaging
      // when the component mounts with a valid token
      setIsInitialized(true);
      setLoading(false);
    } catch (err) {
      console.error('Dashboard initialization error:', err);
      setError(err);
      setLoading(false);
    }
  }, [isAuthenticated, user, navigate, connectionState]);

  // Initialize dashboard on mount and auth changes
  useEffect(() => {
    if (!isInitialized) {
      initializeDashboard();
    }
  }, [isInitialized, initializeDashboard]);

  // Handle WebSocket errors
  useEffect(() => {
    if (wsError) {
      setError(wsError);
    }
  }, [wsError]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Removed disconnect function usage
    };
  }, []);

  // Render loading state
  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-center space-y-4">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
          <h2 className="text-xl font-semibold text-gray-900">Loading Dashboard</h2>
          <p className="text-gray-500">
            {connectionState === CONNECTION_STATES.CONNECTING 
              ? 'Establishing connection...'
              : 'Loading your dashboard...'}
          </p>
        </div>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-center space-y-4">
          <div className="text-red-500 text-xl">
            <h2>Error Loading Dashboard</h2>
            <p>{error.message}</p>
          </div>
        </div>
      </div>
    );
  }

  // Render dashboard
  return (
    <div className="dashboard-page">
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <Dashboard isInitialized={isInitialized} setIsInitialized={setIsInitialized} />
      )}
    </div>
  );
};

export default DashboardPage;