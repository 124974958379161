import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FaArrowUp } from 'react-icons/fa';

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  border-radius: 12px;
  padding: 2rem;
`;

const ArrowIndicator = styled(motion.div)`
  color: #0066FF;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const Message = styled.h3`
  font-size: 1.5rem;
  color: #2d3748;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 500;
`;

const Subtitle = styled.p`
  font-size: 1.1rem;
  color: #718096;
  text-align: center;
  max-width: 400px;
  line-height: 1.6;
`;

const PlaceholderUI = () => {
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <ArrowIndicator
        animate={{
          y: [-10, 0, -10],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <FaArrowUp />
      </ArrowIndicator>
      <Message>Ready to Start Your Campaign?</Message>
      <Subtitle>
        Click the button above to begin your AI-powered outreach mission. 
        Our agents are ready to help you achieve your goals.
      </Subtitle>
    </Container>
  );
};

export default PlaceholderUI;