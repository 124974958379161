import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';
import { luxuryTheme as theme } from '../styles/theme';
import { FaChartLine, FaComments, FaRegLightbulb } from 'react-icons/fa';

const Container = styled.div`
  min-height: 120vh;
  width: 100vw;
  max-width: 100vw;
  background: linear-gradient(135deg, #121212 0%, #1e1e1e 100%);
  color: ${theme.colors.text};
  position: relative;
  padding-bottom: ${theme.spacing[160]};
  margin: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

/* Ensure the body has no margins */
document.body.style.margin = '0';
document.body.style.padding = '0';
document.body.style.width = '100vw';
document.body.style.overflowX = 'hidden';
document.body.style.overflowY = 'auto';


const RotatingWord = styled(motion.span)`
  display: inline-block;
  margin: 0 8px;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.04) 100%);
  pointer-events: none;
`;

const Logo = styled.div`
  font-size: ${theme.typography.h3.fontSize};
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: ${theme.spacing[8]};
  
  img {
    width: 40px;
    height: 40px;
  }
  
  span {
    background: linear-gradient(135deg,rgb(196, 196, 196) 0%,rgb(190, 190, 190) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 4px rgba(255, 255, 255, 0.15);
  }
`;

const Header = styled.header`
  padding: ${theme.spacing[24]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
`;

const Nav = styled.nav`
  display: flex;
  gap: ${theme.spacing[16]};
  margin-right: ${theme.spacing[24]};
`;

const NavButton = styled(motion.button)`
  padding: ${theme.spacing[12]} ${theme.spacing[24]};
  border-radius: 12px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.primary {
    background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
    color: white;
    box-shadow: 0 4px 20px rgba(0, 102, 255, 0.15);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 30px rgba(0, 102, 255, 0.2);
    }
  }

  &.secondary {
    background: white;
    color: #0066FF;
    border: 2px solid rgba(0, 102, 255, 0.1);
    box-shadow: 0 2px 10px rgba(0, 102, 255, 0.05);

    &:hover {
      border-color: rgba(0, 102, 255, 0.2);
      transform: translateY(-2px);
      box-shadow: 0 4px 20px rgba(0, 102, 255, 0.1);
    }
  }
`;

const CTAButton = styled(motion.button)`
  padding: ${theme.spacing[16]} ${theme.spacing[40]};
  font-size: 1.125rem;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 4px 20px rgba(0, 102, 255, 0.15);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 30px rgba(0, 102, 255, 0.2);
  }
`;

const Hero = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: ${theme.spacing[64]} ${theme.spacing[24]};
  text-align: center;
  position: relative;
  z-index: 2;
`;

const Title = styled(motion.h1)`
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: ${theme.spacing[24]};
  line-height: 1.2;
  letter-spacing: -0.02em;

  span {
    background: linear-gradient(135deg, #ffffff 0%, #cccccc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 4px rgba(255, 255, 255, 0.15);
  }

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.25rem;
  margin-bottom: ${theme.spacing[48]};
  color: ${theme.colors.gray600};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;



const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${theme.spacing[32]};
  max-width: 1200px;
  margin: ${theme.spacing[96]} auto;
  padding: 0 ${theme.spacing[24]};
  position: relative;
  z-index: 2;
  margin-bottom: ${theme.spacing[96]};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Footer = styled.footer`
  padding: ${theme.spacing[40]} ${theme.spacing[24]};
  position: relative;
  z-index: 2;
  margin-top: ${theme.spacing[96]};
  width: 100%;
  background: 1e1e1e;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.spacing[24]};

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: ${theme.spacing[24]};
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  a {
    color: ${theme.colors.gray400};
    text-decoration: none;
    transition: color 0.2s;
    font-size: 0.875rem;

    &:hover {
      color: ${theme.colors.gray200};
    }
  }
`;

const Copyright = styled.div`
  color: ${theme.colors.gray500};
  font-size: 0.875rem;
`;

const FeatureCard = styled(motion.div)`
  background: white;
  padding: ${theme.spacing[40]};
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: all 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  }

  .icon {
    font-size: 2rem;
    margin-bottom: ${theme.spacing[24]};
    color: ${theme.colors.primary};
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: ${theme.spacing[16]};
    color: ${theme.colors.text};
    font-weight: 600;
  }

  p {
    color: ${theme.colors.gray600};
    line-height: 1.6;
  }
`;

export function LandingPage() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const rotatingWords = [ 
    { word: 'Work', context: '' },
    { word: 'Sales', context: '' },
    { word: 'Marketing', context: '' },
    { word: 'Engineering', context: '' },
    { word: 'Support', context: '' }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prev) => (prev + 1) % rotatingWords.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [rotatingWords.length]);

  return (
    <Container>
      <GradientOverlay />
      <Header>
        <Logo>
          <img src="/logo192.png" alt="GrupaAI Logo" />
          <span>GrupaAI</span>
        </Logo>
        <Nav>
          <a href="https://discord.gg/dDTem2P" target="_blank" rel="noopener noreferrer" style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '6px',
            background: 'rgba(88, 101, 242, 0.1)', 
            padding: '8px 12px', 
            color: '#FFFFFF',
            borderRadius: '8px',
            transition: 'all 0.2s ease',
            textDecoration: 'none',
            marginRight: '12px'
          }} 
          onMouseEnter={(e) => e.currentTarget.style.background = 'rgba(88, 101, 242, 0.2)'}
          onMouseLeave={(e) => e.currentTarget.style.background = 'rgba(88, 101, 242, 0.1)'}
          >
            <img src="/discord-icon.svg" alt="Discord" style={{ width: '18px', height: '18px' }} />
            Join Discord
          </a>
          <Link to="/login">
            <NavButton 
              className="primary"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Sign In
            </NavButton>
          </Link>
        </Nav>
      </Header>

      <Hero>
        <Title
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <span>
            Coordinate AI Agents to do{' '}
            <AnimatePresence mode="wait">
              <RotatingWord
                key={currentWordIndex}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                {rotatingWords[currentWordIndex].word}
              </RotatingWord>
            </AnimatePresence>
          </span>
        </Title>
        <Subtitle
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
         Get AI Agents to work 24/7 to help you build, grow and scale your business.
        </Subtitle>
        <Link to="/login">
          <CTAButton
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            Try our Fintech Sales Agents
          </CTAButton>
        </Link>

        {/* Stats section temporarily hidden
        /* const Stats = styled.div`
          display: flex;
          justify-content: center;
          gap: ${theme.spacing[64]};
          margin-top: ${theme.spacing[64]};
          text-align: center;
      
          @media (max-width: 768px) {
            flex-direction: column;
            gap: ${theme.spacing[32]};
          }
        `;
      
        const StatItem = styled(motion.div)`
          .number {
            font-size: 3rem;
            font-weight: bold;
            background: linear-gradient(135deg, #000000 0%, #333333 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: ${theme.spacing[8]};
          }
      
          .label {
            color: ${theme.colors.gray600};
            font-size: 1.125rem;
          }
        `; */
        }
      </Hero>

      <Features>
        <FeatureCard
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <FaRegLightbulb className="icon" />
          <h3>Unified Multi-Agent Workspace</h3>
          <p>No more juggling multiple AI agents, apps or tools. Manage every Business function autonomously in one unified workspace.</p>
        </FeatureCard>

        <FeatureCard
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <FaComments className="icon" />
          <h3>AI Agent Store</h3>
          <p>Our Growing Library of AI Agent teams, from <em>Sales to Marketing, Engineering</em> and more teams <b><em>(coming soon)</em></b> to <b> handle every business function.</b></p>
        </FeatureCard>

        <FeatureCard
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.4 }}
        >
          <FaChartLine className="icon" />
          <h3>Multi-Agent Orchestration</h3>
          <p>Your AI teams is orchestrated by AI Chief of Staff Agent to work together and deliver results for you </p>
        </FeatureCard>
      </Features>

      <Footer>
        <FooterContent>
          <Copyright>© {new Date().getFullYear()} GrupaAI. All rights reserved.</Copyright>
          <FooterLinks>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
            <a href="https://discord.gg/dDTem2P" target="_blank" rel="noopener noreferrer" style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '6px',
              background: 'rgba(88, 101, 242, 0.1)', 
              padding: '8px 12px', 
              borderRadius: '8px',
              transition: 'all 0.2s ease',
              marginTop: '-4px',
              position: 'relative',
              top: '-2px'
            }} 
            onMouseEnter={(e) => e.currentTarget.style.background = 'rgba(88, 101, 242, 0.2)'}
            onMouseLeave={(e) => e.currentTarget.style.background = 'rgba(88, 101, 242, 0.1)'}
            >
              <img src="/discord-icon.svg" alt="Discord" style={{ width: '18px', height: '18px' }} />
              Join Discord
            </a>
          </FooterLinks>
        </FooterContent>
      </Footer>
    </Container>
  );
}