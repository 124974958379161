import { useState, useEffect } from 'react';
import imageCacheService from '../services/ImageCacheService';

export function usePreloadedImage(url) {
    const [isReady, setIsReady] = useState(() => url ? imageCacheService.isImageLoaded(url) : false);

    useEffect(() => {
        if (!url) return;

        if (imageCacheService.isImageLoaded(url)) {
            setIsReady(true);
            return;
        }

        imageCacheService.preloadImage(url)
            .then(() => setIsReady(true))
            .catch(() => setIsReady(false));
    }, [url]);

    return isReady;
}
