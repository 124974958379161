import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import './HoverCard.css';
import { useImageCache } from '../hooks/useImageCache';
import { usePreloadedImage } from '../hooks/usePreloadedImage';
import { useAgentIdentity } from '../hooks/useAgentIdentity';

const HoverCardContext = React.createContext();

const HoverCardProvider = ({ children }) => {
  const [activeCardId, setActiveCardId] = useState(null);

  return (
    <HoverCardContext.Provider value={{ activeCardId, setActiveCardId }}>
      {children}
    </HoverCardContext.Provider>
  );
};

const HoverCard = ({ agent = {}, isVisible, position, avatarUrl }) => {
  const { activeCardId, setActiveCardId } = useContext(HoverCardContext);
  const [shouldShow, setShouldShow] = React.useState(false);

  React.useEffect(() => {
    if (!agent || !agent.id) return;
    
    if (isVisible && agent.id !== activeCardId) {
      setActiveCardId(agent.id);
      setShouldShow(true);
    } else if (!isVisible || agent.id === activeCardId) {
      setShouldShow(false);
    }
  }, [isVisible, agent, activeCardId, setActiveCardId]);

  // Convert third-person descriptions to first-person narratives
  const convertToFirstPerson = (text) => {
    if (!text || typeof text !== 'string') return '';
    
    // Common third-person to first-person replacements
    const replacements = [
      { from: /\bthey\b/gi, to: 'I' },
      { from: /\btheir\b/gi, to: 'my' },
      { from: /\bthem\b/gi, to: 'me' },
      { from: /\bthemselves\b/gi, to: 'myself' },
      { from: /\bthe agent\b/gi, to: 'I' },
      { from: /\bthe assistant\b/gi, to: 'I' },
      { from: /\bis responsible for\b/gi, to: 'am responsible for' },
      { from: /\bhandles\b/gi, to: 'handle' },
      { from: /\bmanages\b/gi, to: 'manage' },
      { from: /\banalyzes\b/gi, to: 'analyze' },
      { from: /\breviews\b/gi, to: 'review' },
      { from: /\bensures\b/gi, to: 'ensure' },
      { from: /\bworks\b/gi, to: 'work' },
      { from: /\bstudies\b/gi, to: 'study' },
      { from: /\bcoordinates\b/gi, to: 'coordinate' },
      { from: /\bfacilitates\b/gi, to: 'facilitate' },
    ];

    let result = text;
    replacements.forEach(({ from, to }) => {
      result = result.replace(from, to);
    });

    return result;
  };

  // Get agent role based on agent type
  const getAgentRole = (agentType) => {
    if (!agentType) return 'AI Assistant';
    console.log(agentType);
    const roles = {
      'Website_Analyzer_Agent': "Website Analysis Expert",
      'Sales_Assistant_Agent': "Sales & Outreach Specialist",
      'EmailReviewerAgent': "Email Review Specialist",
      'AuditOutgoingEmailAgent': "Email Audit Specialist",
      'EmailSenderAgent': "Email Delivery Expert",
      'EmailResponseGeneratorAgent': "Response Generation Expert",
      'EngagementAnalysisAgent': "Engagement Analyst",
      'FollowUpStrategizerAgent': "Follow-up Strategy Expert",
      'EmailResponderAgent': "Email Response Specialist",
      'human_proxy': "Human Interface Specialist",
      'chief_of_staff': "Chief of Staff",
      'agent_mediator': "Agent Coordination Specialist",
      'email_checker': "Email Verification Expert"
    };

    return roles[agentType] || "AI Assistant";
  };

  // Get agent description directly from the agent object or use a default
  const getAgentDescription = (agent) => {
    return agent.description || "I support the team by handling various tasks and ensuring smooth operations.";
  };

  // Format agent name to be more readable
  const formatAgentName = (name) => {
    if (!name) return '';
    
    // First replace underscores with spaces
    let formatted = name.replace(/_/g, ' ');
    
    // Then add spaces before capital letters in camelCase
    formatted = formatted.replace(/([A-Z])/g, ' $1');
    
    // Clean up any double spaces and trim
    formatted = formatted.replace(/\s+/g, ' ').trim();
    
    // Capitalize first letter of each word
    return formatted.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const { cachedUrl } = useImageCache(avatarUrl);
  const isImageReady = usePreloadedImage(avatarUrl);
  const { humanName } = useAgentIdentity(agent?.name);

  return (
    <AnimatePresence mode="wait">
      {shouldShow && (
        <motion.div
          className="hover-card"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0 }}
          key={agent.id || agent.name}
          style={{
            position: 'fixed',
            ...position,
            zIndex: 1000
          }}
        >
          <div className="hover-card-content">
            <div className="hover-card-header">
              <div className="agent-avatar">
                {cachedUrl && isImageReady ? (
                  <img 
                    src={cachedUrl} 
                    alt={formatAgentName(agent.name)}
                    style={{ visibility: isImageReady ? 'visible' : 'hidden' }}
                  />
                ) : (
                  <div className="agent-initials">
                    {(agent?.name || '').split(' ').map(word => word?.[0] || '').join('').toUpperCase()}
                  </div>
                )}
              </div>
              <div className="agent-info">
                <h3 className="agent-title">{humanName || formatAgentName(agent?.name)}</h3>
                <p className="agent-role">{getAgentRole(agent?.name)}</p>
              </div>
            </div>
            <p className="agent-description">{convertToFirstPerson(agent?.description || getAgentDescription(agent))}</p>
            {agent?.status && (
              <div className={`agent-status ${agent?.status?.toLowerCase() || ''}`}>
                Status: {agent?.status || 'Unknown'}
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

HoverCard.propTypes = {
  agent: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number
  }).isRequired,
  avatarUrl: PropTypes.string
};

export { HoverCardProvider, HoverCard };