import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { useApp } from '../contexts/AppContext';
import { authService } from '../services/auth';
import api from '../api'; 
import { jwtDecode } from 'jwt-decode'; 

export const AuthContext = createContext(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem(config.tokenKey));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); 
    const navigate = useNavigate();
    const { addNotification } = useApp();

    // Verify token on mount and token change
    const verifyToken = useCallback(async () => {
        if (!token) {
            setLoading(false);
            setIsAuthenticated(false);
            setUser(null);
            return;
        }

        try {
            // First verify token locally
            const decoded = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);
            
            if (!decoded || !decoded.exp || decoded.exp <= currentTime) {
                throw new Error('Token expired or invalid');
            }

            // Then verify with server
            const response = await authService.verifyAuth();
            
            if (response.valid) {
                setIsAuthenticated(true);
                // Update user data from response or keep existing if not provided
                setUser(response.user || decoded);
            } else {
                throw new Error(response.error || 'Server verification failed');
            }
        } catch (error) {
            console.error('Token verification error:', error);
            setIsAuthenticated(false);
            setUser(null);
            localStorage.removeItem(config.tokenKey);
            setToken(null);
            
            // Only redirect to login if not a network error
            if (!error.message.includes('Network error')) {
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    }, [token, navigate]);

    useEffect(() => {
        verifyToken();
    }, [verifyToken]);

    const login = useCallback(async (authData) => {
        try {
            if (!authData.token) {
                throw new Error('No token provided');
            }

            // Validate the Google credential with our backend
            const response = await authService.authenticateWithGoogle(authData.token);
            
            if (!response.success || !response.token) {
                throw new Error('Backend authentication failed');
            }

            // Use the token from our backend instead of the Google token
            setIsAuthenticated(true);
            setUser(response.user);
            setToken(response.token);

            // Store the backend-validated token
            localStorage.setItem(config.tokenKey, response.token);
            localStorage.setItem('user', JSON.stringify(response.user));

            // Store Google credentials in session storage if available
            if (response.google_credentials) {
                sessionStorage.setItem('google_credentials', JSON.stringify(response.google_credentials));
            }

            addNotification({
                type: 'success',
                message: `Welcome, ${response.user.name}!`
            });

            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
            localStorage.removeItem(config.tokenKey);
            localStorage.removeItem('user');
            setIsAuthenticated(false);
            setUser(null);
            setToken(null);
            setError(error.message);
            addNotification({
                type: 'error',
                message: 'Login failed: ' + error.message
            });
        }
    }, [navigate, addNotification]);

    const logout = useCallback(async () => {
        try {
            await authService.logout();
            setIsAuthenticated(false);
            setUser(null);
            setToken(null);
            localStorage.removeItem(config.tokenKey);
            localStorage.removeItem('user');
            sessionStorage.removeItem('google_credentials');
            navigate('/login');
            addNotification({
                type: 'success',
                message: 'Successfully logged out'
            });
        } catch (error) {
            console.error('Logout error:', error);
        }
    }, [navigate, addNotification]);

    const refreshToken = useCallback(async () => {
        try {
            const response = await api.post('/auth/refresh');
            if (response.data.token) {
                setToken(response.data.token);
                return response.data.token;
            }
            throw new Error('No token in refresh response');
        } catch (error) {
            console.error('Error refreshing token:', error);
            await logout();
            throw error;
        }
    }, [logout]);

    const value = {
        token,
        isAuthenticated,
        user,
        loading,
        error,
        login,
        logout,
        refreshToken
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};