import { useMemo } from 'react';
import { getHumanName, getAgentId, processMessageContent, prepareMessageForSending, getHumanInitials } from '../config/agentIdentities';

export const useAgentIdentity = (agentId) => {
  const humanName = useMemo(() => getHumanName(agentId), [agentId]);
  const initials = useMemo(() => getHumanInitials(agentId), [agentId]);
  
  return {
    humanName,
    initials,
    getHumanName,
    getAgentId,
    processMessageContent,
    prepareMessageForSending
  };
};
