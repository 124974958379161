import { ref, set, onValue, serverTimestamp,  query, orderByChild } from 'firebase/database';
 
class FirebaseEvents {
    constructor(database, userEmail) {
        // Initialize processedEvents as a standard Set
        this.processedEvents = new Set();
        this.database = database;
        this.userEmail = userEmail;
        this.listeners = new Map();
        this.eventRefs = new Map();
        this.rooms = new Set();
        
        // Get database prefix from environment
        this.dbPrefix = process.env.REACT_APP_FIREBASE_DATABASE_PREFIX || '';
        console.log('Firebase DB Prefix:', this.dbPrefix, 'from env:', process.env.REACT_APP_FIREBASE_DATABASE_PREFIX);
        
        this.sessionStartTime = Date.now();
        window.sessionStartTime = this.sessionStartTime; // Make available globally
        
        // Bind methods to ensure correct 'this' context
        this.isEventStale = this.isEventStale.bind(this);
        this.emit = this.emit.bind(this);
        this.on = this.on.bind(this);
        this.off = this.off.bind(this);
        this.cleanup = this.cleanup.bind(this)
        
        // Clean up any stale campaign state on initialization
        if (database && userEmail) {
            this.cleanupCampaignState().catch(error => {
                console.error('Failed to cleanup campaign state:', error);
            });
        }
    }

    encodeEmail(email) {
        if (!email) return '';
        // Replace all Firebase-invalid characters with underscores
        return email.replace(/[.#$[\]/]/g, '_');   
    }

    decodeEmail(encodedEmail) {
        if (!encodedEmail) return '';
        // If it doesn't contain underscores, it's probably not encoded
        if (!encodedEmail.includes('_')) return encodedEmail;
        // Replace underscores with dots for email format
        return encodedEmail.replace(/_/g, '.');
    }

    isEventStale(data) {
        if (!data) {
            console.debug('Event stale: No data provided');
            return true;
        }

        // Special handling for agent initialization and campaign events
        if (data.type === 'agents_initialized' || data.type === 'start_campaign' || 
            data.type === 'campaign_status' || data.type === 'campaign_started') {
            
            // First check sortTime since it's immediately available
            if (data.sortTime) {
                return data.sortTime < this.sessionStartTime;
            }
            
            // Always require timestamp
            if (!data.timestamp) {
                console.debug('Event stale: Missing timestamp for agent/campaign event');
                return true;
            }

            // For campaign events, check if they're from the current session
            const eventTime = data.timestamp.sv === 'timestamp' ? Date.now() : data.timestamp;
            if (eventTime < this.sessionStartTime) {
                console.debug('Event stale: Campaign event from previous session');
                return true;
            }

            // For start_campaign events, require userInitiated flag
            if (data.type === 'start_campaign' && !data.userInitiated) {
                console.debug('Event stale: Non-user-initiated campaign start');
                return true;
            }

            // If it's a start_campaign event, check if we already have a running campaign
            if (data.type === 'start_campaign') {
                const campaignStatus = window.campaignStatus || {};
                if (campaignStatus.isRunning) {
                    console.debug('Event stale: Campaign already running');
                    return true;
                }

                // Check if this is a stale start attempt (older than 30 seconds)
                const THIRTY_SECONDS = 30 * 1000;
                if (Date.now() - eventTime > THIRTY_SECONDS) {
                    console.debug('Event stale: Start campaign event too old');
                    return true;
                }
            }

            // For campaign_started events, ensure we process them for state updates
            if (data.type === 'campaign_started') {
                // Allow campaign_started events from current session
                if (data.timestamp && data.timestamp >= this.sessionStartTime) {
                    console.debug('Campaign started event is fresh');
                    return false;
                }
            }
        }

        // Special handling for email review requests to ensure proper ordering
        if (data.type === 'email_review_request') {
            // Always require timestamp for email reviews
            if (!data.timestamp) {
                console.debug('Email review stale: Missing timestamp');
                return true;
            }

            // Convert timestamp to number if it's a string
            const reviewTime = typeof data.timestamp === 'string' ? 
                new Date(data.timestamp).getTime() : data.timestamp;

            // Check if this review is from before the session started
            if (reviewTime < this.sessionStartTime) {
                console.debug('Email review stale: From previous session', {
                    reviewTime,
                    sessionStartTime: this.sessionStartTime
                });
                return true;
            }

            // Check if this review has already been processed
            const reviewKey = `email_review:${data.id}`;
            if (this.processedEvents.has(reviewKey)) {
                console.debug('Email review stale: Already processed', { id: data.id });
                return true;
            }

            return false;
        }

        // Handle chat messages - only show messages from current session
        if (data.type === 'group_chat_message' || data.type === 'message' || data.type === 'agent_message') {
            // First check sortTime since it's immediately available
            if (data.sortTime) {
                return data.sortTime < this.sessionStartTime;
            }

            // Check if message has a timestamp
            if (!data.timestamp) {
                console.debug('Chat message fresh: No timestamp (realtime)');
                return false; // Allow realtime messages without timestamp
            }

            // Handle server timestamp object
            if (typeof data.timestamp === 'object' && 
                (data.timestamp['.sv'] === 'timestamp' || data.timestamp['sv'] === 'timestamp')) {
                console.debug('Chat message fresh: Has server timestamp');
                return false;
            }

            // Convert timestamp to number if it's a string
            const messageTime = typeof data.timestamp === 'string' ? 
                new Date(data.timestamp).getTime() : data.timestamp;

            // Allow messages from current session
            if (messageTime >= this.sessionStartTime) {
                console.debug('Chat message fresh: From current session');
                return false;
            }

            console.debug('Chat message stale: From previous session', {
                messageTime,
                sessionStartTime: this.sessionStartTime
            });
            return true;
        }

        // If it's a Firebase push ID object, get the latest event
        if (typeof data === 'object' && !Array.isArray(data) && Object.keys(data).some(key => key.startsWith('-'))) {
            const events = Object.entries(data).map(([key, value]) => ({
                key,
                ...value
            }));
            
            // Sort by Firebase push ID (which is chronological)
            events.sort((a, b) => b.key.localeCompare(a.key));
            
            // Get the latest event
            const latestEvent = events[0];
            console.debug('Found latest event:', latestEvent);
            
            // Check the timestamp in the latest event
            return this.isEventStale(latestEvent);
        }
        
        // If it's a server timestamp object directly
        if (typeof data === 'object' && (data['.sv'] === 'timestamp' || data['sv'] === 'timestamp')) {
            console.debug('Event fresh: Is server timestamp');
            return false;
        }

        // Check timestamp field
        const timestamp = data.timestamp;
        if (!timestamp) {
            console.debug('Event stale: No timestamp field');
            return true;
        }

        // If it's a pending server timestamp
        if (typeof timestamp === 'object' && 
            (timestamp['.sv'] === 'timestamp' || timestamp['sv'] === 'timestamp')) {
            console.debug('Event fresh: Has server timestamp');
            return false;
        }

        // For numeric timestamps
        if (typeof timestamp === 'number') {
            // Check if event is from before this session
            if (timestamp < this.sessionStartTime) {
                console.debug('Event stale: From previous session', {
                    eventTime: timestamp,
                    sessionStartTime: this.sessionStartTime
                });
                return true;
            }
            return false;
        }

        // For ISO string timestamps
        if (typeof timestamp === 'string') {
            const eventTime = new Date(timestamp).getTime();
            // Check if event is from before this session
            if (eventTime < this.sessionStartTime) {
                console.debug('Event stale: From previous session', {
                    eventTime,
                    sessionStartTime: this.sessionStartTime
                });
                return true;
            }
            return false;
        }

        return false; // Default to not stale for other event types
    }

    // Helper to get prefixed path
    getPath(path) {
        const prefixedPath = this.dbPrefix ? `${this.dbPrefix}/${path}` : path;
        console.log('Firebase path:', path, '-> prefixed:', prefixedPath);
        return prefixedPath;
    }

       // Helper to get prefixed path: TODO- This was problematic, not sure why it was not working- i guess its already prefixed
    //getPath(path) {
        // Check if we're in development/testing mode to prepend 'dev/'
    //    const isDevelopment = process.env.NODE_ENV === 'development';
    //    let prefixedPath = this.dbPrefix ? `${this.dbPrefix}/${path}` : path;
        
        // Add 'dev/' prefix for development environment
    //    if (isDevelopment) {
    //        prefixedPath = `dev/${prefixedPath}`;
    //    }
        
    //    console.log('Firebase path:', path, '-> prefixed:', prefixedPath, '(isDev:', isDevelopment, ')');
    //    return prefixedPath;
    //}

    // Emit an event
    async emit(eventName, data) {
        if (!this.database) {
            console.warn('Firebase database not initialized');
            return false;
        }

        try {
            const encodedEmail = this.encodeEmail(this.userEmail);
            let eventPath;

            // All events now go to events/{eventName}/{encodedEmail}
            const room = data.room ? 
            (!data.room.includes('@') && !data.room.includes('.') ? data.room : this.encodeEmail(data.room))
            : encodedEmail;
            eventPath = this.getPath(`events/${eventName}/${room}`);
            
            // Clean the data to remove undefined values and ensure user_email is set
            const cleanData = JSON.parse(JSON.stringify({
                ...data,
                room: this.decodeEmail(room),  // Store decoded room in data
                namespace: data.namespace || '/',
                timestamp: serverTimestamp(),
                event_name: eventName,  // Always include event_name for routing
                type: data.type,  // Keep group_chat_message type for messages
                user_email: data.user_email || this.userEmail // Preserve passed user_email or use default
            }));

            console.log(`Emitting ${eventName} to Firebase:`, {
                path: eventPath,
                data: cleanData
            });

            const eventRef = ref(this.database, eventPath);
            await set(eventRef, cleanData);

            return true;
        } catch (error) {
            console.error(`Error emitting ${eventName} to Firebase:`, error);
            return false;
        }
    }

    // Generic event listener with proper reference tracking
    on(eventName, callback, options = {}) {
        if (!this.database || !callback) {
            console.warn('Firebase database not initialized or callback not provided');
            return;
        }

        try {
            // Bind the instance for use in callbacks
            const instance = this;
            const encodedEmail = instance.encodeEmail(instance.userEmail);
            let eventPath;

            // Special handling for initialization events
            if (eventName === 'agents_initialized' || eventName === 'initialization_error' || 
                eventName === 'start_campaign' || eventName === 'campaign_started') {
                
                eventPath = this.getPath(`events/${eventName}/${encodedEmail}`);
                console.log(`Setting up listener for ${eventName} at path: ${eventPath}`);
                
                const eventRef = ref(instance.database, eventPath);
                const eventQuery = query(eventRef, orderByChild('sortTime')); //make it realtime and limit to last 50 messages : NOte: we may increase this in the future

                const handler = onValue(eventQuery, (snapshot) => {
                    const rawData = snapshot.val();

                    if (!rawData) {
                        console.log(`No data exists at path: ${eventPath}`);
                        return;
                    }
                    console.log(`Raw Firebase event received for ${eventName}:`, {
                        path: eventPath,
                        data: rawData,
                        exists: snapshot.exists(),
                        rawData
                    });

                    if (!snapshot.exists()) {
                        console.log(`No data exists at path: ${eventPath}`);
                        return;
                    }

                    let dataToProcess = rawData;

                    // If it's a push ID format, get only the latest event
                    if (typeof rawData === 'object' && !Array.isArray(rawData)) {
                        const events = Object.entries(rawData)
                            .filter(([key]) => key.startsWith('-'))
                            .sort((a, b) => b[0].localeCompare(a[0])); // Sort by Firebase push ID (chronological)
                        
                        if (events.length > 0) {
                            const [pushIdKey, eventData] = events[0];
                            console.log(`Using latest event data from push ID ${pushIdKey}:`, eventData);
                            dataToProcess = eventData;
                        }
                    }

                    // Check if event is stale
                    if (instance.isEventStale(dataToProcess)) {
                        console.log(`Stale event received for ${eventName}:`, dataToProcess);
                        return;
                    }

                    // Format data to match expected structure
                    const formattedData = {
                        ...dataToProcess,  // Keep all original fields
                        success: dataToProcess.success === true,  // Explicit boolean check
                        message: dataToProcess.message || '',
                        campaign_id: dataToProcess.campaign_id,
                        type: dataToProcess.type || eventName,
                        timestamp: dataToProcess.timestamp,
                        room: dataToProcess.room,
                        namespace: dataToProcess.namespace || '/',
                        delivered_to_socketio: dataToProcess.delivered_to_socketio,
                        // Validate campaign status fields - if success is false, force status to stopped
                        status: dataToProcess.success === false ? 'stopped' : 
                               ['active', 'stopped', 'paused'].includes(dataToProcess.status) ? dataToProcess.status : 'stopped',
                        agents: Array.isArray(dataToProcess.agents) ? dataToProcess.agents : [],
                        error: dataToProcess.error || null
                    };
                    console.log(`Calling handler for ${eventName} with formatted data:`, formattedData);
                    callback(formattedData);
                }, { onlyOnce: options.once });

                // Store reference for cleanup
                if (!this.eventRefs.has(eventName)) {
                    this.eventRefs.set(eventName, new Map());
                }
                const eventHandlers = this.eventRefs.get(eventName);
                eventHandlers.set(callback, { ref: eventRef, handler });
                this.listeners.set(eventName, handler);
                return;
            }

            // For email_review_request, we need to listen to both room-specific and general paths
            if (eventName === 'email_review_request') {
                // Listen to room-specific path
                const room = options.room || encodedEmail;
                eventPath = this.getPath(`events/${eventName}/${room}`);
                
                // Also listen to the general path under the user's encoded email
                const generalPath = this.getPath(`events/${eventName}/${encodedEmail}`);
                const generalRef = ref(this.database, generalPath);
                
                // Track first arrival timestamps for deduplication
                const firstArrivals = new Map();
                
                const handleEmailReview = (data, source) => {
                    if (!data) return;
    
                    // Convert data to array and add metadata
                    const reviews = Object.entries(data)
                        .map(([eventId, eventData]) => ({
                            eventId,
                            ...eventData,
                            timestamp: eventData.timestamp || Date.now(),
                            source,
                            sortKey: `${eventData.timestamp || Date.now()}-${eventId}` // Composite key for stable sorting
                        }));
    
                    // Sort by timestamp first, then by eventId for stability
                    reviews.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
    
                    // Process reviews in chronological order with deduplication
                    for (const review of reviews) {
                        const eventKey = `${eventName}:${review.eventId}`;
                        
                        // Skip if already processed in this session
                        if (instance.processedEvents.has(eventKey)) {
                            continue;
                        }
                        
                        // Check first arrival source
                        if (firstArrivals.has(eventKey)) {
                            const firstArrival = firstArrivals.get(eventKey);
                            // Only process from original source
                            if (firstArrival.source !== source) {
                                continue;
                            }
                            // Check if enough time has passed since first arrival
                            const processingDelay = Date.now() - firstArrival.timestamp;
                            if (processingDelay < 1000) { // 1 second buffer for processing
                                continue;
                            }
                        }
                        
                        // Check if event is fresh
                        if (!instance.isEventStale(review)) {
                            // Record first arrival if not seen before
                            if (!firstArrivals.has(eventKey)) {
                                firstArrivals.set(eventKey, {
                                    timestamp: Date.now(),
                                    source,
                                    eventId: review.eventId
                                });
                            }
                            
                            // Mark as processed and notify
                            instance.processedEvents.add(eventKey);
                            callback({
                                ...review,
                                requiresApproval: true,
                                processedAt: Date.now()
                            });
                            
                            // Enforce blocking behavior
                            break;
                        }
                    }
                    
                    // Cleanup old first arrivals (older than 5 minutes)
                    const now = Date.now();
                    const CLEANUP_THRESHOLD = 5 * 60 * 1000; // 5 minutes
                    for (const [key, value] of firstArrivals.entries()) {
                        if (now - value.timestamp > CLEANUP_THRESHOLD) {
                            firstArrivals.delete(key);
                        }
                    }
                };
    
                // Set up handlers for both paths
                const generalHandler = onValue(generalRef, (snapshot) => {
                    handleEmailReview(snapshot.val(), 'general');
                }, { onlyOnce: options.once });
    
                const roomRef = ref(this.database, eventPath);
                const roomHandler = onValue(roomRef, (snapshot) => {
                    handleEmailReview(snapshot.val(), 'room');
                }, { onlyOnce: options.once });
    
                // Store references for cleanup
                if (!this.eventRefs.has(eventName)) {
                    this.eventRefs.set(eventName, new Map());
                }
                const eventHandlers = this.eventRefs.get(eventName);
                eventHandlers.set(callback, { 
                    ref: generalRef, 
                    handler: () => {
                        generalHandler();
                        roomHandler();
                    }
                });
            } else {
                // For other events, use standard room handling
                const room = options.room || encodedEmail;
                eventPath = this.getPath(`events/${eventName}/${room}`);
                
                // Capture instance for use in callbacks
                const instance = this;
                const eventRef = ref(instance.database, eventPath);
                const eventQuery = query(eventRef, orderByChild('sortTime'));
                const handler = onValue(eventQuery, (snapshot) => {
                    const data = snapshot.val();
                    if (!data) return;
                    
                    console.debug('Firebase event received:', {
                        eventName,
                        path: eventPath,
                        data: data,
                        isStale: instance.isEventStale(data)
                    });
    
                    // Sort email review requests by timestamp to ensure proper ordering
                    const reviews = Object.entries(data)
                        .map(([eventId, eventData]) => ({
                            eventId,
                            ...eventData,
                            timestamp: eventData.timestamp || Date.now()
                        }))
                        .sort((a, b) => a.timestamp - b.timestamp);
    
                    // Process reviews in order
                    for (const review of reviews) {
                        const eventKey = `${eventName}:${review.eventId}`;
                        if (!instance.processedEvents.has(eventKey) && !instance.isEventStale(review)) {
                            instance.processedEvents.add(eventKey);
                            // Add blocking flag to indicate this needs user approval
                            callback({
                                ...review,
                                requiresApproval: true
                            });
                            // Only process one review at a time to enforce blocking behavior
                            break;
                        }
                    }
                }, { onlyOnce: options.once });
    
                // Store reference for cleanup
                if (!this.eventRefs.has(eventName)) {
                    this.eventRefs.set(eventName, new Map());
                }
                const eventHandlers = this.eventRefs.get(eventName);
                eventHandlers.set(callback, { ref: eventRef, handler });
                this.listeners.set(eventName, handler);
            }
        } catch (error) {
            console.error(`Error setting up Firebase listener for ${eventName}:`, error);
        }
    }

    // Remove event listener with proper cleanup
    off(eventName, callback = null) {
        if (!this.database || !this.eventRefs.has(eventName)) return;

        const eventHandlers = this.eventRefs.get(eventName);
        
        if (callback) {
            const handlerInfo = eventHandlers.get(callback);
            if (handlerInfo) {
                handlerInfo.handler();
                eventHandlers.delete(callback);
            }
        } else {
            eventHandlers.forEach(({ handler }) => {
                handler();
            });
            eventHandlers.clear();
            this.eventRefs.delete(eventName);
            this.listeners.delete(eventName);
        }
    }

    // Room management
    async joinRoom(room) {
        if (!this.database) return false;

        try {
            const encodedUser = this.encodeEmail(this.userEmail);
            // Don't re-encode if room is already encoded (doesn't contain @ or . characters)
            const encodedRoom = !room.includes('@') && !room.includes('.') ? room : this.encodeEmail(room);
            const roomRef = ref(this.database, `rooms/${encodedRoom}/members/${encodedUser}`);
            
            await set(roomRef, {
                joined_at: new Date().toISOString(),
                active: true,
                server_timestamp: serverTimestamp()
            });

            this.rooms.add(room);  // Store original room name
            return true;
        } catch (error) {
            console.error(`Failed to join room ${room}:`, error);
            return false;
        }
    }

    async leaveRoom(room) {
        if (!this.database || !this.rooms.has(room)) return false;

        try {
            const encodedUser = this.encodeEmail(this.userEmail);
            const encodedRoom = room.includes('@') ? this.encodeEmail(room) : room;
            const roomRef = ref(this.database, `rooms/${encodedRoom}/members/${encodedUser}`);
            
            await set(roomRef, {
                left_at: new Date().toISOString(),
                active: false,
                server_timestamp: serverTimestamp()
            });

            this.rooms.delete(room);
            return true;
        } catch (error) {
            console.error(`Failed to leave room ${room}:`, error);
            return false;
        }
    }

    async cleanupCampaignState() {
        if (!this.database || !this.userEmail) return;

        const encodedEmail = this.encodeEmail(this.userEmail);
        const eventsToClean = [
            'agents_initialized',
            'campaign_started',
            'start_campaign',
            'campaign_status'
        ];

        for (const eventType of eventsToClean) {
            const eventPath = this.getPath(`events/${eventType}/${encodedEmail}`);
            const eventRef = ref(this.database, eventPath);
            
            try {
                // Clear any existing campaign status
                window.campaignStatus = { isRunning: false };
                
                // Clean up campaign state in Firebase
                await set(eventRef, null);
                
                // Reset local campaign tracking
                this.processedEvents.clear();
                
                console.log('Campaign state cleaned up successfully');
                // Remove the campaign state
                await set(eventRef, null);
                console.log('Cleaned up campaign state for user:', this.userEmail);
            } catch (error) {
                console.error('Error cleaning up campaign state:', error);
            }
        }
    }

    // Cleanup
    async cleanup() {
        // Clear the processedEvents Set
        this.processedEvents.clear();
        
        // Clean up campaign state
        await this.cleanupCampaignState();
        
        // Clean up Firebase event handlers with null checks
        if (this.eventRefs) {
            this.eventRefs.forEach((handlers) => {
                if (handlers && typeof handlers.forEach === 'function') {
                    handlers.forEach(({ handler }) => {
                        if (handler && typeof handler === 'function') {
                            try {
                                handler();
                            } catch (error) {
                                console.warn('Error during handler cleanup:', error);
                            }
                        }
                    });
                }
            });
        }
        
        // Clean up all listeners with null checks
        if (this.listeners && typeof this.listeners.entries === 'function') {
            for (const [eventName, callback] of this.listeners.entries()) {
                if (eventName && callback) {
                    try {
                        this.off(eventName, callback);
                    } catch (error) {
                        console.warn(`Error removing listener for ${eventName}:`, error);
                    }
                }
            }
        }
        
        // Clear all collections with null checks
        if (this.listeners && typeof this.listeners.clear === 'function') {
            this.listeners.clear();
        }
        if (this.eventRefs && typeof this.eventRefs.clear === 'function') {
            this.eventRefs.clear();
        }
        if (this.rooms && typeof this.rooms.clear === 'function') {
            this.rooms.clear();
        }
    }
}

export default FirebaseEvents;
