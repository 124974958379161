import { signInWithCustomToken } from 'firebase/auth';
import { ref, onValue, off, set, onDisconnect, query, limitToLast } from 'firebase/database';
import io from 'socket.io-client';
import FirebaseEvents from './FirebaseEvents';

class UnifiedMessaging {
    constructor(config) {
        if (!config) {
            throw new Error('Configuration is required');
        }

        this.firebaseConfig = config.firebase;
        this.socketUrl = config.socketUrl;
        this.token = config.token;
        this.onMessage = config.onMessage;
        this.onStateChange = config.onStateChange;
        this.onError = config.onError;
        this.socketId = null;
        this.initialized = false;
        this.firebaseConnected = false;
        this.socketConnected = false;
        this.messageHandlers = new Set();
        this.joinedRooms = new Set();
        this.firebaseListeners = new Map();
        this.pendingMessages = [];
        this.firebaseEvents = null;
        this.eventHandlers = new Map(); 
        this.oneTimeHandlers = new Map(); 
        this.reconnectAttempts = 0;
        this.MAX_RECONNECT_ATTEMPTS = 5;
        this.RECONNECT_TIMEOUT = 30000; // 30 seconds
        this.MAX_PENDING_MESSAGES = 100;
        this.recoveryTimeout = null;
        this.activeCampaigns = new Map();
        this.processedEvents = new Set(); // Initialize processedEvents

        // Initialize both Firebase and Socket
        this.initialize().catch(error => {
            console.error('Initialization error:', error);
            this.handleCriticalError(error);
        });
    }

    async initialize() {
        if (!this.token) {
            throw new Error('Token is required for initialization');
        }

        try {
            // Initialize Firebase first if configured
            if (this.firebaseConfig) {
                try {
                    await this.initializeFirebase();
                    console.log('Firebase initialized successfully');
                } catch (err) {
                    console.error('Firebase initialization error:', err);
                    this.onError?.(err);
                    // If Firebase fails, it's a critical error
                    throw err;
                }
            }
            
            // Initialize Socket.IO
            try {
                await this.initializeSocket();
                console.log('Socket initialized successfully');
            } catch (err) {
                console.warn('Socket initialization failed:', err);
                this.onError?.(err);
                // Only throw if we don't have Firebase
                if (!this.firebaseConnected) {
                    throw err;
                }
            }

            // Set initialized flag if we have at least one connection
            if (this.firebaseConnected || this.socketConnected) {
                this.initialized = true;
                console.log('UnifiedMessaging initialized successfully');
                await this.processPendingMessages();
                return true;
            } else {
                throw new Error('No available connections - both Firebase and Socket failed');
            }
        } catch (err) {
            console.error('Error initializing connections:', err);
            this.handleCriticalError(err);
            throw err;
        }
    }

    // Encode email for Firebase path
    encodeEmail(email) {
        if (!email) return '';
        // Replace all Firebase-invalid characters with underscores
        return email.includes('@') ? email.replace(/[.#$[\]/]/g, '_') : email;
    }

    // Decode email from Firebase path
    decodeEmail(encodedEmail) {
        if (!encodedEmail) return '';
        // If it doesn't contain underscores, it's probably not encoded
        if (!encodedEmail.includes('_')) return encodedEmail;
        // Replace underscores with dots for email format
        return encodedEmail.replace(/_/g, '.');
    }

    // Validate Firebase config
    validateFirebaseConfig(config) {
        const requiredFields = ['apiKey', 'authDomain', 'projectId', 'databaseURL'];
        const missingFields = requiredFields.filter(field => !config[field]);
        
        if (missingFields.length > 0) {
            console.error('Missing required Firebase config fields:', missingFields);
            return false;
        }
        
        return true;
    }

    async initializeFirebase() {
        let customToken = null;
        let retryCount = 0;
        const MAX_RETRIES = 3;
        const RETRY_DELAY = 1000; // 1 second

        const cleanup = async () => {
            if (this.firebaseEvents) {
                await this.firebaseEvents.cleanup();
                this.firebaseEvents = null;
            }
            this.firebaseConnected = false;
            await this.updateConnectionState();
        };

        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

        while (retryCount < MAX_RETRIES) {
            try {
                // Cleanup before retry
                await cleanup();

                // Validate Firebase config
                if (!this.validateFirebaseConfig(this.firebaseConfig)) {
                    throw new Error('Invalid Firebase configuration');
                }

                // Import Firebase services
                const { auth, db: database } = await import('../config/firebase');
                this.auth = auth;
                this.database = database;

                // Get and validate custom token
                customToken = await this.getFirebaseToken();
                if (!customToken) {
                    throw new Error('Failed to get Firebase custom token');
                }

                // Sign in and get user
                await signInWithCustomToken(this.auth, customToken);
                const user = this.auth.currentUser;
                if (!user) {
                    throw new Error('No user after Firebase auth');
                }

                // Initialize FirebaseEvents with proper error handling
                const userEmail = this.getUserEmail();
                if (!userEmail) {
                    throw new Error('No user email available');
                }

                this.firebaseEvents = new FirebaseEvents(this.database, userEmail);
                
                // Emit connect event
                await this.firebaseEvents.emit('connect', {
                    auth: {
                        uid: user.uid,
                        email: userEmail,
                        name: user.displayName,
                        picture: user.photoURL
                    },
                    timestamp: new Date().toISOString(),
                    connection_type: 'firebase'
                });

                // Update state and setup listeners
                this.firebaseConnected = true;
                this.initialized = true;
                await this.updateConnectionState();
                await this.setupFirebaseListeners();

                return; // Success - exit the retry loop

            } catch (error) {
                console.error(`Firebase initialization attempt ${retryCount + 1} failed:`, error);
                retryCount++;

                if (retryCount < MAX_RETRIES) {
                    console.log(`Retrying in ${RETRY_DELAY}ms...`);
                    await delay(RETRY_DELAY);
                } else {
                    console.error('Max retries reached, Firebase initialization failed');
                    await cleanup();
                    throw error;
                }
            }
        }
    }

    // Update connection state based on available connections
    async updateConnectionState() {
        const newState = {
            initialized: this.initialized,
            firebaseConnected: this.firebaseConnected,
            socketConnected: this.socketConnected,
            hasFirebaseEvents: !!this.firebaseEvents,
            hasSocket: !!this.socket,
            status: (this.firebaseConnected || this.socketConnected) ? 'connected' : 'disconnected'
        };
        
        console.log('Updating connection state:', {
            ...newState,
            joinedRooms: Array.from(this.joinedRooms),
            firebaseListeners: Array.from(this.firebaseListeners.keys())
        });

        // Update presence if Firebase is connected
        if (this.firebaseConnected) {
            try {
                const userId = this.getUserEmail();
                if (userId) {
                    await this.updatePresence(userId, 'online');
                }
            } catch (error) {
                console.warn('Failed to update presence:', error);
            }
        }

        // Always emit state change, even if nothing changed
        this.onStateChange?.({
            firebaseConnected: this.firebaseConnected,
            socketConnected: this.socketConnected,
            status: newState.status
        });
    }

    // Set socket ID and update Firebase
    async setSocketId(id) {
        this.socketId = id;
        if (this.database) {
            try {
                const userEmail = this.getUserEmail();
                if (userEmail) {
                    const encodedEmail = this.encodeEmail(userEmail);
                    const connectionRef = ref(this.database, `connections/${encodedEmail}`);
                    await set(connectionRef, {
                        socketId: id,
                        lastUpdated: Date.now()
                    });
                }
            } catch (error) {
                console.error('Error updating socket ID in Firebase:', error);
                this.onError?.(error);
            }
        }
    }

    // Update user presence in Firebase
    async updatePresence(userId, status = 'online') {
        if (!this.database || !userId) return;

        try {
            const encodedUserId = this.encodeEmail(userId);
            const userStatusRef = ref(this.database, `status/${encodedUserId}`);
            await set(userStatusRef, {
                state: status,
                last_changed: Date.now(),
                socketId: this.socketId
            });

            if (status === 'online') {
                await onDisconnect(userStatusRef).set({
                    state: 'offline',
                    last_changed: Date.now(),
                });
            }
        } catch (err) {
            console.error('Error updating presence:', err);
            this.onError?.(err);
            throw err; // Re-throw to be handled by caller
        }
    }

    // Handle message from any source
    handleMessage(message) {
        try {
            let normalizedMessage;
            // Special handling for email review requests
            if (message.type === 'email_review_request' || 
                message.event_name === 'email_review_request' || 
                (message.content && message.content.type === 'email_review_request')) {
                
                const emailData = {
                    id: message.emailData?.id || message.id || `email_${Date.now()}`,
                    subject: message.emailData?.subject || message.subject || message.content?.subject,
                    body: message.emailData?.body || message.body || message.content?.body || 
                          (typeof message.content === 'object' ? message.content.content : message.content),
                    recipient_email: message.emailData?.recipient_email || message.recipient_email || message.content?.recipient_email,
                    signature: message.emailData?.signature || message.signature || message.content?.signature || '',
                    is_html: message.emailData?.is_html || message.is_html || message.content?.is_html || false
                };

                normalizedMessage = {
                    id: emailData.id,
                    content: message.content,
                    emailData,
                    source: message.source || 'event',
                    timestamp: message.timestamp?.sv ? Date.now() : (message.timestamp || Date.now()),
                    sortTime: message.sortTime || Date.now(),
                    sender: message.sender || this.getUserEmail(),
                    room: message.room,
                    type: 'email_review_request',
                    event_name: 'email_review_request',
                    agent_id: message.agent_id,
                    campaign_id: message.campaign_id,
                    recipient_id: message.recipient_id,
                    delivered_to_socketio: message.delivered_to_socketio
                };
            } else {
                // Regular message normalization
                normalizedMessage = {
                    id: message.id || Date.now().toString(),
                    content: message.content || message,
                    source: message.source || (message.event_name ? 'event' : 'room'),
                    timestamp: message.timestamp?.sv ? Date.now() : (message.timestamp || Date.now()),
                    sortTime: message.sortTime || Date.now(),
                    sender: message.sender || this.getUserEmail(),
                    room: message.room,
                    type: message.type || 'message',
                    event_name: message.event_name,
                    agent_id: message.agent_id,
                    campaign_id: message.campaign_id,
                    recipient_id: message.recipient_id,
                    delivered_to_socketio: message.delivered_to_socketio
                };
            }

            // Process message immediately
            console.log('Handling message:', normalizedMessage);
            this.onMessage?.(normalizedMessage);
            this.messageHandlers.forEach(handler => handler(normalizedMessage));

            // Store processed message ID to prevent duplicates
            if (normalizedMessage.id) {
                this.processedEvents.add(normalizedMessage.id);
            }
        } catch (err) {
            console.error('Error handling message:', err);
            this.onError?.(err);
        }
    }

    // Enhanced sendMessage to use FirebaseEvents
    async sendMessage(message, room = null) {
        if (!message) return false;

        const normalizedMessage = {
            content: message,
            timestamp: { '.sv': 'timestamp' },
            sortTime: Date.now(),
            sender: this.getUserEmail(),
            room,
            type: 'message'
        };

        try {
            let success = false;

            // Try Firebase Events first
            if (this.firebaseEvents) {
                try {
                    success = await this.firebaseEvents.emit('message', normalizedMessage, room);
                    console.log('Message sent via Firebase Events:', success);
                } catch (firebaseError) {
                    console.warn('Firebase Events send failed:', firebaseError);
                }
            }

            // If Firebase failed or not available, try socket
            if (!success && this.socketConnected) {
                this.socket.emit('message', { room, message: normalizedMessage });
                success = true;
                console.log('Message sent via Socket');
            }

            // If both failed, try legacy Firebase
            if (!success && this.firebaseConnected) {
                const path = room ? `rooms/${room}/messages` : 'messages';
                const messageRef = ref(this.database, path).push();
                await set(messageRef, {
                    ...normalizedMessage,
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
                success = true;
                console.log('Message sent via legacy Firebase');
            }

            // Queue message if all attempts failed with overflow protection
            if (!success) {
                if (this.pendingMessages.length >= this.MAX_PENDING_MESSAGES) {
                    console.warn('Message queue full, dropping oldest message');
                    this.pendingMessages.shift(); // Remove oldest
                }
                this.pendingMessages.push({ message: normalizedMessage, room });
                console.log('Message queued for later delivery');
            }

            return success;
        } catch (err) {
            console.error('Error sending message:', err);
            this.onError?.(err);
            return false;
        }
    }

    // Try to send pending messages when connection is established
    async processPendingMessages() {
        if (!this.pendingMessages.length) return;

        console.log('Processing pending messages:', this.pendingMessages.length);
        const messages = [...this.pendingMessages];
        this.pendingMessages = [];

        for (const { message, room } of messages) {
            try {
                await this.sendMessage(message, room);
            } catch (err) {
                console.error('Error sending pending message:', err);
                this.onError?.(err);
            }
        }
    }

    // Join a room with support for both Socket and Firebase
    async joinRoom(room) {
        if (!room) {
            throw new Error('Room is required');
        }

        // Get user email for security check
        const userEmail = await this.getUserEmail();
        if (!userEmail) {
            throw new Error('User email is required to join room');
        }

        // Security check: can only join room matching user's email
        const decodedRoom = this.decodeEmail(room);
        if (decodedRoom !== userEmail) {
            throw new Error('Unauthorized room');
        }

        // Encode room if it's an email
        const encodedRoom = room.includes('@') ? this.encodeEmail(room) : room;
        console.log(`Joining room: ${encodedRoom}`);

        if (this.joinedRooms.has(encodedRoom)) {
            console.log(`Already joined room: ${encodedRoom}`);
            return true;
        }

        let success = false;

        // Join Firebase room and setup listeners
        if (this.firebaseEvents && this.database && this.firebaseConnected) {
            try {
                console.log('Joining Firebase room:', {
                    room: encodedRoom,
                    firebaseConnected: this.firebaseConnected,
                    hasFirebaseEvents: !!this.firebaseEvents,
                    hasDatabase: !!this.database
                });
                // Join room through FirebaseEvents
                success = await this.firebaseEvents.joinRoom(encodedRoom);
                if (success) {
                    await this.setupFirebaseListeners(encodedRoom, userEmail);
                }
            } catch (error) {
                console.warn(`Failed to join Firebase room: ${encodedRoom}`, error);
            }
        } else {
            console.warn('Cannot join Firebase room - not connected:', {
                hasFirebaseEvents: !!this.firebaseEvents,
                hasDatabase: !!this.database,
                firebaseConnected: this.firebaseConnected
            });
        }

        // Join Socket.IO room
        if (this.socket?.connected) {
            try {
                await new Promise((resolve, reject) => {
                    this.socket.emit('join', { room: encodedRoom }, (response) => {
                        if (response?.error) {
                            reject(new Error(response.error));
                        } else {
                            resolve(response);
                        }
                    });
                });
                success = true;
                console.log(`Successfully joined Socket.IO room: ${encodedRoom}`);
            } catch (error) {
                console.warn(`Failed to join Socket.IO room: ${encodedRoom}`, error);
                if (!success) {
                    throw error; // Only throw if Firebase also failed
                }
            }
        }

        if (success) {
            this.joinedRooms.add(encodedRoom);
            return true;
        }

        throw new Error(`Failed to join room: ${encodedRoom}`);
    }

    // Update campaign status with state tracking
    async updateCampaignStatus(campaignId, status, additionalData = {}) {
        if (!this.firebaseConnected) {
            console.error('Firebase not connected');
            throw new Error('Firebase connection required to update campaign');
        }

        try {
            const userEmail = this.getUserEmail();
            if (!userEmail) throw new Error('No user email available');

            // Prevent duplicate campaign starts
            if (status === 'started' && this.activeCampaigns.has(campaignId)) {
                console.warn(`Campaign ${campaignId} is already active`);
                return false;
            }

            const encodedEmail = this.encodeEmail(userEmail);
            const campaignRef = ref(this.database, `campaigns/${encodedEmail}/${campaignId}`);
            
            // Update Firebase
            await set(campaignRef, {
                ...additionalData,
                status,
                lastUpdated: Date.now(),
                updatedBy: userEmail
            });

            // Update local state
            if (status === 'started') {
                this.activeCampaigns.set(campaignId, {
                    startTime: Date.now(),
                    userEmail
                });
            } else if (status === 'completed' || status === 'stopped') {
                this.activeCampaigns.delete(campaignId);
            }

            return true;
        } catch (err) {
            console.error('Error updating campaign:', err);
            this.onError?.(err);
            throw err;
        }
    }

    // Get campaign status
    async getCampaignStatus(campaignId) {
        if (!this.firebaseConnected) {
            console.error('Firebase not connected');
            throw new Error('Firebase connection required to get campaign status');
        }

        try {
            const userEmail = this.getUserEmail();
            if (!userEmail) throw new Error('No user email available');

            const encodedEmail = this.encodeEmail(userEmail);
            const campaignRef = ref(this.database, `campaigns/${encodedEmail}/${campaignId}`);
            
            return new Promise((resolve) => {
                onValue(campaignRef, (snapshot) => {
                    resolve(snapshot.val());
                }, { onlyOnce: true });
            });
        } catch (err) {
            console.error('Error getting campaign status:', err);
            this.onError?.(err);
            throw err;
        }
    }

    // Setup Firebase listeners for real-time updates
    async setupFirebaseListeners(room = null, userEmail = null) {
        if (!this.database || !this.firebaseConnected) {
            console.warn('Cannot setup Firebase listeners - not connected:', {
                hasDatabase: !!this.database,
                firebaseConnected: this.firebaseConnected
            });
            return;
        }

        console.log('Setting up Firebase listeners:', {
            room,
            userEmail,
            firebaseConnected: this.firebaseConnected,
            hasDatabase: !!this.database,
            existingListeners: Array.from(this.firebaseListeners.keys())
        });

        try {
            if (!userEmail) {
                userEmail = await this.getUserEmail();
                if (!userEmail) return;
            }

            const encodedEmail = this.encodeEmail(userEmail);
            
            // Connection status with reconnection logic
            const connectedRef = ref(this.database, '.info/connected');
            onValue(connectedRef, async (snap) => {
                const connected = snap.val() === true;
                console.log('Firebase connection state changed:', connected);
                
                if (!connected) {
                    this.firebaseConnected = false;
                    await this.updateConnectionState();
                    
                    // Attempt to reconnect if not already attempting
                    if (!this.recoveryTimeout && this.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
                        this.reconnectAttempts++;
                        console.log(`Attempting Firebase reconnection (${this.reconnectAttempts}/${this.MAX_RECONNECT_ATTEMPTS})`);
                        
                        this.recoveryTimeout = setTimeout(async () => {
                            try {
                                await this.initializeFirebase();
                                this.reconnectAttempts = 0; // Reset attempts on successful connection
                            } catch (error) {
                                console.error('Firebase reconnection attempt failed:', error);
                            } finally {
                                this.recoveryTimeout = null;
                            }
                        }, this.RECONNECT_TIMEOUT);
                    }
                } else {
                    this.firebaseConnected = true;
                    this.reconnectAttempts = 0; // Reset attempts on successful connection
                    await this.updateConnectionState();

                    // Set online status
                    const connectionRef = ref(this.database, `connections/${encodedEmail}`);
                    const connectionData = {
                        online: true,
                        lastSeen: Date.now(),
                        userId: this.getUserId()
                    };

                    set(connectionRef, connectionData);
                    onDisconnect(connectionRef).update({
                        online: false,
                        lastSeen: Date.now()
                    });

                    // Setup campaign status listener
                    const campaignsRef = ref(this.database, `campaigns/${encodedEmail}`);
                    onValue(campaignsRef, (snapshot) => {
                        const campaigns = snapshot.val();
                        if (campaigns) {
                            Object.entries(campaigns).forEach(([id, data]) => {
                                this.onMessage?.({
                                    type: 'campaign_update',
                                    campaignId: id,
                                    data
                                });
                            });
                        }
                    });

                    // Setup agent status listener
                    const agentsRef = ref(this.database, `agents/${encodedEmail}`);
                    onValue(agentsRef, (snapshot) => {
                        const agents = snapshot.val();
                        if (agents) {
                            Object.entries(agents).forEach(([id, data]) => {
                                this.onMessage?.({
                                    type: 'agent_update',
                                    agentId: id,
                                    data
                                });
                            });
                        }
                    });

                    // Setup room listener if provided
                    if (room) {
                        const roomRef = ref(this.database, `rooms/${room}/messages`);
                        onValue(roomRef, (snapshot) => {
                            const messages = snapshot.val();
                            if (messages) {
                                Object.entries(messages).forEach(([id, data]) => {
                                    this.onMessage?.({
                                        type: 'message',
                                        id,
                                        data
                                    });
                                });
                            }
                        });
                    }

                    // Setup group chat message listener
                    if (room) {
                        const encodedRoom = this.encodeEmail(room);
                        const groupChatRef = ref(this.database, `events/group_chat_message/${encodedRoom}`);
                        console.log('Setting up group chat listener for room:', {
                            room,
                            encodedRoom,
                            path: `events/group_chat_message/${encodedRoom}`,
                            isEmail: room.includes('@')
                        });
                        let lastMessageId = null;

                        // Listen for new messages
                        onValue(query(groupChatRef, limitToLast(1)), (snapshot) => {
                            if (!snapshot.exists()) return;
                            
                            const messages = snapshot.val();
                            if (!messages) return;
                            
                            // Get the latest message
                            const [id, data] = Object.entries(messages)[0];
                            
                            // Skip if we've already processed this message
                            if (id === lastMessageId) {
                                console.log('Skipping duplicate message:', {
                                    id,
                                    lastMessageId
                                });
                                return;
                            }
                            
                            // Process if it's a new message
                            if (id !== lastMessageId) {
                                console.log('Emitting group chat message:', data);
                                try {
                                    this.onMessage?.({ 
                                        type: 'group_chat_message',
                                        ...data
                                    });
                                    lastMessageId = id; // Only update lastMessageId after successful emit
                                    console.log('Successfully emitted group chat message');
                                } catch (error) {
                                    console.error('Error emitting group chat message:', error);
                                }
                            } else {
                                console.log('Skipping duplicate message:', {
                                    id,
                                    lastMessageId
                                });
                            }
                        });
                    }

                    // Setup email review request listener
                    // Check if we're in development mode to prepend 'dev/' to the path
                    const isDevelopment = process.env.NODE_ENV === 'development';
                    const reviewPath = isDevelopment ? `dev/events/email_review_request/${encodedEmail}` : `events/email_review_request/${encodedEmail}`;
                    const emailReviewRef = ref(this.database, reviewPath);
                    console.log('Setting up email review request listener:', {
                        path: reviewPath,
                        userEmail,
                        isDevelopment
                    });

                    onValue(emailReviewRef, (snapshot) => {
                        console.log('Email review request snapshot received:', {
                            exists: snapshot.exists(),
                            val: snapshot.val()
                        });
                        const reviewData = snapshot.val();
                        if (reviewData) {
                            Object.entries(reviewData).forEach(([id, data]) => {
                                if (!this.processedEvents.has(`email_review:${id}`)) {
                                    console.log('Processing email review request:', {
                                        id,
                                        data
                                    });
                                    this.onMessage?.({
                                        type: 'email_review_request',
                                        id,
                                        ...data
                                    });
                                    this.processedEvents.add(`email_review:${id}`);
                                }
                            });
                        }
                    });
                }
            }, (error) => {
                console.error('Firebase connection error:', error);
                this.firebaseConnected = false;
                this.updateConnectionState();
                this.onError?.({ 
                  message: 'Firebase connection error', 
                  error,
                  source: 'firebase'  // Mark as Firebase error
                });
            });

        } catch (err) {
            console.error('Error setting up Firebase listeners:', err);
            this.onError?.(err);
        }
    }

    // Handle critical errors with recovery attempt
    handleCriticalError(error) {
        console.error('Critical error:', error);
        this.onStateChange?.('error');
        this.onError?.(error);
        
        // Clean up existing connections
        this.cleanup();
        
        // Clear any existing recovery timeout
        if (this.recoveryTimeout) {
            clearTimeout(this.recoveryTimeout);
        }
        
        // Attempt one final reconnection
        this.recoveryTimeout = setTimeout(() => {
            console.log('Attempting recovery after critical error');
            Promise.all([
                this.firebaseConfig ? this.initializeFirebase() : Promise.resolve(),
                this.initializeSocket()
            ]).catch(err => {
                console.error('Recovery failed:', err);
                this.onStateChange?.('error');
                this.onError?.(new Error('Unrecoverable connection error'));
            });
        }, 5000);
    }

    // Enhanced socket initialization with reconnection limits
    async initializeSocket() {
        if (!this.token || !this.socketUrl) {
            throw new Error('Token and socketUrl are required for socket initialization');
        }

        return new Promise((resolve, reject) => {
            try {
                console.log('Initializing socket connection...');

                const socketOptions = {
                    auth: {
                        token: this.token
                    },
                    transportOptions: {
                        polling: {
                            extraHeaders: {
                                Authorization: `Bearer ${this.token}`
                            }
                        }
                    },
                    transports: ['websocket', 'polling'],
                    reconnection: true,
                    reconnectionAttempts: this.MAX_RECONNECT_ATTEMPTS,
                    reconnectionDelay: 1000,
                    reconnectionDelayMax: 5000,
                    randomizationFactor: 0.5,
                    timeout: this.RECONNECT_TIMEOUT
                };

                // Clean up existing socket if any
                if (this.socket) {
                    console.log('Cleaning up existing socket connection');
                    this.socket.removeAllListeners();
                    this.socket.disconnect();
                    this.socket = null;
                    this.socketConnected = false;
                    this.updateConnectionState();
                }

                console.log('Creating new socket connection to:', this.socketUrl);
                this.socket = io(this.socketUrl, socketOptions);

                // Set up connection handlers
                this.socket.on('connect', () => {
                    console.log('Socket connected successfully');
                    this.socketConnected = true;
                    this.reconnectAttempts = 0; // Reset reconnect attempts
                    this.updateConnectionState();
                    
                    // Rejoin rooms after reconnection
                    if (this.joinedRooms.size > 0) {
                        console.log('Rejoining rooms:', Array.from(this.joinedRooms));
                        this.joinedRooms.forEach(room => this.joinRoom(room));
                    }
                    
                    this.processPendingMessages();
                    resolve(true);
                });

                this.socket.on('connect_error', (error) => {
                    console.warn('Socket.IO connection error:', error);
                    this.socketConnected = false;
                    this.updateConnectionState();
                    this.onError?.({ 
                      message: 'Socket.IO connection error', 
                      error,
                      source: 'socketio'  // Mark as Socket.IO error
                    });
                    
                    this.reconnectAttempts++;
                    if (this.reconnectAttempts >= this.MAX_RECONNECT_ATTEMPTS) {
                        console.error('Max reconnection attempts reached');
                        this.onStateChange?.('error');
                        this.socket.disconnect();
                        reject(new Error('Max reconnection attempts reached'));
                    }
                });

                this.socket.on('disconnect', (reason) => {
                    console.log('Socket disconnected:', reason);
                    this.socketConnected = false;
                    this.updateConnectionState();
                    
                    if (reason === 'io server disconnect' || reason === 'io client disconnect') {
                        this.socket.disconnect();
                        reject(new Error(`Socket disconnected: ${reason}`));
                    }
                });

                this.socket.on('error', (error) => {
                    console.error('Socket.IO error:', error);
                    this.onStateChange?.('error');
                    this.onError?.({ 
                      message: 'Socket.IO error', 
                      error,
                      source: 'socketio'  // Mark as Socket.IO error
                    });
                    
                    if (error.type === 'TransportError' || error.type === 'AuthError') {
                        reject(error);
                    }
                });

                // Handle authentication response
                this.socket.on('authenticated', (response) => {
                    console.log('Socket authentication response:', response);
                    if (response.status === 'success') {
                        this.onStateChange?.('connected');
                    } else {
                        reject(new Error('Socket authentication failed'));
                    }
                });

                // Set timeout for initial connection
                setTimeout(() => {
                    if (!this.socketConnected) {
                        reject(new Error('Socket connection timeout'));
                    }
                }, this.RECONNECT_TIMEOUT);

                // Attempt initial connection
                if (!this.socket.connected) {
                    console.log('Attempting initial socket connection...');
                    this.socket.connect();
                }

            } catch (error) {
                console.error('Failed to initialize socket:', error);
                reject(error);
            }
        });
    }

    // Get user email from token
    getUserEmail() {
        try {
            const payload = JSON.parse(atob(this.token.split('.')[1]));
            return payload.email || payload.sub;
        } catch (err) {
            console.error('Error extracting user email from token:', err);
            return null;
        }
    }

    // Get Firebase custom token from backend
    async getFirebaseToken() {
        try {
            console.log('Fetching Firebase token...');
            const response = await fetch(`${this.socketUrl}/auth/firebase-token`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                let errorMessage;
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    const errorData = await response.json();
                    errorMessage = errorData.error || `HTTP error! status: ${response.status}`;
                } else {
                    const text = await response.text();
                    errorMessage = `Server error (${response.status}): ${text}`;
                }
                console.error('Firebase token fetch failed:', errorMessage);
                throw new Error(errorMessage);
            }

            const data = await response.json();
            if (!data.firebaseToken) {
                console.error('No Firebase token in response:', data);
                throw new Error('No Firebase token in response');
            }

            console.log('Firebase token fetched successfully');
            return data.firebaseToken;

        } catch (error) {
            console.error('Failed to get Firebase token:', error);
            // Add more context to the error
            const enhancedError = new Error(`Firebase token fetch failed: ${error.message}`);
            enhancedError.originalError = error;
            enhancedError.context = {
                socketUrl: this.socketUrl,
                hasToken: !!this.token,
                userEmail: this.getUserEmail()
            };
            throw enhancedError;
        }
    }

    // Add message handler
    addMessageHandler(handler) {
        this.messageHandlers.add(handler);
        return () => this.messageHandlers.delete(handler);
    }

    // Leave a room
    leaveRoom(room) {
        if (!room || !this.joinedRooms.has(room)) return;

        // Cleanup Firebase
        if (this.database) {
            const roomRef = ref(this.database, `rooms/${room}/members/${this.getUserId()}`);
            off(roomRef);
            set(roomRef, null);
        }

        this.joinedRooms.delete(room);
    }

    // Cleanup
    async cleanup() {
        try {
            console.log('Cleaning up UnifiedMessaging...');
            
            // Clean up event handlers
            Object.keys(this.eventHandlers).forEach(eventName => {
                this.off(eventName); // This will properly clean up both Firebase and Socket handlers
            });
            this.eventHandlers = new Map();
            this.oneTimeHandlers = new Map();

            // Cleanup Socket
            if (this.socket) {
                this.socket.removeAllListeners();
                this.socket.disconnect();
                this.socket = null;
            }

            // Cleanup Firebase
            if (this.firebaseEvents) {
                await this.firebaseEvents.cleanup();
                this.firebaseEvents = null;
            }

            // Clear all Firebase listeners
            if (this.firebaseListeners.size > 0) {
                this.firebaseListeners.forEach((listener, path) => {
                    off(ref(this.database, path));
                });
                this.firebaseListeners.clear();
            }

            // Reset all state
            this.room = null;
            this.pendingMessages = [];
            this.reconnectAttempts = 0;
            this.initialized = false;
            this.firebaseConnected = false;
            this.socketConnected = false;
            this.messageHandlers.clear();
            this.joinedRooms.clear();
            this.activeCampaigns.clear();
        } catch (error) {
            console.error('Error during UnifiedMessaging cleanup:', error);
            throw error; // Propagate error for proper handling
        }
    }

    getUserId() {
        try {
            const token = this.token;
            if (!token) return null;

            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload).sub;
        } catch (err) {
            console.error('Error getting user ID:', err);
            return null;
        }
    }

    async startCampaign(campaignData) {
        if (!this.firebaseConnected && !this.firebaseEvents) {
            console.error('Firebase not connected');
            throw new Error('Firebase connection required to start campaign');
        }

        if (!this.socket && !this.firebaseEvents) {
            console.error('No connection available');
            throw new Error('Socket.IO or Firebase connection required to start campaign');
        }

        try {
            const userEmail = await this.getUserEmail();
            if (!userEmail) throw new Error('No user email available');
            
            const encodedEmail = this.encodeEmail(userEmail);
            
            // Check if we're already tracking this campaign locally
            const campaignId = `${encodedEmail}_${Date.now()}`;
            if (this.activeCampaigns.has(campaignId)) {
                console.warn('Campaign already being started:', campaignId);
                throw new Error('Campaign start already in progress');
            }

            // Mark campaign as initializing
            this.activeCampaigns.set(campaignId, {
                status: 'initializing',
                startedAt: Date.now()
            });

            // Check Firebase for existing active campaigns
            const campaignsRef = ref(this.database, `campaigns/${encodedEmail}`);
            const existingCampaigns = await new Promise((resolve) => {
                onValue(campaignsRef, (snapshot) => {
                    resolve(snapshot.val());
                }, { onlyOnce: true });
            });

            if (existingCampaigns) {
                // Clean up stale campaigns (older than 1 hour)
                const now = Date.now();
                const ONE_HOUR = 60 * 60 * 1000;
                
                const activeCampaign = Object.entries(existingCampaigns).find(([key, campaign]) => {
                    // Check if campaign is active and not stale
                    const isActive = campaign.status === 'started' || campaign.status === 'initializing';
                    const startTime = campaign.startedAt || campaign.timestamp || 0;
                    const isStale = (now - startTime) > ONE_HOUR;
                    
                    // Check if this is our current campaign
                    const isSameCampaign = key === campaignId;
                    
                    if (isActive && isStale) {
                        console.log('Found stale campaign, will be cleaned up:', key);
                        return false;
                    }
                    
                    // Only consider it active if it's not stale and not our current campaign
                    return isActive && !isStale && !isSameCampaign;
                });
                
                if (activeCampaign) {
                    this.activeCampaigns.delete(campaignId);
                    console.warn('Active campaign already exists:', activeCampaign[0]);
                    throw new Error('A campaign is already running for this user');
                }

                // Clean up stale campaigns in Firebase
                const updates = {};
                Object.entries(existingCampaigns).forEach(([key, campaign]) => {
                    const startTime = campaign.startedAt || campaign.timestamp || 0;
                    const isStale = (now - startTime) > ONE_HOUR;
                    const isSameCampaign = key === campaignId;
                    
                    // Clean up if stale or if it's a failed attempt of our current campaign
                    if (isStale || (isSameCampaign && campaign.status === 'initializing')) {
                        updates[`campaigns/${encodedEmail}/${key}`] = null;
                    }
                });

                if (Object.keys(updates).length > 0) {
                    try {
                        await set(ref(this.database), updates);
                        console.log('Cleaned up stale campaigns');
                    } catch (error) {
                        console.warn('Failed to clean up stale campaigns:', error);
                    }
                }
            }
            
            return new Promise((resolve, reject) => {
                const timeout = setTimeout(() => {
                    this.activeCampaigns.delete(campaignId);
                    reject(new Error('Campaign start timed out after 60 seconds'));
                }, 60000);

                const handleSuccess = (data) => {
                    clearTimeout(timeout);
                    if (data && (data.success || data.status === 'ready')) {
                        this.activeCampaigns.set(campaignId, {
                            status: 'started',
                            startedAt: Date.now(),
                            data: data
                        });
                        resolve({
                            success: true,
                            campaignId,
                            agents: data.agents
                        });
                    } else {
                        this.activeCampaigns.delete(campaignId);
                        reject(new Error(data.message || 'Campaign start failed'));
                    }
                };

                const handleError = (error) => {
                    clearTimeout(timeout);
                    this.activeCampaigns.delete(campaignId);
                    reject(error);
                };

                // Set up handlers before emitting
                if (this.firebaseEvents) {
                    this.firebaseEvents.on('campaign_started', handleSuccess);
                    this.firebaseEvents.on('campaign_error', handleError);
                }
                if (this.socket) {
                    this.socket.on('campaign_started', handleSuccess);
                    this.socket.on('campaign_error', handleError);
                }

                // Now emit the event
                const campaignStartData = {
                    data: campaignData,
                    timestamp: Date.now(),
                    userEmail,
                    room: encodedEmail,
                    campaignId
                };

                if (this.firebaseEvents) {
                    this.firebaseEvents.emit('start_campaign', campaignStartData).catch(err => {
                        console.error('Error emitting start_campaign via Firebase:', err);
                        // Try socket as fallback
                        if (this.socket) {
                            this.socket.emit('start_campaign', campaignStartData);
                        } else {
                            handleError(err);
                        }
                    });
                } else if (this.socket) {
                    this.socket.emit('start_campaign', campaignStartData);
                } else {
                    handleError(new Error('No connection available'));
                }
            });
        } catch (err) {
            console.error('Error starting campaign:', err);
            this.onError?.(err);
            throw err;
        }
    }

    // Get dashboard data
    async getDashboardData() {
        if (!this.firebaseConnected && !this.firebaseEvents) {
            throw new Error('Firebase connection required');
        }

        try {
            const userEmail = this.getUserEmail();
            if (!userEmail) throw new Error('No user email available');

            // Try FirebaseEvents first
            if (this.firebaseEvents) {
                return new Promise((resolve) => {
                    this.firebaseEvents.on('dashboard_data', resolve, { once: true });
                    this.firebaseEvents.emit('get_dashboard_data');
                });
            }

            // Fallback to direct Firebase reference
            const encodedEmail = this.encodeEmail(userEmail);
            const dashboardRef = ref(this.database, `dashboard/${encodedEmail}`);
            
            return new Promise((resolve) => {
                onValue(dashboardRef, (snapshot) => {
                    resolve(snapshot.val());
                }, { onlyOnce: true });
            });
        } catch (err) {
            console.error('Error getting dashboard data:', err);
            this.onError?.(err);
            throw err;
        }
    }

    // Setup campaign listeners
    setupCampaignListeners(userEmail) {
        if (!this.database && !this.firebaseEvents) return;
        if (!userEmail) return;

        try {
            // Setup listeners via FirebaseEvents
            if (this.firebaseEvents) {
                this.firebaseEvents.on('campaign_update', (data) => {
                    this.onMessage?.({
                        type: 'campaign_update',
                        data
                    });
                });

                this.firebaseEvents.on('agents_update', (data) => {
                    this.onMessage?.({
                        type: 'agents_update',
                        data
                    });
                });

                this.firebaseEvents.on('dashboard_update', (data) => {
                    this.onMessage?.({
                        type: 'dashboard_update',
                        data
                    });
                });
            }

            // Also setup direct Firebase listeners as backup
            const encodedEmail = this.encodeEmail(userEmail);
            
            // Campaign status listener
            const campaignRef = ref(this.database, `campaigns/${encodedEmail}`);
            onValue(campaignRef, (snapshot) => {
                const campaignData = snapshot.val();
                if (campaignData) {
                    this.onMessage?.({
                        type: 'campaign_update',
                        data: campaignData
                    });
                }
            });

            // Agents status listener
            const agentsRef = ref(this.database, `agents/${encodedEmail}`);
            onValue(agentsRef, (snapshot) => {
                const agentsData = snapshot.val();
                if (agentsData) {
                    this.onMessage?.({
                        type: 'agents_update',
                        data: agentsData
                    });
                }
            });

            // Dashboard data listener
            const dashboardRef = ref(this.database, `dashboard/${encodedEmail}`);
            onValue(dashboardRef, (snapshot) => {
                const dashboardData = snapshot.val();
                if (dashboardData) {
                    this.onMessage?.({
                        type: 'dashboard_update',
                        data: dashboardData
                    });
                }
            });
        } catch (err) {
            console.error('Error setting up campaign listeners:', err);
            this.onError?.(err);
        }
    }

    // Register a handler for an event
    handleEvent(eventName, handler, once = false) {
        console.log(`Setting up ${once ? 'one-time' : 'persistent'} handler for event: ${eventName}`);
        
        if (typeof handler !== 'function') {
            console.error(`Invalid handler provided for ${eventName}. Must be a function.`);
            return;
        }

        const handlerSet = once ? this.oneTimeHandlers : this.eventHandlers;
        if (!handlerSet.has(eventName)) {
            handlerSet.set(eventName, new Set());
        }
        handlerSet.get(eventName).add(handler);

        // Set up Firebase listener if needed
        if (this.firebaseEvents) {
            this.firebaseEvents.on(eventName, handler, { once });
        }
    }

    // Create one-time event handler
    once(eventName, handler) {
        return this.handleEvent(eventName, handler, true);
    }

    // Create persistent event handler
    on(eventName, handler) {
        return this.handleEvent(eventName, handler, false);
    }

    // Remove event handler
    off(eventName, handler = null) {
        // If no specific handler provided, remove all handlers for this event
        if (!handler) {
            this.eventHandlers.delete(eventName);
            this.oneTimeHandlers.delete(eventName);
            if (this.firebaseEvents) {
                this.firebaseEvents.off(eventName);
            }
            return;
        }

        // Remove specific handler
        const removeFromSet = (set) => {
            if (set.has(eventName)) {
                const handlers = set.get(eventName);
                handlers.delete(handler);
                if (handlers.size === 0) {
                    set.delete(eventName);
                }
            }
        };

        removeFromSet(this.eventHandlers);
        removeFromSet(this.oneTimeHandlers);

        // Remove from Firebase if needed
        if (this.firebaseEvents) {
            this.firebaseEvents.off(eventName, handler);
        }
    }

    // Emit an event to any connected clients
    async emit(eventName, data, room = null, namespace = '/') {
        let success = false;
        let error = null;

        // Try Firebase first
        if (this.firebaseEvents && this.firebaseConnected) {
            try {
                success = await this.firebaseEvents.emit(eventName, {
                    ...data,
                    room,
                    namespace,
                    timestamp: Date.now()
                });
                if (success) {
                    console.log(`Successfully emitted ${eventName} through Firebase`);
                }
            } catch (err) {
                error = err;
                console.warn(`Failed to emit ${eventName} through Firebase:`, err);
            }
        }

        // Try Socket.IO as backup if Firebase failed
        if (!success && this.socket?.connected) {
            try {
                const socketResult = await new Promise((resolve) => {
                    this.socket.emit(eventName, {
                        ...data,
                        room,
                        namespace,
                        timestamp: Date.now()
                    }, (response) => {
                        resolve(response?.success);
                    });
                    // Add timeout for socket response
                    setTimeout(() => resolve(false), 5000);
                });

                if (socketResult) {
                    console.log(`Successfully emitted ${eventName} through socket (backup)`);
                    success = true;
                }
            } catch (err) {
                if (!success) { // Only track socket error if Firebase also failed
                    error = err;
                }
                console.warn(`Failed to emit ${eventName} through socket:`, err);
            }
        }

        // Handle complete failure
        if (!success && error) {
            console.error(`Failed to emit ${eventName} through all channels:`, error);
            this.onError?.(error);
            throw error;
        }

        return success;
    }

    // Handle incoming events from any source (Firebase or Socket)
    handleEventResponse(eventName, data) {
        const handlers = this.eventHandlers.get(eventName) || new Set();
        const oneTimeHandlers = this.oneTimeHandlers.get(eventName) || new Set();

        if (handlers.size === 0 && oneTimeHandlers.size === 0) {
            return; // No handlers to execute
        }

        try {
            const normalizedData = this.sanitizeData({
                ...data,
                timestamp: data.timestamp || Date.now(),
                type: eventName
            });

            // Execute regular handlers
            handlers.forEach(handler => {
                if (typeof handler === 'function') {
                    try {
                        handler(normalizedData);
                    } catch (err) {
                        console.error(`Error in event handler for ${eventName}:`, err);
                        this.onError?.(err);
                    }
                } else {
                    console.warn(`Invalid handler for ${eventName}:`, handler);
                }
            });

            // Execute and remove one-time handlers
            if (oneTimeHandlers.size > 0) {
                oneTimeHandlers.forEach(handler => {
                    if (typeof handler === 'function') {
                        try {
                            handler(normalizedData);
                        } catch (err) {
                            console.error(`Error in one-time handler for ${eventName}:`, err);
                            this.onError?.(err);
                        }
                    } else {
                        console.warn(`Invalid one-time handler for ${eventName}:`, handler);
                    }
                });
                this.oneTimeHandlers.delete(eventName);
            }
        } catch (error) {
            console.error(`Error handling ${eventName} event:`, error);
            this.onError?.(error);
        }
    }

    // Sanitize data for Firebase by removing undefined values
    sanitizeData(data) {
        if (!data) return null;
        
        if (Array.isArray(data)) {
            return data.map(item => this.sanitizeData(item));
        }
        
        if (typeof data === 'object') {
            const cleanData = {};
            const removedKeys = [];
            
            for (const [key, value] of Object.entries(data)) {
                if (value !== undefined) {
                    cleanData[key] = this.sanitizeData(value);
                } else {
                    removedKeys.push(key);
                }
            }
            
            if (removedKeys.length > 0) {
                console.debug('Cleaned undefined values from keys:', removedKeys);
            }
            
            return cleanData;
        }
        
        return data;
    }

    async cleanupCampaignState() {
        if (!this.firebaseEvents) return;
        return this.firebaseEvents.cleanupCampaignState();
    }

    async removeEvent(eventName) {
        if (!this.firebaseEvents) return;
        return this.firebaseEvents.removeEvent(eventName);
    }
}

export default UnifiedMessaging;
