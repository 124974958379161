import React, { useState, useEffect, useCallback } from 'react';
import { useWebSocket, CONNECTION_STATES } from '../contexts/WebSocketContext';
import { useApp } from '../contexts/AppContext';
import AgentChat from './AgentChat';
import { PerformanceDashboard } from './PerformanceDashboard';
import { OutreachDialog } from './OutreachDialog';
import { motion, AnimatePresence } from 'framer-motion';
import './Dashboard.css';
import { useAuth } from '../components/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useAgents } from '../hooks/useAgents';
import { authService } from '../services/auth';
import { ProfileMenu } from './ProfileMenu';
import { getDatabase, remove, ref } from 'firebase/database';
import FirebaseEvents from '../services/FirebaseEvents';
import PlaceholderUI from './PlaceholderUI';
import InitializingUI from './InitializingUI';

export function Dashboard({ isInitialized = false, setIsInitialized }) {
  const [showOutreachDialog, setShowOutreachDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [campaignActive, setCampaignActive] = useState(false);
  const [campaignState, setCampaignState] = useState({
    isInitializing: false,
    isStarting: false,
    error: null,
    metrics: {},
    startTime: new Date().toISOString(), // Ensure startTime is always a string
    campaign_id: null,
    status: 'stopped'
  });

  const { emit, on, off, connectionState } = useWebSocket();
  const isConnected = connectionState === CONNECTION_STATES.CONNECTED;
  const { addNotification } = useApp();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { 
    agents, 
    activeAgentsCount,
    setAgents,
    refresh: refreshAgentsList
  } = useAgents();

  // Event handler functions defined before use
  const handleInitError = useCallback((error) => {
    console.error('Initialization error:', error);
    const errorMessage = error.message?.includes("NoneType") 
        ? "Failed to initialize agent communication. Please try again in a few moments."
        : error.message || 'Failed to initialize agents';
        
    setError(errorMessage);
    setIsLoading(false);
    setCampaignState(prev => ({
        ...prev,
        isInitializing: false,
        isStarting: false,
        error: errorMessage,
        status: 'stopped'
    }));
    addNotification({
        type: 'error',
        message: errorMessage,
        duration: 5000
    });
  }, [addNotification]);

  const handleCampaignError = useCallback((error) => {
    // Only handle errors from current session
    if (error.timestamp && typeof error.timestamp === 'number') {
      const sessionStartTime = window.sessionStartTime || Date.now(); // Fallback if not set
      if (error.timestamp < sessionStartTime) {
        return; // Ignore errors from previous sessions
      }
    }
    
    console.debug('Campaign error:', error); // Changed to debug level
    setCampaignState(prev => ({
      ...prev,
      error: error.message || 'Campaign error occurred',
      isStarting: false
    }));
    addNotification({
      type: 'error',
      message: error.message || 'Campaign error occurred'
    });
  }, [addNotification]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        if (!token) {
          navigate('/login', { replace: true });
          return;
        }

        // Validate token
        const isValid = await authService.validateToken(token);
        if (!isValid) {
          console.log('Invalid token, redirecting to login');
          navigate('/login', { replace: true });
          return;
        }

        if (connectionState === CONNECTION_STATES.DISCONNECTED) {
          console.log('Socket disconnected, waiting for reconnection...');
          addNotification({
            type: 'warning',
            message: 'Attempting to reconnect to server...'
          });
        } else if (connectionState === CONNECTION_STATES.ERROR) {
          console.error('Socket connection error');
          addNotification({
            type: 'error',
            message: 'Failed to connect to server. Please refresh the page.'
          });
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        navigate('/login', { replace: true });
      }
    };

    checkAuth();
  }, [navigate, connectionState, addNotification]);

  useEffect(() => {
    if (connectionState === CONNECTION_STATES.CONNECTED && (!agents || agents.length === 0) && !isInitialized) {
      addNotification({
        type: 'info',
        message: 'Click "Start New Campaign" to begin'
      });
    }
  }, [connectionState, agents, isInitialized, addNotification]);

  useEffect(() => {
    if (connectionState === CONNECTION_STATES.DISCONNECTED) {
      addNotification({
        type: 'error',
        message: 'Lost connection to server'
      });
    }
  }, [connectionState, addNotification]);

  useEffect(() => {
    if (connectionState === CONNECTION_STATES.CONNECTED && !agents.length) {
      refreshAgentsList();
    }
  }, [connectionState, agents.length, refreshAgentsList]);

  useEffect(() => {
    if (connectionState === CONNECTION_STATES.DISCONNECTED) {
      console.log('Socket disconnected, waiting for reconnection...');
    } else if (connectionState === CONNECTION_STATES.ERROR) {
      setError('WebSocket connection error');
      addNotification({
        type: 'error',
        message: 'Connection error. Please refresh the page.'
      });
    }
  }, [connectionState, addNotification]);

  useEffect(() => {
    if (connectionState !== CONNECTION_STATES.CONNECTED) return;
    
    console.log('Dashboard: Setting up socket listeners');
    
    const handlers = {
      initialization_error: handleInitError,
      campaign_error: handleCampaignError,
      campaign_started: (data) => {
        console.log('Campaign started event received:', data);
        
        // Handle both direct data and Firebase event data
        const eventData = data.agents ? data : (
          // If no direct agents, try to get from Firebase event
          Object.entries(data)
            .filter(([key, value]) => key.startsWith('-') && value && typeof value === 'object')
            .sort((a, b) => b[0].localeCompare(a[0]))
            .map(entry => entry[1])[0] || data
        );

        // Log the event data for debugging
        console.log('Processing campaign_started event:', {
          eventData,
          hasAgents: Boolean(eventData?.agents),
          campaignId: eventData?.campaign_id
        });
        
        if (eventData && Array.isArray(eventData.agents)) {
          console.log('Setting agents from campaign_started:', eventData.agents);
          
          // Batch state updates for better synchronization
          Promise.resolve().then(() => {
            setIsLoading(false);
            setAgents(eventData.agents);
            setCampaignState(prev => ({
              ...prev,
              isInitializing: false,
              isStarting: false,
              campaign_id: eventData.campaign_id,
              status: 'active',
              startTime: typeof campaignState.startTime === 'number' ? new Date(campaignState.startTime).toISOString() : new Date().toISOString(),
              error: null
            }));
            setCampaignActive(true);
            setIsInitialized?.(true);
            setShowOutreachDialog(false);
            
            // Log state updates
            console.log('Updated campaign state:', {
              agents: eventData.agents,
              campaignId: eventData.campaign_id,
              status: 'active'
            });

            addNotification({
              type: 'success',
              message: 'Campaign is now active'
            });
          });
        } else if (eventData && eventData.error) {
          console.error('Campaign start failed:', eventData);
          handleCampaignError(eventData);
        } else {
          console.log('Ignoring campaign_started event with invalid data:', eventData);
        }
      }
    };

    Object.entries(handlers).forEach(([event, handler]) => {
      on(event, handler);
    });

    return () => {
      Object.entries(handlers).forEach(([event, handler]) => {
        off(event, handler);
      });
    };
  }, [on, off, setAgents, setIsInitialized, handleInitError, handleCampaignError, addNotification, connectionState]);

  useEffect(() => {
    if (agents && Array.isArray(agents)) {
      console.log('Agents updated in Dashboard:', agents);
      console.log('Updating active agents count:', activeAgentsCount);
      
      // Set campaign as active if:
      // 1. We have agents OR
      // 2. We have an active campaign state OR
      // 3. We have a campaign ID
      const shouldBeActive = 
        agents.length > 0 || 
        campaignState.status === 'active' 
        campaignState.campaign_id;

      if (shouldBeActive) {
        console.log('Activating campaign based on state:', {
          agentsPresent: agents.length > 0,
          campaignStatus: campaignState.status,
          campaignId: campaignState.campaign_id
        });
        
        // Use Promise.resolve to batch state updates
        Promise.resolve().then(() => {
          setCampaignActive(true);
          setIsInitialized(true);
        });
      }
    }
  }, [agents, activeAgentsCount, campaignState, campaignState.startTime, setIsInitialized]);

  useEffect(() => {
    // Check token every 4 minutes
    const tokenCheckInterval = setInterval(async () => {
        try {
            const isValid = await authService.checkTokenExpiration();
            if (!isValid) {
                // Token refresh failed, redirect to login
                navigate('/login');
            }
        } catch (error) {
            console.error('Token check failed:', error);
            navigate('/login');
        }
    }, 240000); // 4 minutes

    return () => clearInterval(tokenCheckInterval);
  }, [navigate]);

  useEffect(() => {
    console.log('Dashboard state changed:', {
      isInitialized,
      campaignActive,
      agentsCount: agents?.length,
      showOutreachDialog,
      isLoading,
      campaignState
    });
  }, [isInitialized, campaignActive, agents, showOutreachDialog, isLoading, campaignState]);

  useEffect(() => {
    if (isConnected && !isInitialized) {
      console.log('Dashboard connected, requesting initial data');
      emit('request_dashboard_data');
      setIsInitialized?.(true);
    }
  }, [emit, isConnected, isInitialized, setIsInitialized]);



  useEffect(() => {
    if (user?.email) {
      const db = getDatabase();
      const firebaseEvents = new FirebaseEvents(db, user.email);

      // Clean up any stale campaign state
      firebaseEvents.cleanupCampaignState().catch(error => {
        console.error('Failed to cleanup campaign state:', error);
      });

      // Reset campaign state
      setCampaignState({
        isInitializing: false,
        isStarting: false,
        error: null,
        metrics: {},
        startTime: null,
        campaign_id: null,
        status: 'stopped'
      });
      setCampaignActive(false);
    }
  }, [user?.email]);

  const handleStartOutreach = async (data) => {
    console.log('handleStartOutreach called with data:', data);
    try {
      // Check WebSocket connection first
      if (!isConnected) {
        throw new Error('Server is not running. Please start the local server and try again.');
      }

      // Clear old Firebase events first
      const db = getDatabase();
      const events = ['agents_initialized', 'campaign_started', 'start_campaign'];
      const firebaseEvents = new FirebaseEvents(db, user.email);
      for (const event of events) {
        await remove(ref(db, `events/${event}/${firebaseEvents.encodeEmail(user.email)}`));
      }
      
      setIsLoading(true);
      startCampaign(data);
    } catch (error) {
      console.error('Error starting outreach:', error);
      setError(error.message || 'Failed to start outreach');
      setIsLoading(false);
      addNotification({
        type: 'error',
        message: error.message || 'Failed to start outreach'
      });
    }
  };

  const startCampaign = async (data) => {
    try {
      // Get user email from user object
      const userEmail = user?.email;
      if (!userEmail) {
        throw new Error('User email not found');
      }

      // Add user_email to campaign data
      const enrichedData = {
        ...data,
        user_email: userEmail
      };

      if (!isConnected) {
        throw new Error('No connection available. Please try again.');
      }

      setError(null);
      setIsLoading(true);
      
      return new Promise((resolve, reject) => {
        let campaignTimeout;
        let initTimeout;
        let hasStartedProcessing = false;
        let lastProgressTime = Date.now();

        const cleanup = () => {
          console.log('Cleaning up event handlers...');
          if (initTimeout) clearInterval(initTimeout);
          if (campaignTimeout) clearTimeout(campaignTimeout);
          off('agents_initialized');
          off('campaign_started');
          off('initialization_error');
          off('campaign_error');
          off('connect_error');
          off('error');
          off('disconnect');
          console.log('Event handlers cleaned up');
        };

        const handleInitError = (error) => {
          console.error('Initialization error occurred:', error);
          cleanup();
          const errorMessage = error.message?.includes("NoneType") 
            ? "Failed to initialize agent communication. Please try again."
            : error.message || 'Failed to initialize agents';
            
          setError(errorMessage);
          setIsLoading(false);
          setCampaignState({
            isInitializing: false,
            isStarting: false,
            error: errorMessage
          });
          addNotification({
            type: 'error',
            message: errorMessage
          });
          reject(new Error(errorMessage));
        };

        // Initialize new campaign
        console.log('Starting campaign with data:', enrichedData);
        setCampaignState({
          isInitializing: true,
          isStarting: false,
          error: null
        });
        // Handle initialization progress
        const handleProgress = (data) => {
          console.log('Initialization progress:', data);
          hasStartedProcessing = true;
          lastProgressTime = Date.now();
        };

        // Set up all our listeners
        on('initialization_error', (error) => {
          console.error('Received initialization_error:', error);
          handleInitError(error);
        });

        on('start_campaign_error', (error) => {
          console.error('Campaign start error:', error);
          handleInitError(new Error(error.message || 'Failed to start campaign'));
        });

        // Listen for agents_initialized with proper room handling
        on('agents_initialized', async (campaignStartedResponse) => {
          console.log('Received agents_initialized:', campaignStartedResponse);
          console.log('Checking response structure:', {
            isObject: typeof campaignStartedResponse === 'object',
            hasAgents: Boolean(campaignStartedResponse?.agents),
            success: campaignStartedResponse?.success,
            message: campaignStartedResponse?.message,
            timestamp: campaignStartedResponse?.timestamp
          });
          hasStartedProcessing = true;
          lastProgressTime = Date.now();

          if (!campaignStartedResponse || typeof campaignStartedResponse !== 'object') {
            console.error('Invalid agents_initialized event data:', campaignStartedResponse);
            handleInitError(new Error('Invalid agents_initialized event data'));
            return;
          }

          console.log('Latest agents_initialized event:', campaignStartedResponse);

          if (campaignStartedResponse.success || campaignStartedResponse.message === 'Sales Agent Initialized successfully') {
            try {
              console.log('Agents initialized successfully, preparing to start campaign...');
              
              // Clear initialization timeout since we've successfully initialized agents
              if (initTimeout) {
                clearInterval(initTimeout);
                initTimeout = null;
              }
              
              // Get the latest event data from Firebase
              const latestEvent = Object.entries(campaignStartedResponse)
                .filter(([key, value]) => key.startsWith('-') && value && typeof value === 'object')
                .sort((a, b) => b[0].localeCompare(a[0]))
                .map(entry => entry[1])[0];  // Use map like campaign_started handler

              if (latestEvent && latestEvent.agents) {  // Access agents directly from mapped event
                console.log('Setting agents from Firebase:', latestEvent.agents);
                setAgents(latestEvent.agents);
                
                // Sync campaign state updates with campaign_started handler
                setCampaignState(prev => ({
                  ...prev,
                  campaign_id: latestEvent.campaign_id || campaignStartedResponse.campaign_id,
                  status: 'active',
                  error: null
                }));
              }
              
              // Only emit start_campaign if we don't have a recent successful campaign_started event
              const campaignStartedEvents = Object.entries(campaignStartedResponse || {})
                .filter(([key, value]) => key.startsWith('-') && value && typeof value === 'object')
                .sort((a, b) => b[0].localeCompare(a[0])); // Sort by Firebase push ID (chronological)
              
              const latestCampaignStarted = campaignStartedEvents.length > 0 ? 
                campaignStartedEvents[0][1] : null;
              
              // Check if we have a recent successful campaign with matching ID
              const isRecentAndValid = latestCampaignStarted && 
                latestCampaignStarted.success && 
                latestCampaignStarted.campaign_id === campaignStartedResponse.campaign_id &&
                latestCampaignStarted.timestamp > Date.now() - 5 * 60 * 1000; // Within last 5 minutes
              
              if (!campaignActive && !isRecentAndValid) {
                setShowOutreachDialog(false);
                
                // Add a small delay to ensure Firebase has time to write the event
                await new Promise(resolve => setTimeout(resolve, 1000));
                
                // Now that agents are initialized, start the campaign
                const campaignPayload = {
                  ...enrichedData,  // Use enrichedData which already has user_email
                  campaign_id: campaignStartedResponse.campaign_id,
                };
                
                console.log('Emitting start_campaign with payload:', campaignPayload);
                await emit('start_campaign', campaignPayload);
                console.log('Start campaign event emitted, waiting for campaign_started event...');
              } else {
                console.log('Skipping start_campaign emission:', {
                  campaignActive,
                  latestCampaignStarted: latestCampaignStarted ? {
                    success: latestCampaignStarted.success,
                    campaign_id: latestCampaignStarted.campaign_id,
                    current_campaign_id: campaignStartedResponse.campaign_id,
                    timestamp: latestCampaignStarted.timestamp,
                    age_minutes: (Date.now() - latestCampaignStarted.timestamp) / (60 * 1000)
                  } : null
                });
                setShowOutreachDialog(false);
              }
            } catch (error) {
              console.error('Failed to emit start_campaign:', error);
              handleInitError(error);
            }
          } else {
            handleInitError(new Error(campaignStartedResponse?.message || 'Agent initialization failed'));
          }
        });

        // Listen for campaign_started event
        on('campaign_started', (response) => {
          console.log('Campaign started response:', response);
          
          if (!response || typeof response !== 'object') {
            handleInitError(new Error('Invalid campaign_started event data'));
            return;
          }

          if (response.success || response.message === 'Campaign started successfully') {
            console.log('Campaign started successfully:', response);
            
            // Set campaign active first to prevent race condition
            setCampaignActive(true);
            setAgents(response.agents || []);
            setIsLoading(false);
            setCampaignState(prev => ({
              ...prev,
              isStarting: false,
              isInitializing: false,
              status: 'active',
              campaign_id: response.campaign_id || prev.campaign_id,
              startTime: Date.now()
            }));
            setShowOutreachDialog(false);
          } else {
            // Only reset campaign active if we're sure it failed
            setCampaignActive(false);
            handleInitError(new Error(response.message || 'Failed to start campaign'));
          }
        });

        // Handle campaign start response
        // on('campaign_started', (response) => {
        //   console.log('Campaign started successfully:', response);
        //   if (response.success) {
        //     resolve(response);
        //     cleanup();  // Only cleanup after successful campaign start
        //   } else {
        //     handleInitError(new Error(response.message || 'Failed to start campaign'));
        //   }
        // });

        // Set up timeout checking
        initTimeout = setInterval(() => {
          const timeSinceLastProgress = Date.now() - lastProgressTime;
          
          // Check if we have agents - if so, we can consider initialization successful
          if (agents && agents.length > 0) {
            console.log('Agents detected, clearing initialization timeout');
            clearInterval(initTimeout);
            off('initialization_progress', handleProgress);
            return;
          }
          
          // Only timeout if we've started processing and then stopped
          // Increased timeout from 90000 to 180000 (3 minutes) to allow more time for backend processing
          if (hasStartedProcessing && timeSinceLastProgress > 180000) { 
            clearInterval(initTimeout);
            off('initialization_progress', handleProgress);
            handleInitError(new Error('Initialization stalled. Please try again.'));
          }
          // Initial startup timeout - longer to account for cold starts
          // Increased timeout from 120000 to 240000 (4 minutes) to allow more time for backend startup
          else if (!hasStartedProcessing && timeSinceLastProgress > 240000) { 
            clearInterval(initTimeout);
            off('initialization_progress', handleProgress);
            handleInitError(new Error('Initialization timed out. Please try again.'));
          }
        }, 5000);

        // First initialize the agents
        const initPayload = {
          ...enrichedData
        };
        console.log('Emitting initialize_agents with:', initPayload);
        emit('initialize_agents', initPayload);
        console.log('Initialize agents event emitted');

        // Handle connection errors
        on('connect_error', (error) => {
          console.error('Connection error during initialization:', error);
          handleInitError(new Error(`Connection error: ${error.message}`));
        });

        on('error', (error) => {
          console.error('Socket error during initialization:', error);
          handleInitError(new Error(`Socket error: ${error.message || 'Unknown error'}`));
        });

        on('disconnect', () => {
          console.log('Socket disconnected, attempting to reconnect...');
          console.log('Connection state:', {
            state: connectionState,
            connected: isConnected
          });
          
          setCampaignState({
            isInitializing: false,
            isStarting: false,
            error: null
          });
        });
      });
    } catch (error) {
      console.error('Error starting outreach:', error);
      setError(error.message || 'Failed to start outreach');
      setIsLoading(false);
    }
  };
  // Update the button disabled logic
  const isStartButtonDisabled = !isConnected || 
    campaignState.isInitializing || 
    campaignState.isStarting;

  const handleCampaignStateChange = useCallback((active, newMetrics = {}) => {
    setCampaignActive(active);
    setCampaignState(prev => ({
      ...prev,
      isInitializing: false,
      isStarting: false,
      metrics: { ...prev.metrics, ...newMetrics }
    }));
    if (!active && setIsInitialized && typeof setIsInitialized === 'function') {
      setIsInitialized(false);
    }
  }, [setIsInitialized]);

  return (
    <motion.div className="dashboard">
      <div className="dashboard-header">
        <div className={`connection-status ${connectionState === CONNECTION_STATES.CONNECTED ? 'connected' : 'disconnected'}`}>
          {connectionState === CONNECTION_STATES.CONNECTED 
            ? `Connected${agents?.length ? ` (${agents.length} active)` : ''}`
            : 'Connecting...'}
        </div>
        
        <div className="header-actions">
          <button 
            className={`start-outreach-button ${isLoading ? 'loading' : ''}`}
            onClick={() => setShowOutreachDialog(true)}
            disabled={isStartButtonDisabled || campaignActive}
          >
            {isLoading ? 'Initializing...' : 
             campaignState.isStarting ? 'Starting Campaign...' : 
             campaignActive ? 'Campaign Active' :
             'Start New Campaign'}
          </button>
          <ProfileMenu user={user} onLogout={logout} />
        </div>
      </div>

      <div className="metrics-section">
        <PerformanceDashboard 
          metrics={campaignState.metrics} 
          agentCount={agents.length}
          isActive={campaignActive}
          startTime={campaignState.startTime}
        />
      </div>

      <div className="main-content">
        {campaignState.isInitializing ? (
          <InitializingUI />
        ) : !campaignActive ? (
          <PlaceholderUI />
        ) : (
          <AgentChat 
            agents={agents}
            campaignActive={campaignActive}
            campaignState={campaignState}
            onCampaignStateChange={handleCampaignStateChange}
            dashboardConnectionState={connectionState}
            isConnected={isConnected}
          />
        )}
      </div>

      <AnimatePresence mode="sync">
        {error && (
          <motion.div 
            key="error-message"
            className="error-message"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
          >
            {error}
            <button onClick={() => setError(null)}>Dismiss</button>
          </motion.div>
        )}



        {showOutreachDialog && (
          <motion.div
            key="outreach-dialog"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
          >
            <OutreachDialog
              onStart={handleStartOutreach}
              onClose={() => {
                setShowOutreachDialog(false);
                setIsLoading(false);
                setCampaignState({
                  isInitializing: false,
                  isStarting: false,
                  error: null
                });
              }}
              loading={isLoading}
              error={error}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
export default Dashboard;
