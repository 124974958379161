export class PostEventSource {
  constructor(url, config) {
    this.url = url;
    this.config = config;
    this.eventSource = null;
    this.eventListeners = { message: [], error: [] };
    this.onmessage = null;
    this.onerror = null;
    this.isClosing = false;
    this.connect();
  }

  connect() {
    const controller = new AbortController();
    const { signal } = controller;

    fetch(this.url, {
      ...this.config,
      signal,
      headers: {
        ...this.config.headers,
        'Connection': 'keep-alive'
      }
    }).then(response => {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      const processText = () => {
        if (this.isClosing) {
          reader.cancel();
          return;
        }

        reader.read().then(({value, done}) => {
          if (done) {
            return;
          }
          
          buffer += decoder.decode(value, {stream: true});
          const lines = buffer.split('\n');
          buffer = lines.pop() || '';
          
          lines.forEach(line => {
            if (line.startsWith('data: ')) {
              try {
                const event = { data: line.slice(6) };
                if (this.onmessage && !this.isClosing) {
                  this.onmessage(event);
                }
                this.eventListeners.message.forEach(listener => {
                  if (!this.isClosing) listener(event);
                });
              } catch (err) {
                console.error('Error processing message:', err);
              }
            }
          });
          
          if (!this.isClosing) {
            processText();
          }
        }).catch(error => {
          if (!this.isClosing) {
            if (this.onerror) {
              this.onerror(error);
            }
            this.eventListeners.error.forEach(listener => listener(error));
          }
        });
      };

      processText();
    }).catch(error => {
      if (!this.isClosing) {
        if (this.onerror) {
          this.onerror(error);
        }
        this.eventListeners.error.forEach(listener => listener(error));
      }
    });

    this.close = () => {
      this.isClosing = true;
      controller.abort();
    };
  }

  addEventListener(type, callback) {
    if (this.eventListeners[type]) {
      this.eventListeners[type].push(callback);
    }
  }

  removeEventListener(type, callback) {
    if (this.eventListeners[type]) {
      this.eventListeners[type] = this.eventListeners[type].filter(cb => cb !== callback);
    }
  }
}
