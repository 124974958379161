import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useWebSocket, CONNECTION_STATES } from '../contexts/WebSocketContext';
import { useAuth } from '../components/AuthProvider';
import './AgentChat.css';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import EmailApprovalUI from './EmailApprovalUI';
import AgentAvatar from './AgentAvatar';
import AppMessagingService from '../services/AppMessagingService';
import MentionDropdown from './MentionDropdown';
import getCaretCoordinates from 'textarea-caret-position'; // Import AppMessagingService
import { getHumanName, processMessageContent } from '../config/agentIdentities'; // Add import
 
export const AgentChat = ({ 
  campaignActive, 
  campaignState,
  onCampaignStateChange,
  dashboardConnectionState,
  agents = [] // Ensure agents prop is properly defined
}) => {
  const { user, token } = useAuth();
  const [messages, setMessages] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [feedbackMode, setFeedbackMode] = useState(false);
  const [isRoomJoined, setIsRoomJoined] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [mentionState, setMentionState] = useState({
    isActive: false,
    query: '',
    position: { top: 0, left: 0 }
  });
  
  // Email approval handlers
  const handleEmailApprove = async (email) => {
    if (!token) {
      throw new Error('No authentication token available');
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/emails/approve`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          id: email.id,
          approved: true,
          feedback: ''
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      return result;
      
    } catch (error) {
      console.error('Error approving email:', error);
      throw error;
    }
  };
  
  const handleEmailReject = async (email, feedback) => {
    if (!token) {
      throw new Error('No authentication token available');
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/emails/approve`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({
          id: email.id,
          approved: false,
          feedback: feedback || ''
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ error: 'Unknown error occurred' }));
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      return result;
      
    } catch (error) {
      console.error('Error rejecting email:', error);
      throw error;
    }
  };
  const messagesEndRef = useRef(null);
  const messageBuffer = useRef([]);
  const processingBuffer = useRef(false);
  const { emit, on, off } = useWebSocket();
  // Use dashboard connection state if provided, otherwise use local state
  const isConnected = dashboardConnectionState === CONNECTION_STATES.CONNECTED;
  const currentCampaignId = useRef(null);

  console.log('AgentChat render:', {
    isConnected,
    campaignActive,
    messagesCount: messages.length,
    agentsCount: agents.length,
    userEmail: user?.email
  });

  // Debug logging
  useEffect(() => {
    console.log('AgentChat state changed:', {
      isConnected,
      isRoomJoined,
      messagesCount: messages.length,
      agentsCount: agents.length,
      userEmail: user?.email
    });
  }, [isConnected, isRoomJoined, messages.length, agents.length, user?.email]);

  // Update campaign state when it changes
  useEffect(() => {
    if (!campaignState || !onCampaignStateChange) return;

    // Don't validate if we're initializing
    if (campaignState.isInitializing) {
      return;
    }

    // Validate campaign state format
    const isValidCampaignState = 
      campaignState.status &&
      ['active', 'stopped', 'paused'].includes(campaignState.status);

    // Reset to default stopped state if invalid or failed
    if (!isValidCampaignState) {
      console.warn('Invalid campaign state format:', campaignState);
      
      // Clear current campaign ID if this was our active campaign
      if (campaignState.campaign_id === currentCampaignId.current) {
        window.currentCampaignId = null;
        currentCampaignId.current = null;
        console.log('Cleared currentCampaignId due to invalid state');
      }
      
      return;
    }

    // Update campaign ID if provided
    if (campaignState.campaign_id) {
      window.currentCampaignId = campaignState.campaign_id;
      currentCampaignId.current = campaignState.campaign_id;
    }
  }, [campaignState, onCampaignStateChange]);

  // Clear campaign ID and state when component unmounts
  useEffect(() => {
    return () => {
      window.currentCampaignId = null;
      currentCampaignId.current = null;
      onCampaignStateChange?.({
        status: 'stopped',
        agents: [],
        timestamp: new Date().toISOString(),
        error: null,
        success: false,
        campaign_id: null
      });
    };
  }, [onCampaignStateChange]);

  // Clear campaign ID when component unmounts
  useEffect(() => {
    return () => {
      window.currentCampaignId = null;
    };
  }, []);

  // Clear messages when campaign changes
  useEffect(() => {
    if (campaignState?.campaign_id && campaignState.campaign_id !== currentCampaignId.current) {
      setMessages([]);
      messageBuffer.current = [];
    }
  }, [campaignState?.campaign_id]);

  // Message buffer processor with campaign ID filtering and deduplication
  // Store processed message signatures to prevent duplicates
  const processedMessages = useRef(new Set());
  
  const processMessageBuffer = useCallback(() => {
    if (messageBuffer.current.length > 0 && !processingBuffer.current) {
      processingBuffer.current = true;
      const message = messageBuffer.current.shift();
      
      // Add debug logging for message structure
      console.log('[AgentChat] Raw message structure:', JSON.stringify(message, null, 2));
      
      // Skip if message is null or undefined
      if (!message) {
        processingBuffer.current = false;
        return;
      }

      console.log('[AgentChat] Processing message:', {
        messageType: message?.type || 'undefined',
        eventName: message?.eventName || message?.event_name || 'no_event',
        campaignId: message?.campaign_id || null,
        timestamp: message?.timestamp || new Date().toISOString(),
        hasEmailData: Boolean(message?.emailData),
        messageSource: message?.messageSource || message?.source || 'unknown',
        messageId: message?.id || message?.emailData?.id
      });

      // Early duplicate check based on message ID
      const messageId = message?.id || message?.emailData?.id;
      if (messageId && messages.some(m => m.id === messageId)) {
        console.log('Skipping duplicate message by ID:', messageId);
        processingBuffer.current = false;
        return;
      }
      
      // Create a content signature for additional deduplication
      const contentSignature = message.campaign_id && message.content ? 
        `${message.campaign_id}_${typeof message.content === 'string' ? message.content : JSON.stringify(message.content)}` : null;
      
      // Check if message was already processed by content signature
      if (contentSignature && processedMessages.current.has(contentSignature)) {
        console.log('Skipping duplicate message by content signature:', contentSignature);
        processingBuffer.current = false;
        return;
      }
      
      // Mark as processed if we have a content signature
      if (contentSignature) {
        processedMessages.current.add(contentSignature);
      }

      setMessages(prevMessages => {
        const currentCampaignIdValue = currentCampaignId.current;

        // Handle email review requests from both UnifiedMessaging and AppMessagingService
        if (message.type === 'email_review_request' || 
            message.event_name === 'email_review_request' || 
            (message.content && message.content.type === 'email_review_request') ||
            (message.data && message.data.type === 'email_review_request')) {
          
          console.log('[AgentChat] Processing email review request:', {
            emailId: message.emailData?.id || message.id,
            subject: message.emailData?.subject || message.subject,
            timestamp: message.timestamp,
            source: message.source || 'event'
          });

          // Normalize email data structure from both UnifiedMessaging and AppMessagingService
          const emailData = {
            id: message.emailData?.id || message.data?.id || message.id || `email_${Date.now()}`,
            subject: message.emailData?.subject || message.data?.subject || message.subject || message.content?.subject,
            body: message.emailData?.body || message.data?.body || message.body || message.content?.body || 
                  (typeof message.content === 'object' ? message.content.content : message.content),
            recipient_email: message.emailData?.recipient_email || message.data?.recipient_email || message.recipient_email || message.content?.recipient_email,
            signature: message.emailData?.signature || message.data?.signature || message.signature || message.content?.signature || '',
            is_html: message.emailData?.is_html || message.data?.is_html || message.is_html || message.content?.is_html || false,
            campaign_id: message.campaign_id || message.data?.campaign_id || message.emailData?.campaign_id || message.content?.campaign_id || campaignState?.campaign_id,
            source: message.source || message.data?.source || message.messageSource || 'event'
          };

          // Check for duplicate email reviews
          const isDuplicateReview = prevMessages.some(msg =>
            msg.type === 'email_review_request' &&
            msg.emailData?.id === emailData.id
          );

          if (isDuplicateReview) {
            console.log('Skipping duplicate email review:', emailData);
            return prevMessages;
          }

          // IMPORTANT: Only set currentEmail if we don't already have an email with this ID being displayed
          // This prevents duplicate rendering of the EmailApprovalUI component
          const isAlreadyDisplayed = currentEmail && currentEmail.id === emailData.id;
          if (!isAlreadyDisplayed) {
            setCurrentEmail(emailData);
          } else {
            console.log('Email already being displayed, not updating currentEmail state:', emailData.id);
          }

          // Create normalized message with email data
          const normalizedMessage = {
            ...message,
            id: emailData.id,
            type: 'email_review_request',
            emailData,
            content: `📧 Email Review Request (via ${emailData.source})\nTo: ${emailData.recipient_email}\nSubject: ${emailData.subject}\n\n${emailData.body}`,
            timestamp: message.timestamp || Date.now(),
            campaign_id: emailData.campaign_id,
            source: emailData.source
          };

          // We'll only add the EmailApprovalUI component if we're not already displaying it via currentEmail
          // This prevents duplicate rendering of the EmailApprovalUI component
          const messageWithUI = {
            ...normalizedMessage,
            content: isAlreadyDisplayed ? normalizedMessage.content : (
              <EmailApprovalUI
                email={emailData}
                onApprove={() => handleEmailApprove(emailData)}
                onReject={(feedback) => handleEmailReject(emailData, feedback)}
              />
            )
          };
          return [...prevMessages, messageWithUI];
        }

        // For non-email review messages, check campaign ID
        if (currentCampaignIdValue && message.campaign_id && message.campaign_id !== currentCampaignIdValue) {
          console.log('Skipping message from different campaign:', {
            reason: 'campaign mismatch',
            messageCampaignId: message.campaign_id,
            currentCampaignId: currentCampaignIdValue
          });
          return prevMessages;
        }

        const isDuplicate = prevMessages.some(msg => 
          msg.agent_id === message.agent_id && 
          msg.content === message.content &&
          msg.timestamp === message.timestamp
        );

        if (isDuplicate) {
          console.log('Duplicate message detected, skipping:', message);
          return prevMessages;
        }

        // Handle Firebase server timestamp
        let timestamp;
        if (message.timestamp && typeof message.timestamp === 'object' && message.timestamp.sv === 'timestamp') {
          timestamp = message._timestamp || new Date().toISOString();
        } else if (typeof message.timestamp === 'number') {
          timestamp = new Date(message.timestamp).toISOString();
        } else {
          timestamp = message.timestamp || new Date().toISOString();
        }

        const normalizedMessage = {
          ...message,
          timestamp,
          type: message.type || 'system',
          campaign_id: message.campaign_id || campaignState?.campaign_id,
          eventName: message.eventName || message.event_name || 'no_event',
          messageSource: message.messageSource || message.source || 'unknown'
        };

        requestAnimationFrame(() => {
          if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        });

        return [...prevMessages, normalizedMessage];
      });

      setTimeout(() => {
        processingBuffer.current = false;
        if (messageBuffer.current.length > 0) {
          processMessageBuffer();
        }
      }, 5);
    }
  }, [campaignState]);

  const joinRoom = useCallback(async () => {
    if (!isConnected || !user?.email || isRoomJoined) {
      console.warn('Cannot join room:', {
        isConnected,
        hasEmail: !!user?.email,
        isRoomJoined
      });
      return;
    }

    try {
      // Wait for AppMessagingService to be ready
      if (!AppMessagingService.messaging) {
        console.log('Waiting for AppMessagingService to be ready...');
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      // Join room through both Socket.IO and Firebase
      const success = await emit('join', {
        room: user.email,
        email: user.email,
        metadata: {
          timestamp: Date.now(),
          clientInfo: {
            userAgent: navigator.userAgent,
            platform: navigator.platform
          }
        }
      });

      // Also join through AppMessagingService for Firebase
      const firebaseSuccess = await AppMessagingService.joinRoom(user.email);
      console.log('Room join results:', { socketSuccess: success, firebaseSuccess });
      
      if (success && firebaseSuccess) {
        setIsRoomJoined(true);
        console.log('Successfully joined room through both Socket.IO and Firebase');
      }
    } catch (error) {
      console.error('Failed to join room:', error);
    }
  }, [isConnected, user?.email, isRoomJoined, emit]);

  // Single useffect for room joining
  useEffect(() => {
    if (isConnected && !isRoomJoined && user?.email) {
      console.log('Connection established, attempting to join room');
      joinRoom();
    }
  }, [isConnected, isRoomJoined, user?.email, joinRoom]);

  useEffect(() => {
    if (!isConnected) {
      console.log('Socket not ready:', { isConnected });
      return;
    }

    const handleError = (error) => {
      console.error('Socket error:', error);
      setFeedback(`Error: ${error.message}`);
    };

    // Set up message event listeners with deduplication
    on('group_chat_message', (message) => {
      console.log('Received message:', { 
        type: message?.type,
        id: message?.id,
        campaign_id: message?.campaign_id,
        content: message?.content,
        emailData: message?.emailData,
        event_name: message?.event_name
      });

      // Skip if message is already in buffer or messages
      const isDuplicate = messageBuffer.current.some(m => 
        m.id === message.id || 
        (m.type === message.type && 
         m.campaign_id === message.campaign_id && 
         m.content === message.content)
      );
      
      if (isDuplicate) {
        console.log('Skipping duplicate message:', {
          type: message.type,
          id: message.id,
          campaign_id: message.campaign_id
        });
        return;
      }
      
      messageBuffer.current.push(message);
      processMessageBuffer();
    });
    
    on('email_review_request', (message) => {
      console.log('Received email review request directly:', message);
      // Skip if this is an event or if the message is already in the buffer
      if (message.eventId || messageBuffer.current.some(m => 
          m.id === message.id || 
          (m.emailData && m.emailData.id === message.emailData?.id)
      )) {
        console.log('Skipping duplicate email review request:', {
          eventId: message.eventId,
          id: message.id,
          emailId: message.emailData?.id
        });
        return;
      }

      messageBuffer.current.push({
        ...message,
        type: 'email_review_request',
        timestamp: message.timestamp || Date.now()
      });
      processMessageBuffer();
    });

    on('error', handleError);

    return () => {
      off('group_chat_message');
      off('email_review_request');
      off('error');
    };
  }, [isConnected, processMessageBuffer]);

  useEffect(() => {
    if (!isConnected) {
      console.log('Socket not ready:', { isConnected });
      return;
    }

    joinRoom();
  }, [joinRoom, isConnected]);

  // Attempt to join room when connection is established
  useEffect(() => {
    if (isConnected && !isRoomJoined && user?.email) {
      console.log('Connection established, attempting to join room');
      joinRoom();
    }
  }, [isConnected, isRoomJoined, user?.email, joinRoom]);

  // Handle disconnection
  useEffect(() => {
    if (!isConnected && isRoomJoined) {
      console.log('Lost connection, marking room as unjoined');
      setIsRoomJoined(false);
    }
  }, [isConnected, isRoomJoined]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      setIsRoomJoined(false);
    };
  }, []);

  // Single effect for scrolling
  useEffect(() => {
    if (messagesEndRef.current) {
      console.log('Messages updated, scrolling to bottom:', {
        messageCount: messages.length,
        lastMessage: messages[messages.length - 1]
      });
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, messages.length]);

  useEffect(() => {
    if (messageBuffer.current.length > 0) {
      processMessageBuffer();
    }
  }, [processMessageBuffer]);

  useEffect(() => {
    if (isConnected && campaignActive) {
      onCampaignStateChange(campaignActive, { ...(campaignState || {}), agentCount: agents.length });
    }
  }, [agents.length]);

  const sendMessage = (messageContent) => {
    if (isConnected) {
      emit('message', {
        token: user.token, 
        room: user.email, 
        message: messageContent 
      });
    }
  };

  const handleEmailApproval = async (approved, feedbackText, email) => {
    if (approved) {
      try {
        // Make HTTP call to approve
        await handleEmailApprove(email);
        
        // Send chat message
        sendMessage({
          role: 'user',
          content: 'Email approved for sending.',
          metadata: {
            type: 'email_approval',
            approved: true
          }
        });

        // Update the email review message to mark it as approved
        setMessages(prevMessages => {
          return prevMessages.map(msg => {
            if (msg.type === 'email_review_request' && msg.emailData?.id === email.id) {
              return { ...msg, approved: true };
            }
            return msg;
          });
        });

        // Clear the current email state
        setCurrentEmail(null);
      } catch (error) {
        console.error('Error approving email:', error);
        sendMessage({
          role: 'system',
          content: `Error: Failed to approve email - ${error.message}`,
          metadata: { type: 'error' }
        });
      }
    } else if (feedbackText === 'FEEDBACK_MODE') {
      // Store email for when feedback is submitted
      setCurrentEmail(email);
      setFeedbackMode(true);
      
      // Show feedback prompt
      sendMessage({
        role: 'system',
        content: 'Please provide your feedback about what changes you would like to make to the email.',
        metadata: {
          type: 'email_feedback_request'
        }
      });
    } else {
      try {
        // Make HTTP call to reject with feedback
        await handleEmailReject(email, feedbackText);

        // Send chat message
        sendMessage({
          role: 'user',
          content: `Email rejected with feedback: ${feedbackText}`,
          metadata: {
            type: 'email_approval',
            approved: false,
            feedback: feedbackText
          }
        });

        // Update the email review message to mark it as rejected
        setMessages(prevMessages => {
          return prevMessages.map(msg => {
            if (msg.type === 'email_review_request' && msg.emailData?.id === email.id) {
              return { ...msg, approved: false, feedback: feedbackText };
            }
            return msg;
          });
        });

        // Clear the current email state
        setCurrentEmail(null);
      } catch (error) {
        console.error('Error rejecting email:', error);
        sendMessage({
          role: 'system',
          content: `Error: Failed to reject email - ${error.message}`,
          metadata: { type: 'error' }
        });
      }
    }
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    if (!feedback.trim()) return;

    // Add user's message to chat
    const userMessage = {
      agent_id: 'user',
      content: feedback,
      timestamp: new Date().toISOString(),
      type: 'message'
    };
    setMessages(prev => [...prev, userMessage]);

    if (feedbackMode && currentEmail) {
      try {
        // Make HTTP call to reject with feedback
        await handleEmailReject(currentEmail, feedback);
        
        // Send the feedback message
        sendMessage({
          role: 'user',
          content: feedback,
          metadata: {
            type: 'email_approval',
            approved: false,
            feedback: feedback
          }
        });
        
        // Clear state
        setFeedbackMode(false);
        setCurrentEmail(null);
      } catch (error) {
        console.error('Error rejecting email:', error);
        sendMessage({
          role: 'system',
          content: `Error: Failed to reject email - ${error.message}`,
          metadata: { type: 'error' }
        });
      }
    } else {
      // Normal chat message
      sendMessage(feedback);
    }
    
    setFeedback('');
  };

  const getAgentInitials = (name) => {
    console.log('Getting initials for name:', name);
    if (!name) return '';
    if (name === 'system') return '🤖';

    // First try to get capitals
    const capitals = name.match(/[A-Z][a-z]+/g);
    if (capitals && capitals.length >= 1) {
      console.log('Found capital words:', capitals);
      return capitals.slice(0, 2).map(word => word[0]).join('');
    }

    // If no capitals, split by underscore or space
    const words = name.split(/[_ ]+/);
    console.log('Split into words:', words);
    return words
      .slice(0, 2)
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const getMessageContent = (msg) => {
    if (!msg || !msg.content) return '';
    
    if (typeof msg.content === 'string') {
      return msg.content;
    }
    
    if (typeof msg.content === 'object') {
      return msg.content.text || 
             msg.content.message || 
             msg.content.content ||
             '';
    }
    
    return '';
  };

  const getMessagePosition = (message, index, messages) => {
    // Check for user messages - these should always be on the right
    if (message.role === 'user' || 
        message.agent_id === 'user' || 
        message.agent_id === user?.email || 
        message.sender === user?.email) {
      return 'right';
    }
    
    // System messages always on the left
    if (message.agent_id === 'system') return 'left';

    // Default to left if first message
    if (index === 0) return 'left';
    
    const prevMessage = messages[index - 1];
    if (!prevMessage) return 'left';

    // Check for @ mentions in content
    let contentToCheck = '';

    // Safely get current message content
    if (message.content) {
      if (typeof message.content === 'string') {
        contentToCheck = message.content;
      } else if (typeof message.content === 'object') {
        contentToCheck = message.content.text || 
                        message.content.message || 
                        message.content.content ||
                        '';
      }
    }

    // Extract mentioned agent from @ mention
    const mentionMatch = contentToCheck.match(/@([A-Za-z][A-Za-z_]*)/); 
    const mentionedAgent = mentionMatch ? mentionMatch[1] : null;

    // Check if the mentioned agent exists in the agents list
    const isValidMention = mentionedAgent && agents.some(agent => {
      if (typeof agent === 'object') {
        return agent.name.toLowerCase() === mentionedAgent.toLowerCase() || 
               (agent.id && agent.id.toLowerCase() === mentionedAgent.toLowerCase());
      }
      return agent.toLowerCase() === mentionedAgent.toLowerCase();
    });

    // If it's a reply to the previous message (either through @ mention or recipient_id)
    if (message.agent_id === prevMessage.agent_id) {
      // Same agent, keep the thread on same side
      return prevMessage.position;
    }

    // Check for direct reply through @ mention
    if (isValidMention) {
      const prevAgentId = typeof prevMessage.agent_id === 'object' ? 
        prevMessage.agent_id.id || prevMessage.agent_id.name : 
        prevMessage.agent_id;

      if (prevAgentId.toLowerCase() === mentionedAgent.toLowerCase()) {
        // Direct reply to previous message, alternate side
        return prevMessage.position === 'left' ? 'right' : 'left';
      }
    }

    // Check for reply through recipient_id
    if (message.recipient_id) {
      const prevAgentId = typeof prevMessage.agent_id === 'object' ? 
        prevMessage.agent_id.id || prevMessage.agent_id.name : 
        prevMessage.agent_id;

      if (prevAgentId === message.recipient_id) {
        // Direct reply to previous agent, alternate side
        return prevMessage.position === 'left' ? 'right' : 'left';
      }
    }

    // Start new conversation thread on opposite side
    return prevMessage.position === 'left' ? 'right' : 'left';
  };

  const renderAgentStatus = () => {
    if (!agents || !Array.isArray(agents)) return null;

    // Deduplicate agents while preserving latest status
    const uniqueAgents = Object.values(agents.reduce((acc, agent) => {
      if (!agent || !agent.id) return acc;
      
      // Keep or update agent in accumulator
      acc[agent.id] = {
        ...acc[agent.id],
        ...agent,
        status: agent.status || acc[agent.id]?.status || 'active'
      };
      return acc;
    }, {}));

    return (
      <div className="agent-avatars">
        {uniqueAgents.map((agent) => (
          <AgentAvatar
            key={agent.id || agent.name}
            agent={agent}
            status={agent.status}
            getAgentInitials={getAgentInitials}
          />
        ))}
      </div>
    );
  };

  const formatMessageTime = (timestamp) => {
    if (!timestamp) return '';

    const messageDate = new Date(timestamp);
    const now = new Date();
    const diffInMinutes = Math.floor((now - messageDate) / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    // For messages less than 1 minute old
    if (diffInMinutes < 1) {
      return 'Now';
    }
    
    // For messages less than 1 hour old
    if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    }
    
    // For messages from today
    if (diffInHours < 24 && messageDate.getDate() === now.getDate()) {
      return messageDate.toLocaleTimeString([], { 
        hour: 'numeric',
        minute: '2-digit',
        hour12: true 
      });
    }
    
    // For messages from yesterday
    if (diffInDays === 1) {
      return 'Yesterday';
    }
    
    // For older messages
    return messageDate.toLocaleDateString([], {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const formatMessage = (msg) => {
    // Ensure we have a string before processing
    const content = getMessageContent(msg);
    
    // First process the content through our identity manager
    let processedContent = processMessageContent(content);
    
    // Then handle additional formatting
    processedContent = processedContent
      // Preserve line breaks
      .replace(/\n/g, '  \n')
      // Convert URLs to markdown links
      .replace(
        /(https?:\/\/[^\s<]+[^<.,:;"')\]\s])/g,
        (url) => `[${url}](${url})`
      )
      // Convert emails to markdown links
      .replace(
        /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g,
        (email) => `[${email}](mailto:${email})`
      );

    // Sanitize the content
    processedContent = DOMPurify.sanitize(processedContent);

    return (
      <div className="message-text">
        <ReactMarkdown 
          components={{
            p: ({ children }) => (
              <span style={{ 
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                display: 'block'
              }}>
                {children}
              </span>
            ),
            text: ({ children }) => {
              // Process mentions in text nodes
              if (typeof children === 'string') {
                // Use the comprehensive agent name pattern
                const parts = children.split(/(@(?:Website_Analyzer_Agent|Sales_Assistant_Agent|EmailReviewerAgent|AuditOutgoingEmailAgent|EmailSenderAgent|EmailResponseGeneratorAgent|EngagementAnalysisAgent|FollowUpStrategizerAgent|EmailResponderAgent))\b/g);
                return parts.map((part, index) => {
                  if (part.startsWith('@')) {
                    const agentName = part.substring(1);
                    return <span key={index} className="agent-mention">@{getHumanName(agentName)}</span>;
                  }
                  return part;
                });
              }
              return children;
            }
          }}
        >
          {processedContent}
        </ReactMarkdown>
      </div>
    );
  };
  
  const renderMessage = (msg, index) => {
    console.log('Rendering message:', msg);
    console.log('Message type:', msg.type);
    console.log('Message metadata:', msg.metadata);

    // Get position before rendering
    const position = getMessagePosition(msg, index, messages);
    
    // Check if this is a reply
    const isReply = index > 0 && (
      (msg.content && (
        (typeof msg.content === 'string' && msg.content.match(/@([A-Za-z][A-Za-z_]*)/)) ||
        (typeof msg.content === 'object' && msg.content.text && msg.content.text.match(/@([A-Za-z][A-Za-z_]*)/))
      )) ||
      (msg.recipient_id && messages[index - 1].agent_id === msg.recipient_id)
    );

    console.log('Message type:', msg.type);
    console.log('Message metadata:', msg.metadata);

    if (msg.type === 'PENDING_APPROVAL' || msg.type === 'email_review_request') {
      console.log('Found email review request message:', msg);

      // For email_review_request type, use the message data directly
      const emailData = msg.type === 'email_review_request' ? {
        id: msg.emailData?.id || msg.id,
        subject: msg.emailData?.subject || msg.subject,
        body: msg.emailData?.body || msg.body,
        recipient_email: msg.emailData?.recipient_email || msg.recipient_email,
        signature: msg.emailData?.signature || msg.signature || '',
        is_html: msg.emailData?.is_html || msg.is_html
      } : msg.metadata?.email;

      console.log('Rendering EmailApprovalUI with email:', emailData);
      return (
        <EmailApprovalUI
          email={emailData}
          onApprove={() => handleEmailApproval(true, null, emailData)}
          onReject={() => handleEmailApproval(false, 'FEEDBACK_MODE', emailData)}
        />
      );
    }

    if (msg.type === 'TIMER_STARTED') {
      return (
        <div className="timer-message">
          <div className="timer-header">
            ⏳ Auto-sending in {msg.metadata.timing.wait_time}s
          </div>
          <EmailApprovalUI
            email={msg.metadata.email}
            onApprove={() => handleEmailApproval(true, null, msg.metadata.email)}
            onReject={() => handleEmailApproval(false, 'FEEDBACK_MODE', msg.metadata.email)}
            mode="timer"
            waitTime={msg.metadata.timing.wait_time}
          />
        </div>
      );
    }

    if (msg.type === 'AUTONOMOUS_SEND') {
      return (
        <div className="autonomous-message">
          <div className="autonomous-header">
            🚀 Sending email automatically
          </div>
          <div className="recall-info">
            You have {msg.metadata.timing.recall_window}s to recall this email
          </div>
          <EmailApprovalUI
            email={msg.metadata.email}
            mode="autonomous"
            recallWindow={msg.metadata.timing.recall_window}
            onRecall={() => handleEmailApproval(false, 'Email recalled by user', msg.metadata.email)}
          />
        </div>
      );
    }

    return (
      <motion.div 
        key={msg.id || `${msg.agent_id}-${index}-${msg.timestamp}`}
        className={`message ${msg.type === 'agent_message' ? 'agent' : 'system'} ${position} ${isReply ? 'reply' : ''}`}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        {/* Always show avatar on the correct side */}
        {position === 'left' && (
          <div className="message-avatar-container" style={{ width: '32px', height: '32px', flexShrink: 0 }}>
            <AgentAvatar
              agent={msg.agent_id}
              getAgentInitials={getAgentInitials}
              style={{ width: '32px', height: '32px', fontSize: '12px' }}
            />
          </div>
        )}
        
        <div className={`message-content ${position}`}>
          <div className="message-sender">
            {position === 'right' ? 
              (user?.displayName || user?.name || user?.email?.split('@')[0]) : 
              getHumanName(msg.agent_name || msg.agent_id)
            }
          </div>
          <div className="message-body">
            {formatMessage(msg)}
          </div>
          <div className="message-time">
            {formatMessageTime(msg.timestamp)}
          </div>
        </div>
        
        {position === 'right' && (
          <div className="message-avatar-container" style={{ width: '32px', height: '32px', flexShrink: 0 }}>
            <img 
              src={user?.picture || user?.photoURL || user?.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.email || '')}&background=2563EB&color=fff`} 
              alt={user?.displayName || user?.email?.split('@')[0]} 
              style={{ 
                width: '32px', 
                height: '32px', 
                borderRadius: '50%',
                border: '2px solid #fff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                objectFit: 'cover'
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.email || '')}&background=2563EB&color=fff`;
              }}
            />
          </div>
        )}
      </motion.div>
    );
  };

  useEffect(() => {
    console.log('AgentChat state updated:', {
      messagesCount: messages.length,
      lastMessage: messages[messages.length - 1],
      campaignActive,
      isConnected,
    });
  }, [messages, campaignActive, isConnected]);

  // Handle campaign events
  useEffect(() => {
    if (!isConnected) return;

    const handleCampaignStarted = (data) => {
      console.log('Campaign started:', data);
      onCampaignStateChange(true, {
        startTime: new Date().toISOString(),
        ...data.metrics
      });
    };

    const handleCampaignError = (error) => {
      console.error('Campaign error:', error);
      onCampaignStateChange(false, {
        error: error.message || 'Campaign error occurred'
      });
    };

    const handleMetricsUpdate = (metrics) => {
      onCampaignStateChange(campaignActive, { ...metrics, agentCount: agents.length });
    };

    on('campaign_started', handleCampaignStarted);
    on('campaign_error', handleCampaignError);
    on('metrics_update', handleMetricsUpdate);

    return () => {
      off('campaign_started', handleCampaignStarted);
      off('campaign_error', handleCampaignError);
      off('metrics_update', handleMetricsUpdate);
    };
  }, [isConnected, campaignActive, onCampaignStateChange, agents.length]);

  useEffect(() => {
    console.log('Connection status:', {
      isRoomJoined,
      isConnected,
      messageCount: messages.length,
      userEmail: user?.email
    });
  }, [isRoomJoined, isConnected, messages.length, user?.email]);

  useEffect(() => {
    if (isConnected) {
      on('connect', () => {
        console.log('Connected to chat server');
      });

      on('disconnect', () => {
        console.log('Disconnected from chat server');
        // Attempt reconnection
        setTimeout(() => {
          if (!isConnected) {
            console.log('Attempting to reconnect...');
          }
        }, 5000);
      });

      on('reconnect', () => {
        console.log('Reconnected to chat server');
      });

      return () => {
        off('connect');
        off('disconnect');
        off('reconnect');
      };
    }
  }, [isConnected]);

  const renderEmailApprovalUI = () => {
    if (currentEmail) {
      // Check if this email is already displayed in a message
      const isAlreadyInMessages = messages.some(msg => 
        msg.type === 'email_review_request' && 
        msg.emailData?.id === currentEmail.id
      );
      
      if (isAlreadyInMessages) {
        console.log('Email already displayed in messages, not rendering duplicate UI:', currentEmail.id);
        return null;
      }
      
      return (
        <EmailApprovalUI
          email={currentEmail}
          onApprove={(email) => handleEmailApproval(true, null, email)}
          onReject={(email, feedback) => handleEmailApproval(false, feedback, email)}
          onRequestFeedback={(email) => handleEmailApproval(false, 'FEEDBACK_MODE', email)}
        />
      );
    }
    return null;
  };

  return (
    <div className="agent-chat-container">
      {campaignState?.error && (
        <div className="error-banner">
          <p>{campaignState.error}</p>
          <button 
            onClick={() => onCampaignStateChange(campaignActive, { error: null })}
            className="dismiss-button"
          >
            ×
          </button>
        </div>
      )}

      <div className="chat-header">
        <div className="header-title">
          <h3>Sales Team Chat</h3>
          <span className="subtitle">
            Your Autonomous Sales Organization...
          </span>
        </div>
        {renderAgentStatus()}
      </div>

      <div className="messages-container" style={{ minHeight: '300px' }}>
        {currentEmail && renderEmailApprovalUI()}
        {messages.length === 0 ? (
          <div className="no-messages">
            {campaignActive ? 'Waiting for messages...' : 'No messages yet'} (Total: {messages.length})
          </div>
        ) : (
          <AnimatePresence mode="popLayout">
            {messages.map((msg) => renderMessage(msg, `${msg.type}_${msg.agent_id || 'system'}_${msg.timestamp || Date.now()}`))}
          </AnimatePresence>
        )}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleFeedbackSubmit} className="feedback-input-container">
        <MentionDropdown
          agents={agents}
          query={mentionState.query}
          position={mentionState.position}
          visible={mentionState.isActive}
          onSelect={(agent) => {
            const textArea = document.querySelector('.feedback-input');
            const cursorPosition = textArea.selectionStart;
            const textBeforeMention = feedback.substring(0, cursorPosition).replace(/@[^\s]*$/, '');
            const textAfterMention = feedback.substring(cursorPosition);
            const newText = `${textBeforeMention}@${agent.name} ${textAfterMention}`;
            setFeedback(newText);
            setMentionState(prev => ({ ...prev, isActive: false }));
            textArea.focus();
          }}
        />
        <textarea
          className="feedback-input"
          value={feedback}
          placeholder={feedbackMode ? "Type your feedback for the email..." : "Type your message to the AI team..."}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleFeedbackSubmit(e);
            }
          }}
          onChange={(e) => {
            setFeedback(e.target.value);
            const textArea = e.target;
            const cursorPosition = textArea.selectionStart;
            const textBeforeCursor = e.target.value.substring(0, cursorPosition);
            const mentionMatch = textBeforeCursor.match(/@([^\s]*)$/);

            if (mentionMatch && agents && agents.length > 0) {
              try {
                const rect = textArea.getBoundingClientRect();
                const caretCoords = getCaretCoordinates(textArea, cursorPosition);
                const scrollTop = textArea.scrollTop;

                // Calculate position relative to the viewport
                const top = rect.top + window.scrollY + caretCoords.top - scrollTop;
                const left = rect.left + window.scrollX + caretCoords.left;

                setMentionState({
                  isActive: true,
                  query: mentionMatch[1],
                  position: {
                    top: top + 24, // Add offset to show below the caret
                    left: left
                  }
                });
              } catch (error) {
                console.error('Error calculating mention dropdown position:', error);
                setMentionState(prev => ({ ...prev, isActive: false }));
              }
            } else {
              setMentionState(prev => ({ ...prev, isActive: false }));
            }
          }}
        />
        <button type="submit" className="send-button">
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M12 19l9 2-9-18-9 18l9-2zm0 0v-8"
            />
          </svg>
          {feedbackMode ? 'Send Feedback' : 'Send'}
        </button>
      </form>
    </div>
  );
};

AgentChat.propTypes = {
  agents: PropTypes.array,
  campaignActive: PropTypes.bool,
  campaignState: PropTypes.shape({
    isInitializing: PropTypes.bool,
    isStarting: PropTypes.bool,
    error: PropTypes.string,
    metrics: PropTypes.object,
    startTime: PropTypes.string
  }),
  onCampaignStateChange: PropTypes.func,
  dashboardConnectionState: PropTypes.string // Add this prop type
};

export default AgentChat;