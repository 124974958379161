import React from 'react';
import styled from '@emotion/styled';
import { luxuryTheme as theme } from '../styles/theme';

const Container = styled.div`
  min-height: 100vh;
  background: white;
  padding: ${theme.spacing[40]} ${theme.spacing[24]};
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  color: ${theme.colors.text};

  h1 {
    font-size: 2.5rem;
    margin-bottom: ${theme.spacing[32]};
    color: ${theme.colors.text};
  }

  h2 {
    font-size: 1.5rem;
    margin: ${theme.spacing[32]} 0 ${theme.spacing[16]};
    color: ${theme.colors.text};
  }

  p {
    margin-bottom: ${theme.spacing[16]};
    line-height: 1.6;
    color: ${theme.colors.gray700};
  }

  ul {
    margin-bottom: ${theme.spacing[16]};
    padding-left: ${theme.spacing[24]};

    li {
      margin-bottom: ${theme.spacing[8]};
      color: ${theme.colors.gray700};
    }
  }
`;

export function TermsOfService() {
  return (
    <Container>
      <Content>
        <h1>Terms of Service</h1>
        
        <p>Last updated: {new Date().toLocaleDateString()}</p>
        
        <p>Please read these Terms of Service carefully before using the GrupaAI platform.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using GrupaAI, you agree to be bound by these Terms of Service and all applicable laws and regulations.</p>

        <h2>2. Use License</h2>
        <p>Permission is granted to temporarily use GrupaAI for personal or business purposes, subject to the following restrictions:</p>
        <ul>
          <li>You must not modify or copy the materials</li>
          <li>You must not use the materials for any commercial purpose without proper licensing</li>
          <li>You must not attempt to reverse engineer any software contained in GrupaAI</li>
        </ul>

        <h2>3. AI Agent Usage</h2>
        <p>Our AI agents are provided &quot;as is&quot; and you agree to use them responsibly and in accordance with our guidelines.</p>

        <h2>4. Data Privacy</h2>
        <p>Your use of GrupaAI is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect and use your information.</p>

        <h2>5. Account Responsibilities</h2>
        <p>You are responsible for maintaining the confidentiality of your account and password. Please notify us immediately of any unauthorized use of your account.</p>

        <h2>6. Service Modifications</h2>
        <p>We reserve the right to modify or discontinue GrupaAI at any time without notice.</p>

        <h2>7. Limitation of Liability</h2>
        <p>GrupaAI shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the service.</p>

        <h2>Contact Information</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <p>Email: compliance@grupa.ai</p>
      </Content>
    </Container>
  );
}

export default TermsOfService;