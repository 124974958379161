import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirebaseApp } from '../config/firebase';

class AgentAvatarService {
    static instance = null;
    
    constructor() {
        if (AgentAvatarService.instance) {
            return AgentAvatarService.instance;
        }
        
        // Get Firebase app instance and initialize storage
        try {
            const app = getFirebaseApp();
            this.storage = getStorage(app);
            this.bucketName = 'ai-learnings.firebasestorage.app';
            this.urlCache = new Map(); // Cache for avatar URLs
            console.log('Firebase Storage initialized successfully');
        } catch (error) {
            console.error('Error initializing Firebase Storage:', error);
            throw error;
        }
        
        this.agentAvatarMap = {
            // Core Agents
            'website_analyzer_agent': 'website-analyzer.png',
            'outreach_assistant_agent': 'sales-assistant.png',
            'email_reviewer_agent': 'email-reviewer.png',
            'audit_outgoing_email_agent': 'audit-outgoing.png',
            'email_sender_agent': 'email-sender.png',
            'email_checker_agent': 'email-checker.png',
            
            // Analysis & Response Agents
            'engagement_analysis_agent': 'engagement-analyzer.png',
            'email_response_generator_agent': 'email-response-generator.png',
            'email_responder_agent': 'email-responder.png',
            'follow_up_strategizer_agent': 'follow-up-strategizer.png',
            
            // Management & Coordination
            'chief_of_staff': 'chief-of-staff.png',
            'agent_mediator': 'agent-mediator.png',
            'human_proxy': 'human-proxy.png',
            
            // Legacy mappings for backward compatibility
            'WA': 'website-analyzer.png',
            'SA': 'sales-assistant.png',
            'ER': 'email-reviewer.png',
            'AO': 'audit-outgoing.png',
            'ES': 'email-sender.png',
            'EC': 'email-checker.png',
            'EAN': 'engagement-analyzer.png',
            'ERG': 'email-response-generator.png',
            'ERP': 'email-responder.png',
            'CO': 'chief-of-staff.png',
            'AM': 'agent-mediator.png',
            'HP': 'human-proxy.png'
        };
        
        AgentAvatarService.instance = this;
    }

    normalizeAgentName(name) {
        if (!name) return '';
        // Convert camelCase or PascalCase to underscore format first
        const underscored = name
            .replace(/([a-z])([A-Z])/g, '$1_$2')
            .toLowerCase();
        // Then normalize to standard format
        const normalized = underscored
            .split('_')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join('');
        console.log('Name normalization:', { original: name, normalized });
        return normalized;
    }

    async getAvatarUrl(agentId, agentName) {
        try {
            console.log('Getting avatar for:', { agentId, agentName });
            agentName=this.normalizeAgentName(agentName);
            // Check cache first
            const cacheKey = `${agentId}_${agentName}`;
            if (this.urlCache.has(cacheKey)) {
                const cachedUrl = this.urlCache.get(cacheKey);
                console.log('Using cached URL:', cachedUrl);
                return cachedUrl;
            }
            
            // First, check if we have a mapped filename for this agent
            const avatarFilename = this.agentAvatarMap[agentId];
            if (avatarFilename) {
                const url = `https://firebasestorage.googleapis.com/v0/b/ai-learnings.firebasestorage.app/o/agent-avatars%2F${avatarFilename}?alt=media`;
                console.log('Using mapped avatar URL:', url);
                this.urlCache.set(cacheKey, url);
                return url;
            }
            
            // If no mapping exists, try using the agent name
            if (agentName) {
                // First convert camelCase to hyphenated (e.g. EmailReviewerAgent -> email-reviewer)
                let normalizedName = agentName
                    .replace(/([a-z])([A-Z])/g, '$1-$2')
                    .toLowerCase()
                    .replace(/\bagent\b/gi, '') // Remove 'agent' word (case insensitive)
                    .replace(/[\s_]+/g, '-') // Replace spaces and underscores with hyphens
                    .replace(/-+/g, '-') // Replace multiple consecutive hyphens with a single one
                    .replace(/^-|-$/g, ''); // Remove leading/trailing hyphens

                const storagePath = `agent-avatars/${normalizedName}.png`;
                const url = `https://firebasestorage.googleapis.com/v0/b/${this.bucketName}/o/${encodeURIComponent(storagePath)}?alt=media`;
                console.log('Using normalized name URL:', url);
                this.urlCache.set(cacheKey, url);
                return url;
            }

            console.warn('No avatar found for agent:', { agentId, agentName });
            this.urlCache.set(cacheKey, null); // Cache the miss
            return null;
        } catch (error) {
            console.error('Error getting avatar URL:', error);
            return null;
        }
    }

    async getAgentAvatar(agentId) {
        // Use the unified getAvatarUrl method to ensure consistent URL generation
        return this.getAvatarUrl(agentId);
    }

    // Upload a new avatar for an agent
    async uploadAgentAvatar(agentId, file) {
        try {
            const normalizedId = agentId.toLowerCase().replace(/_/g, '-');
            const filename = `${normalizedId}.png`;
            const agentAvatarRef = ref(this.storage, `agent-avatars/${filename}`);
            await uploadBytes(agentAvatarRef, file);
            const url = await getDownloadURL(agentAvatarRef);
            
            // Update cache
            this.urlCache.clear(); // Clear entire cache since we have a new upload
            return url;
        } catch (error) {
            console.error('Error uploading avatar:', error);
            throw error;
        }
    }
}

// Create and export singleton instance
const instance = new AgentAvatarService();
export default instance;