import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './components/AuthProvider';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { AppProvider } from './contexts/AppContext';
import { AppRoutes } from './routes';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Toaster, toast } from 'react-hot-toast';
import './App.css';
import config from './config';

// Separate config error component for cleaner code
const ConfigError = () => (
    <div className="config-error">
        <h1>Configuration Error</h1>
        <p>Google Client ID is not properly configured.</p>
        <p>Current value: {config.googleClientId}</p>
        <p>Please check your .env file and ensure it contains:</p>
        <pre>REACT_APP_GOOGLE_CLIENT_ID=your_actual_client_id</pre>
        <p>Then restart the application.</p>
    </div>
);

function App() {
    // Configuration validation
    if (!config.googleClientId || config.googleClientId.includes('your_google_client_id')) {
        return <ConfigError />;
    }

    const handleScriptLoadError = (err) => {
        console.error('Google OAuth script failed to load:', err);
        toast.error('Failed to load authentication service. Please try again later.');
    };

    return (
        <ErrorBoundary>
            <BrowserRouter>
                <GoogleOAuthProvider 
                    clientId={config.googleClientId}
                    onScriptLoadError={handleScriptLoadError}
                >                       
                    <AuthProvider>
                        <AppProvider>
                            <WebSocketProvider>
                                <Toaster 
                                    position="top-right"
                                    toastOptions={{
                                        duration: 4000,
                                        style: {
                                            background: '#333',
                                            color: '#fff',
                                        },
                                    }}
                                />
                                <AppRoutes />
                            </WebSocketProvider>
                        </AppProvider>
                    </AuthProvider>
                </GoogleOAuthProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );
}

export default App;
