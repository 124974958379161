import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
// Removed unused theme import

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #121212 0%, #1e1e1e 100%);
  border-radius: 12px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const GlowEffect = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(0, 102, 255, 0.2) 0%, rgba(0, 0, 0, 0) 70%);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const PulseCircle = styled(motion.div)`
  position: absolute;
  width: 300px;
  height: 300px;
  border: 2px solid rgba(0, 102, 255, 0.1);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const LoadingIndicator = styled(motion.div)`
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 2rem;
  z-index: 2;
`;

const Spinner = styled(motion.div)`
  width: 100%;
  height: 100%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top-color: #0066FF;
  border-radius: 50%;
`;

const Message = styled.h3`
  font-size: 1.75rem;
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 600;
  z-index: 2;
  background: linear-gradient(135deg, #ffffff 0%, #cccccc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.15);
`;

const Subtitle = styled.p`
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  max-width: 500px;
  line-height: 1.6;
  z-index: 2;
`;

const StatusIndicator = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  background: rgba(0, 102, 255, 0.1);
  border-radius: 30px;
  z-index: 2;
`;

const StatusDot = styled(motion.div)`
  width: 10px;
  height: 10px;
  background: #0066FF;
  border-radius: 50%;
  margin-right: 10px;
`;

const StatusText = styled.span`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
`;

const InitializingUI = () => {
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <GlowEffect />
      
      <PulseCircle
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.1, 0.2, 0.1],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      
      <LoadingIndicator>
        <Spinner
          animate={{ rotate: 360 }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "linear"
          }}
        />
      </LoadingIndicator>
      
      <Message>Initializing Your AI Campaign</Message>
      
      <Subtitle>
        We&apos;re setting up your AI agents and preparing your campaign for launch.
        This premium experience is tailored specifically to your business needs.
      </Subtitle>
      
      <StatusIndicator
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.6 }}
      >
        <StatusDot
          animate={{ opacity: [0.5, 1, 0.5] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        />
        <StatusText>Orchestrating AI Agents...</StatusText>
      </StatusIndicator>
    </Container>
  );
};

export default InitializingUI;