import axios from 'axios';
import config from '../config';
import { jwtDecode } from 'jwt-decode';

const TOKEN_REFRESH_THRESHOLD = 5 * 60; // 5 minutes in seconds

class AuthService {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });

    // Remove CORS headers from client side as they should be set on server side
    this.isRefreshing = false;
    this.failedQueue = [];
    this.refreshAttempts = 0;
    this.maxRefreshAttempts = 3;

    // Add request interceptor to attach token and handle token refresh
    this.api.interceptors.request.use(
      async (config) => {
        // Skip token check for refresh, login, and Google callback endpoints
        if (config.url.includes('/auth/refresh') || config.url.includes('/auth/login') || config.url.includes('/auth/google/callback')) {
          return config;
        }

        const token = this.getToken();
        if (!token) {
          return Promise.reject(new Error('No token available'));
        }

        const decoded = this.validateToken(token);
        if (!decoded) {
          this.clearAuth();
          window.location.href = '/login';
          return Promise.reject(new Error('Invalid token'));
        }

        if (decoded.needsRefresh) {
          try {
            if (!this.isRefreshing) {
              this.isRefreshing = true;
              const newToken = await this.refreshToken();
              this.isRefreshing = false;
              this.refreshAttempts = 0;
              
              if (newToken) {
                config.headers.Authorization = `Bearer ${newToken}`;
                // Process queued requests
                this.processQueue(null, newToken);
                return config;
              }
            }
            
            // Queue this request
            return new Promise((resolve, reject) => {
              this.failedQueue.push({ resolve, reject });
            });
          } catch (error) {
            this.isRefreshing = false;
            this.processQueue(error, null);
            this.clearAuth();
            window.location.href = '/login';
            return Promise.reject(error);
          }
        }

        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add response interceptor to handle token expiration
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        
        if (error.response?.status === 401 && !originalRequest._retry) {
          if (this.refreshAttempts >= this.maxRefreshAttempts) {
            this.clearAuth();
            window.location.href = '/login?session_expired=true';
            return Promise.reject(new Error('Max refresh attempts exceeded'));
          }

          originalRequest._retry = true;
          this.refreshAttempts++;
          
          try {
            const newToken = await this.refreshToken();
            if (newToken) {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              return this.api(originalRequest);
            }
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            this.clearAuth();
            window.location.href = '/login?session_expired=true';
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getGoogleAuthUrl() {
    try {
      const response = await this.api.get('/auth/google/url');
      return response.data;
    } catch (error) {
      console.error('Auth URL Error:', error);
      throw error;
    }
  }

  //async getGoogleAuthUrl() {
  //   try {
  //     const baseUrl = 'https://accounts.google.com/o/oauth2/auth';
  //     const params = {
  //       client_id: config.googleClientId,
  //       redirect_uri: `${window.location.origin}/auth/google/callback`,
  //       response_type: 'code',
  //       scope: 'openid email profile',
  //       access_type: 'offline',
  //      prompt: 'consent'
  //    };
  //    const authUrl = `${baseUrl}?${new URLSearchParams(params).toString()}`;
   //   return authUrl;
  //  } catch (error) {
  //    console.error('Error generating Google Auth URL:', error);
  //    throw error;
  //  }
  //}


  async handleCallback(code, socketManager) {
    try {
      const redirectUri = `${window.location.origin}/callback`;
      const response = await this.api.post('/auth/google/callback', {
        code,
        redirect_uri: redirectUri,
        state: sessionStorage.getItem('oauth_state')
      });

      const data = response.data;
      if (data.token) {
        this.setToken(data.token);
        this.setUser(data.user);
        if (socketManager?.disconnectSocket && socketManager?.connectSocket) {
          await socketManager.disconnectSocket();
          await socketManager.connectSocket();
        }
      }

      return data;
    } catch (error) {
      console.error('Auth callback failed:', error);
      throw error;
    }
  }

  async checkAuthStatus() {
    try {
      const token = this.getToken();
      if (!token) {
        return { authenticated: false, user: null };
      }

      const response = await this.api.get('/auth/status', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    } catch (error) {
      console.error('Auth check failed:', error);
      return { authenticated: false, user: null };
    }
  }

  async login(credentials) {
    try {
      const response = await this.api.post('/auth/login', credentials);
      const { token, user } = response.data;
      
      if (!token || !user) {
        throw new Error('Invalid response from server');
      }

      // Validate token before storing
      const decoded = this.validateToken(token);
      if (!decoded) {
        throw new Error('Invalid token received');
      }

      this.setToken(token);
      this.setUser(user);

      return { token, user };
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  async logout() {
    try {
      const response = await axios.post(
        `${config.apiUrl}/auth/logout`,
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem(config.tokenKey)}`
          }
        }
      );
      
      // Clear local storage and cookies regardless of response
      localStorage.removeItem(config.tokenKey);
      localStorage.removeItem('user');
      sessionStorage.removeItem('google_credentials');
      sessionStorage.removeItem('google_auth_code');
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      
      return response.data;
    } catch (error) {
      console.error('Logout error:', error);
      // Still clear storage even if server request fails
      localStorage.removeItem(config.tokenKey);
      localStorage.removeItem('user');
      sessionStorage.removeItem('google_credentials');
      sessionStorage.removeItem('google_auth_code');
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      throw error;
    }
  }

  async refreshToken() {
    try {
      const token = this.getToken();
      if (!token) {
        throw new Error('No token available for refresh');
      }

      const decoded = this.validateToken(token);
      if (decoded && !decoded.needsRefresh) {
        return token; // No need to refresh if token is still valid
      }

      const response = await this.api.post('/auth/refresh', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const newToken = response.data.token;
      if (newToken) {
        const newDecoded = this.validateToken(newToken);
        if (!newDecoded) {
          throw new Error('Invalid token received during refresh');
        }

        this.setToken(newToken);
        return newToken;
      } else {
        throw new Error('No token received during refresh');
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      this.clearAuth();
      window.location.href = '/login?session_expired=true';
      throw error;
    }
  }

  validateToken(token) {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp - currentTime < TOKEN_REFRESH_THRESHOLD) {
        return { ...decoded, needsRefresh: true };
      }
      return { ...decoded, needsRefresh: false };
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  }

  async authenticateWithGoogle(credential) {
    const maxRetries = 3;
    let attempt = 0;
    let lastError = null;

    while (attempt < maxRetries) {
      try {
        const response = await this.api.post('/auth/google/callback', {
          credential,
          code: credential, // Use the credential as the code
          redirect_uri: `${window.location.origin}/callback`
        });

        const data = response.data;
        if (data.success && data.token) {
          // Validate token before storing
          const decoded = this.validateToken(data.token);
          if (!decoded) {
            throw new Error('Invalid token received from server');
          }

          // Store auth data
          const user = {
            ...data.user,
            displayName: data.user.name || data.user.displayName,
            photoURL: data.user.picture || data.user.photoURL || data.user.avatar,
          };
          
          this.setToken(data.token);
          this.setUser(user);
          localStorage.setItem('user', JSON.stringify(user));

          // Store Google credentials in session storage
          if (data.google_credentials) {
            console.log('Storing Google credentials:', data.google_credentials);
            sessionStorage.setItem('google_credentials', JSON.stringify(data.google_credentials));
          } else {
            console.warn('No Google credentials received from server');
          }

          return {
            success: true,
            token: data.token,
            user: data.user
          };
        }
        throw new Error('Invalid server response');
      } catch (error) {
        console.error(`Authentication attempt ${attempt + 1} failed:`, error);
        lastError = error;
        attempt++;
        if (attempt < maxRetries) {
          // Exponential backoff
          await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
        }
      }
    }

    throw new Error(`Authentication failed after ${maxRetries} attempts: ${lastError?.message}`);
  }

  async verifyAuth() {
    try {
      const response = await this.api.post('/auth/verify');
      return response.data;
    } catch (error) {
      console.error('Auth verification failed:', error);
      console.error('Error details:', {
        message: error.message,
        code: error.code,
        config: error.config,
        request: error.request
      });
      if (error.code === 'ERR_NETWORK') {
        return { isAuthenticated: false, error: 'Network error' };
      }
      return { isAuthenticated: false, error: error.message };
    }
  }

  async checkTokenExpiration() {
    const token = this.getToken();
    if (!token) return false;
    
    try {
      const decoded = this.validateToken(token);
      return decoded && !decoded.needsRefresh;
    } catch (error) {
      console.error('Token validation failed:', error);
      return false;
    }
  }

  getToken() {
    try {
      return localStorage.getItem(config.tokenKey);
    } catch (error) {
      console.error('Error getting token:', error);
      return null;
    }
  }

  setToken(token) {
    try {
      if (token) {
        localStorage.setItem(config.tokenKey, token);
      } else {
        localStorage.removeItem(config.tokenKey);
      }
    } catch (error) {
      console.error('Error setting token:', error);
    }
  }

  getUser() {
    const userStr = localStorage.getItem('user');
    try {
      return userStr ? JSON.parse(userStr) : null;
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  clearAuth() {
    localStorage.removeItem(config.tokenKey);
    localStorage.removeItem('user');
    sessionStorage.removeItem('google_credentials');
    sessionStorage.removeItem('google_auth_code');
  }

  isAuthenticated() {
    const token = this.getToken();
    return !!token && !!this.validateToken(token);
  }

  processQueue(error, token = null) {
    this.failedQueue.forEach(promise => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });
    this.failedQueue = [];
  }

  validateStatus(status) {
    return status >= 200 && status < 500;
  }
}

export const authService = new AuthService();