import React from 'react';
import styled from '@emotion/styled';
import { luxuryTheme as theme } from '../styles/theme';

const ErrorContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing[40]};
  background: ${theme.colors.white};
  text-align: center;
`;

const ErrorContent = styled.div`
  max-width: 500px;
`;

const ErrorTitle = styled.h1`
  ${theme.typography.h2};
  color: ${theme.colors.error};
  margin-bottom: ${theme.spacing[16]};
`;

const ErrorMessage = styled.p`
  ${theme.typography.body};
  color: ${theme.colors.gray500};
  margin-bottom: ${theme.spacing[24]};
`;

const RetryButton = styled.button`
  padding: ${theme.spacing[12]} ${theme.spacing[24]};
  background: ${theme.colors.gradient.primary};
  color: ${theme.colors.white};
  border-radius: 8px;
  ${theme.typography.body};
  transition: ${theme.transitions.all};

  &:hover {
    transform: translateY(-1px);
    box-shadow: ${theme.shadows.md};
  }
`;

export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <ErrorContent>
            <ErrorTitle>Something went wrong</ErrorTitle>
            <ErrorMessage>
              {this.state.error?.message || 'An unexpected error occurred'}
            </ErrorMessage>
            <RetryButton onClick={() => window.location.reload()}>
              Try Again
            </RetryButton>
          </ErrorContent>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
} 