import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import avatarService from '../services/AgentAvatarService';
import { HoverCard, HoverCardProvider } from './HoverCard';
import { useImageCache } from '../hooks/useImageCache';
import { useAgentIdentity } from '../hooks/useAgentIdentity';
import imageCacheService from '../services/ImageCacheService';
import './AgentChat.css';

const AgentAvatar = ({ agent, getAgentInitials, style }) => {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const avatarRef = useRef(null);
  const { cachedUrl } = useImageCache(avatarUrl);

  const agentId = agent?.id || (typeof agent === 'object' ? agent?.name : agent);
  const agentName = typeof agent === 'object' ? agent?.name : agent;
  const { humanName, initials: hookInitials } = useAgentIdentity(agentName);
  const displayInitials = hookInitials || getAgentInitials(agentName);

  // Prepare structured agent object for HoverCard
  const structuredAgent = {
    id: agentId,
    name: agentName,
    description: typeof agent === 'object' ? agent?.description : '',
    role: typeof agent === 'object' ? agent?.role : ''
  };

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        setIsLoading(true);
        setLoadError(false);
        
        console.log('Fetching avatar for agent:', {
          agentId,
          agentName,
          displayInitials,
          rawAgent: agent
        });

        const url = await avatarService.getAvatarUrl(agentId, agentName);
        
        if (!url) {
          console.warn('No avatar URL returned for agent:', agentName);
          setLoadError(true);
        } else {
          // Preload the image immediately
          imageCacheService.preloadImage(url).then(() => {
            setAvatarUrl(url);
            setLoadError(false);
          }).catch(error => {
            console.error('Failed to load image:', error);
            setLoadError(true);
          });
        }
      } catch (error) {
        console.error('Error fetching avatar:', error);
        setLoadError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (agentId && agentName) {
      fetchAvatar();
    }
  }, [agentId, agentName, agent, displayInitials]);

  useEffect(() => {
    if (avatarUrl) {
      // Preload the image for HoverCard
      imageCacheService.preloadImage(avatarUrl);
    }
  }, [avatarUrl]);

  const handleMouseEnter = () => {
    requestAnimationFrame(() => {
      if (avatarRef.current) {
        const rect = avatarRef.current.getBoundingClientRect();
        setHoverPosition({
          top: rect.bottom + 8,
          left: Math.max(16, Math.min(
            window.innerWidth - 296, // 280 + 16 padding
            rect.left - 140 + (rect.width / 2)
          ))
        });
        setIsHovered(true);
      }
    });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <HoverCardProvider>
      <div
        className="agent-avatar-container"
        ref={avatarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <motion.div
          key={isLoading ? 'loading' : (avatarUrl ? 'avatar' : 'initials')}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={`agent-avatar ${isLoading ? 'loading' : ''} ${loadError ? 'error' : ''}`}
          style={{
            ...style,
            position: 'relative'
          }}
        >
          {isLoading ? (
            <div className="loading-spinner" />
          ) : cachedUrl && !loadError ? (
            <img
              src={cachedUrl}
              alt={`${agentName} avatar`}
              style={{ 
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                objectFit: 'cover'
              }}
              onError={() => setLoadError(true)}
            />
          ) : (
            <span style={{ fontSize: style?.fontSize || '15px' }}>{displayInitials}</span>
          )}
        </motion.div>
        <div className="agent-name">{humanName || agentName.replace(/_/g, ' ')}</div>
        {isHovered && (
          <HoverCard
            agent={structuredAgent}
            isVisible={isHovered}
            position={hoverPosition}
            avatarUrl={cachedUrl}
          />
        )}
      </div>
    </HoverCardProvider>
  );
};

AgentAvatar.propTypes = {
  agent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string
    })
  ]).isRequired,
  getAgentInitials: PropTypes.func.isRequired,
  style: PropTypes.object
};

export default AgentAvatar;