// Agent Human Identities Configuration
export const agentIdentities = {
  // Core Agents with Human Identities
  'Website_Analyzer_Agent': 'James Adam - Website Analyst',
  'Sales_Assistant_Agent': 'Sam Acer - Sales Assistant',
  'EmailReviewerAgent': 'Erin Reyes - Email Reviewer',
  'AuditOutgoingEmailAgent': 'Holt Andrey - Email Auditor',  // Allison "Ally" Holt
  'EmailSenderAgent': 'Simon Steel - Email Sender',        // Simon "Sendy" Bell
  'EmailResponseGeneratorAgent': 'Regina Rova - Email Responder', // Regina "Rex" Porter
  'EngagementAnalysisAgent': 'Ethan Annie - Engagement Analyst',
  'FollowUpStrategizerAgent': 'Fiona Strauss - Follow-up Strategist',
  'EmailResponderAgent': 'Rona Ryan - Email Responder ',    // Ryan "Reply" Ryan
  'human_proxy': 'Amy Anderson- Your Assistant',
  'outreach_society_of_mind': 'Omar -Outreach Manager',
  'system': 'Corey - Task Officer'                        // System Core (Corey)
};

// Nickname variations for flexible matching
export const nicknameVariations = {
  'Ally': 'AuditOutgoingEmailAgent',
  'Allison': 'AuditOutgoingEmailAgent',
  'Sendy': 'EmailSenderAgent',
  'Simon': 'EmailSenderAgent',
  'Cindy': 'EmailSenderAgent',
  'Regina': 'EmailResponseGeneratorAgent',
  'Rex': 'EmailResponseGeneratorAgent',
  'Reply': 'EmailResponderAgent',
  'Ryan': 'EmailResponderAgent'
};

// Create reverse mapping for lookups
export const reverseIdentities = {
  ...Object.entries(agentIdentities).reduce((acc, [id, name]) => {
    acc[name.toLowerCase()] = id;
    return acc;
  }, {}),
  ...Object.entries(nicknameVariations).reduce((acc, [nickname, id]) => {
    acc[nickname.toLowerCase()] = id;
    return acc;
  }, {})
};

// Get human name for an agent
export const getHumanName = (agentId) => {
  if (!agentId) return agentId;
  
  // Get the original agent name
  const originalName = typeof agentId === 'object' ? 
    (agentId.name || agentId.id) : 
    agentId.toString().trim();
  
  // Check if we have a direct mapping using the original name
  if (agentIdentities[originalName]) {
    return agentIdentities[originalName];
  }
  
  // If no mapping found, return the original name with spaces
  return originalName.replace(/_/g, ' ');
};

// Get agent ID from human name or nickname
export const getAgentId = (nameOrNickname) => {
  const lookup = nameOrNickname.toLowerCase();
  return reverseIdentities[lookup] || nameOrNickname;
};

// Process message content to show human names
export const processMessageContent = (content) => {
  if (!content) return content;
  
  // Handle @mentions
  let processedContent = content.replace(/(?<!@)@([A-Za-z][A-Za-z_]*Agent)\b/g,
    (match, name) => {
      // Remove any leading @ from the name
      const cleanName = name.replace(/^@+/, '');
      
      // Check if it's a direct agent ID
      if (agentIdentities[cleanName]) {
        return `@${agentIdentities[cleanName]}`;
      }
      // Check if it's a nickname
      const agentId = nicknameVariations[cleanName];
      if (agentId && agentIdentities[agentId]) {
        return `@${agentIdentities[agentId]}`;
      }
      // If not found, try to get agent ID from the name
      const resolvedAgentId = getAgentId(cleanName);
      return `@${getHumanName(resolvedAgentId)}`;
    }
  );

  // Then replace standalone agent IDs with human names
  Object.entries(agentIdentities).forEach(([agentId, humanName]) => {
    const regex = new RegExp(`\\b${agentId}\\b`, 'g');
    processedContent = processedContent.replace(regex, humanName);
  });

  // Finally handle any standalone nicknames
  Object.entries(nicknameVariations).forEach(([nickname, agentId]) => {
    if (agentIdentities[agentId]) {
      const regex = new RegExp(`\\b${nickname}\\b`, 'gi');
      processedContent = processedContent.replace(regex, agentIdentities[agentId]);
    }
  });

  return processedContent;
};

// Prepare message for sending (convert human names back to agent IDs)
export const prepareMessageForSending = (content) => {
  if (!content) return content;
  
  let processedContent = content;
  
  // Convert @mentions with human names back to agent IDs
  processedContent = processedContent.replace(
    /@([A-Za-z][A-Za-z_ "]+)/g,
    (match, name) => `@${getAgentId(name.trim())}`
  );
  
  // Convert other human name mentions to agent IDs
  Object.entries(agentIdentities).forEach(([agentId, humanName]) => {
    const regex = new RegExp(`\\b${humanName}\\b`, 'gi');
    processedContent = processedContent.replace(regex, agentId);
  });
  
  // Handle nickname variations
  Object.entries(nicknameVariations).forEach(([nickname, agentId]) => {
    const regex = new RegExp(`\\b${nickname}\\b`, 'gi');
    processedContent = processedContent.replace(regex, agentId);
  });
  
  return processedContent;
};

// Get initials from human name
export const getHumanInitials = (agentId) => {
  const humanName = getHumanName(agentId);
  return humanName
    .split(' ')
    .map(part => part[0])
    .join('')
    .toUpperCase();
};
